export const FETCH_SUPPLIERS_PENDING = 'FETCH_SUPPLIERS_PENDING';
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_ERROR = 'FETCH_SUPPLIERS_ERROR';

export function fetchSuppliersPending() {
  return {
    type: FETCH_SUPPLIERS_PENDING
  };
}

export function fetchSuppliersSuccess(data) {
  return {
    type: FETCH_SUPPLIERS_SUCCESS,
    data: data
  };
}

export function fetchSuppliersError(error) {
  return {
    type: FETCH_SUPPLIERS_ERROR,
    error: error
  };
}

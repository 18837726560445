import { useMsal } from '@azure/msal-react';
import { useEnviroment } from 'enviroment/Context';
import { useCallback } from 'react';

export function useAuth() {
  const { instance, accounts } = useMsal();
  const { requestScopes } = useEnviroment();

  const getUser = useCallback(() => {
    return accounts[0]?.name || null;
  }, [accounts]);

  const getRoles = useCallback(() => {
    return accounts[0]?.idTokenClaims?.roles || [];
  }, [accounts]);

  const hasRole = useCallback(
    roleName => {
      return getRoles().some(role => role.trim().toUpperCase() === roleName.toUpperCase());
    },
    [getRoles]
  );

  const hasElevatedRole = useCallback(() => {
    if (hasRole('Supplier') || hasRole('Admin')) {
      return true;
    }
    return false;
  }, [hasRole]);

  const isAdmin = useCallback(() => {
    if (hasRole('Admin')) {
      return true;
    }
    return false;
  }, [hasRole]);

  const isAsmeSupplier = useCallback(() => {
    return hasRole('AsmeSupplier') || hasRole('Admin');
  }, [hasRole]);

  const getToken = useCallback(async () => {
    const account = accounts[0];

    const response = await instance.acquireTokenSilent({
      scopes: requestScopes,
      account: account ?? undefined
    });

    return response.accessToken;
  }, [instance, accounts, requestScopes]);

  return { getUser, hasElevatedRole, isAdmin, isAsmeSupplier, getToken };
}

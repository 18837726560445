export const FETCH_QDOC_PENDING = 'FETCH_QDOC_PENDING';
export const FETCH_QDOC_SUCCESS = 'FETCH_QDOC_SUCCESS';
export const FETCH_QDOC_ERROR = 'FETCH_QDOC_ERROR';
export const FETCH_QDOC_CLEAR = 'FETCH_QDOC_CLEAR';

export function fetchQDocPending() {
  return {
    type: FETCH_QDOC_PENDING
  };
}

export function fetchQDocSuccess(data) {
  return {
    type: FETCH_QDOC_SUCCESS,
    data: data
  };
}

export function fetchQDocError(error) {
  return {
    type: FETCH_QDOC_ERROR,
    error: error
  };
}

export function fetchQDocClear() {
  return {
    type: FETCH_QDOC_CLEAR
  };
}

export const UPLOAD_FILE_PENDING = 'UPLOAD_FILE_PENDING';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const UPLOAD_FILE_CLEAR = 'UPLOAD_FILE_CLEAR';

export function uploadFilePending() {
  return {
    type: UPLOAD_FILE_PENDING
  };
}

export function uploadFileSuccess(data) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    data: data
  };
}

export function uploadFileError(error) {
  return {
    type: UPLOAD_FILE_ERROR,
    error: error
  };
}

export function uploadFileClear() {
  return {
    type: UPLOAD_FILE_CLEAR
  };
}

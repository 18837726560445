import { uploadFilePending, uploadFileSuccess, uploadFileError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const uploadFileAsync = createAsyncThunk('upload/file', async ({ type, file }, thunkAPI) => {
  thunkAPI.dispatch(uploadFilePending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    var formData = new FormData();
    formData.append('file', file);
    const res = await fetch('api/upload/' + type, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token.accessToken
      },
      body: formData
    });
    const data = await res.json();
    if (!res.ok) {
      thunkAPI.dispatch(uploadFileError(data));
      return;
    }
    thunkAPI.dispatch(uploadFileSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(uploadFileError(error));
  }
});

export default uploadFileAsync;

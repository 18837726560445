import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import './Heat.css';

export default class Heat extends Component {
  renderMaterialStandards(heat) {
    return heat.selectedStandards.map(standard => {
      return (
        <div className='row' key={standard.materialStandardId + standard.materialGradeId}>
          <div className='col-sm-4'>
            <b>Material standard:</b> {standard.materialStandard}
          </div>
          <div className='col-sm-4'>
            <b>Material grade:</b> {standard.materialGrade}
          </div>
          <div className='col-sm-4'>
            <b>Material standard revision:</b> {standard.materialStandardRevision}
          </div>
        </div>
      );
    });
  }

  renderChemicalProperties(range) {
    const output = [];
    let i;
    for (i = 0; i < range.length; i += 4) {
      const props = range.slice(i, i + 4);
      output.push(this.renderSetOfChemicalProperties(props, i));
    }

    return <Fragment>{output}</Fragment>;
  }

  renderSetOfChemicalProperties(props, i) {
    return (
      <div key={i + Date.now()} className='row'>
        {props.map(prop => this.renderChemicalProperty(prop), this)}
      </div>
    );
  }

  renderChemicalProperty(prop) {
    if (!prop) {
      return null;
    }
    return (
      <div className='col-sm-3' key={prop.element}>
        <b>{prop.element}: </b>
        {prop.value}
      </div>
    );
  }

  render() {
    if (!this.props.data) {
      return (
        <Fragment>
          <br />
          <div>
            <h5>No Heat was found with the provided id</h5>
            Please contact support if this problem reoccurs
          </div>
        </Fragment>
      );
    }

    const heat = this.props.data;

    return (
      <Fragment>
        <div>
          <br />
          <h4>Heat details</h4>
          <br />
          <h5>General</h5>
          <div className='well'>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Heat number:</b> {heat.heatNumber}
              </div>
              <div className='col-sm-6'>
                <b>Supplier:</b> {heat.supplier.name}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Steel work:</b> {heat.steelWork.name}
              </div>
              <div className='col-sm-6'>
                <b>Manufacturer:</b> {heat.manufacturer.name}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Certificate type:</b> {heat.certificateType.type}
              </div>
              <div className='col-sm-6'>
                <b>Test number:</b> {heat.testNumber}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Created:</b> {new Date(heat.created).toLocaleDateString()}
              </div>
              <div className='col-sm-6'>
                <b>Created by:</b> {heat.createdBy}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Id:</b> {heat.id}
              </div>
              <div className='col-sm-6'>
                <b>Revision:</b> {heat.revision}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Status: </b>
                {heat.invalid ? 'Invalid' : 'Valid'}
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-sm-12 text-break'>
                <b>Comment: </b> {heat.comment}
              </div>
            </div>
          </div>
          <br />
          <h5>Material standards</h5>
          <div className='well'>{this.renderMaterialStandards(heat)}</div>
          <br />
          <h5>Chemical properties</h5>
          <div className='well'>{this.renderChemicalProperties(heat.chemicalProperties)}</div>
        </div>
        <br />
        <Link to={`/edit-heat/${heat.id}`} className='btn btn-primary'>
          Edit
        </Link>
      </Fragment>
    );
  }
}

export const FETCH_SUPPLIERCERTIFICATE_PENDING = 'FETCH_SUPPLIERCERTIFICATE_PENDING';
export const FETCH_SUPPLIERCERTIFICATE_SUCCESS = 'FETCH_SUPPLIERCERTIFICATE_SUCCESS';
export const FETCH_SUPPLIERCERTIFICATE_ERROR = 'FETCH_SUPPLIERCERTIFICATE_ERROR';

export function fetchSupplierCertificatePending() {
  return {
    type: FETCH_SUPPLIERCERTIFICATE_PENDING
  };
}

export function fetchSupplierCertificateSuccess(data) {
  return {
    type: FETCH_SUPPLIERCERTIFICATE_SUCCESS,
    data: data
  };
}

export function fetchSupplierCertificateError(error) {
  return {
    type: FETCH_SUPPLIERCERTIFICATE_ERROR,
    error: error
  };
}

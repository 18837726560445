import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import searchCertificatesAsync from '../../store/searchCertificates/searchCertificates';
import searchQDocsAsync from '../../store/searchQDocs/searchQDocs';
import searchSupplierCertificatesAsync from '../../store/searchSupplierCertificates/searchSupplierCertificates';
import searchHeatsAsync from '../../store/searchHeats/searchHeats';
import { useAuth } from 'auth/useAuth';

export const PAGE_COUNT = 15;

export function useSearch({ searchFormState, advancedSearch, useCerts, useQdocs }) {
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [certificatesOffset, setCertificateOffset] = useState(0);
  const [supplierCertificatesOffset, setSupplierCertificatesOffset] = useState(0);
  const [heatsOffset, setHeatsOffset] = useState(0);
  const [qdocsOffset, setQdocsOffset] = useState(0);

  const { hasElevatedRole } = useAuth();

  const dispatch = useDispatch();

  const shouldSearchQDocs = useCallback(
    formState => {
      if (!useQdocs) {
        return false;
      }

      if (formState.keyword) return true;

      if (
        formState.heatNumber ||
        formState.alfaLavalId ||
        formState.mtrId ||
        formState.lotNumber ||
        formState.poNumber
      ) {
        return false;
      }

      if (formState.serialNumber || formState.itemNumber) {
        return true;
      }

      return false;
    },
    [useQdocs]
  );

  const shouldSearchCertificates = useCallback(
    formState => {
      if (!useCerts) return false;

      if (formState.keyword) return true;

      if (formState.serialNumber) return false;

      if (
        formState.keyword ||
        formState.heatNumber ||
        formState.itemNumber ||
        formState.alfaLavalId ||
        formState.mtrId ||
        formState.lotNumber ||
        formState.poNumber
      ) {
        return true;
      }

      return false;
    },
    [useCerts]
  );

  const shouldSearchSupplierCertificates = useCallback(
    formState => {
      if (!hasElevatedRole() || !useCerts) {
        return false;
      }

      if (formState.keyword) return true;

      if (formState.itemNumber || formState.mtrId || formState.alfaLavalId || formState.serialNumber) {
        return false;
      }

      if (formState.lotNumber || formState.poNumber || formState.heatNumber) {
        return true;
      }

      return false;
    },
    [useCerts, hasElevatedRole]
  );

  const shouldSearchHeats = useCallback(
    formState => {
      if (!hasElevatedRole() || !useCerts) {
        return false;
      }
      if (formState.keyword) return true;

      if (
        formState.itemNumber ||
        formState.alfaLavalId ||
        formState.mtrId ||
        formState.lotNumber ||
        formState.poNumber ||
        formState.serialNumber
      ) {
        return false;
      }

      if (formState.heatNumber) return true;

      return false;
    },
    [useCerts, hasElevatedRole]
  );

  const searchCertificates = useCallback(
    pageNumber => {
      setSearchInitiated(true);
      const formData = { ...searchFormState };

      if (advancedSearch) {
        delete formData.keyword;
      }

      dispatch(
        searchCertificatesAsync({
          ...formData,
          pageOffset: PAGE_COUNT * (pageNumber - 1),
          pageCount: PAGE_COUNT
        })
      );
      setCertificateOffset(PAGE_COUNT * (pageNumber - 1));
    },
    [dispatch, searchFormState, advancedSearch]
  );

  const searchSupplierCertificates = useCallback(
    pageNumber => {
      setSearchInitiated(true);
      const formData = { ...searchFormState };

      if (advancedSearch) {
        delete formData.keyword;
      }

      dispatch(
        searchSupplierCertificatesAsync({
          ...formData,
          pageOffset: PAGE_COUNT * (pageNumber - 1),
          pageCount: PAGE_COUNT
        })
      );
      setSupplierCertificatesOffset(PAGE_COUNT * (pageNumber - 1));
    },
    [dispatch, searchFormState, advancedSearch]
  );

  const searchHeats = useCallback(
    pageNumber => {
      setSearchInitiated(true);
      const formData = { ...searchFormState };

      if (advancedSearch) {
        delete formData.keyword;
      }

      dispatch(
        searchHeatsAsync({
          ...formData,
          pageOffset: PAGE_COUNT * (pageNumber - 1),
          pageCount: PAGE_COUNT
        })
      );
      setHeatsOffset(PAGE_COUNT * (pageNumber - 1));
    },
    [dispatch, searchFormState, advancedSearch]
  );

  const searchQdocs = useCallback(
    pageNumber => {
      setSearchInitiated(true);
      const formData = { ...searchFormState };

      if (advancedSearch) {
        delete formData.keyword;
      }

      dispatch(
        searchQDocsAsync({
          ...formData,
          pageOffset: PAGE_COUNT * (pageNumber - 1),
          pageCount: PAGE_COUNT
        })
      );
      setQdocsOffset(PAGE_COUNT * (pageNumber - 1));
    },
    [dispatch, searchFormState, advancedSearch]
  );

  const search = useCallback(
    pageNumber => {
      const formData = { ...searchFormState };

      if (advancedSearch) {
        delete formData.keyword;
      }

      if (shouldSearchCertificates(searchFormState)) {
        searchCertificates(pageNumber);
      }

      if (shouldSearchSupplierCertificates(searchFormState)) {
        searchSupplierCertificates(pageNumber);
      }

      if (shouldSearchHeats(searchFormState)) {
        searchHeats(pageNumber);
      }

      if (shouldSearchQDocs(searchFormState)) {
        searchQdocs(pageNumber);
      }
    },
    [
      advancedSearch,
      searchFormState,
      shouldSearchCertificates,
      shouldSearchHeats,
      shouldSearchQDocs,
      shouldSearchSupplierCertificates,
      searchCertificates,
      searchHeats,
      searchQdocs,
      searchSupplierCertificates
    ]
  );

  const memoisedSearch = useMemo(
    () => ({
      search,
      searchInitiated,
      setSearchInitiated,
      certificates: { searchCertificates, certificatesOffset, shouldSearch: shouldSearchCertificates },
      supplierCertificates: {
        searchSupplierCertificates,
        supplierCertificatesOffset,
        shouldSearch: shouldSearchSupplierCertificates
      },
      heats: { searchHeats, heatsOffset, shouldSearch: shouldSearchHeats },
      qdocs: { searchQdocs, qdocsOffset, shouldSearch: shouldSearchQDocs }
    }),
    [
      search,
      searchCertificates,
      certificatesOffset,
      shouldSearchCertificates,
      searchSupplierCertificates,
      supplierCertificatesOffset,
      shouldSearchSupplierCertificates,
      searchHeats,
      heatsOffset,
      shouldSearchHeats,
      searchQdocs,
      qdocsOffset,
      shouldSearchQDocs,
      searchInitiated,
      setSearchInitiated
    ]
  );

  return memoisedSearch;
}

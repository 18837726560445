import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchHeatAsync from '../../store/heat/fetchHeat';
import Spinner from '../Layout/Spinner';
import Heat from './Heat.jsx';
import Unauthorized from '../Layout/Unauthoried.jsx';
import { useParams } from 'react-router';
import { useAuth } from 'auth/useAuth';

export function HeatDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const heat = useSelector(state => state.heat);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    if (hasElevatedRole() && id) {
      dispatch(fetchHeatAsync(id));
    }
  }, [id, dispatch, hasElevatedRole]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (!heat) {
    return null;
  }
  if (!heat.heat && heat.pending) {
    return <Spinner />;
  }

  return <Heat data={heat.heat} />;
}

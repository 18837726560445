export const FETCH_ASMECERTIFICATION_PENDING = 'FETCH_ASMECERTIFICATION_PENDING';
export const FETCH_ASMECERTIFICATION_SUCCESS = 'FETCH_ASMECERTIFICATION_SUCCESS';
export const FETCH_ASMECERTIFICATION_ERROR = 'FETCH_ASMECERTIFICATION_ERROR';

export function fetchAsmeCertificationPending() {
  return {
    type: FETCH_ASMECERTIFICATION_PENDING
  };
}

export function fetchAsmeCertificationSuccess(data) {
  return {
    type: FETCH_ASMECERTIFICATION_SUCCESS,
    data: data
  };
}

export function fetchAsmeCertificationError(error) {
  return {
    type: FETCH_ASMECERTIFICATION_ERROR,
    error: error
  };
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAlfaLavalCertificatePending,
  fetchAlfaLavalCertificateSuccess,
  fetchAlfaLavalCertificateError
} from './actions';

const fetchAlfaLavalCertificateAsync = createAsyncThunk('alfa-laval-certificate/fetch', async (id, thunkAPI) => {
  thunkAPI.dispatch(fetchAlfaLavalCertificatePending());
  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/alfa-laval-certificate/' + id, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchAlfaLavalCertificateError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchAlfaLavalCertificateSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchAlfaLavalCertificateError(error));
  }
});

export default fetchAlfaLavalCertificateAsync;

import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import NavDropdown from './NavDropdown.jsx';
import logo from '../../assets/img/logo-alfalaval.svg';
import './NavMenu.css';
import { useAuth } from 'auth/useAuth';
import { useMsal } from '@azure/msal-react';

export function NavMenu({ closeAll, closeMenu }) {
  const [openMenu, setOpenMenu] = useState(undefined);
  const [user, setUser] = useState(undefined);

  const { instance } = useMsal();

  const { getUser, hasElevatedRole, isAdmin } = useAuth();

  useEffect(() => {
    setUser(getUser());
  }, [getUser]);

  useEffect(() => {
    if (closeAll) {
      setOpenMenu(undefined);
      closeMenu();
    }
  }, [closeAll, closeMenu]);

  const isInternetExplorer = useMemo(() => {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match('Trident.*rv:11.')) {
      // If Internet Explorer, return version number
      return true;
    }
    return false;
  }, []);

  const toggleMenu = useCallback(
    menuId => {
      if (menuId === openMenu) {
        setOpenMenu(undefined);
      } else {
        setOpenMenu(menuId);
      }
    },
    [openMenu]
  );

  const closeOpenMenu = useCallback(() => setOpenMenu(undefined), []);

  const login = useCallback(() => {
    instance.loginRedirect();
  }, [instance]);

  const logOut = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  const userComponent = useMemo(() => {
    return (
      <ul className='navbar-nav'>
        <NavDropdown
          menuId='user'
          changeToggle={toggleMenu}
          toggle={openMenu === 'user'}
          name={user || <i className='fas fa-user' />}
        >
          <div className='dropdown-item' onClick={user ? logOut : login}>
            {user ? 'Log out' : 'Login'}
          </div>
        </NavDropdown>
      </ul>
    );
  }, [logOut, login, openMenu, toggleMenu, user]);

  return (
    <>
      <nav className='navbar navbar-expand-sm navbar-light bg-light' role='navigation'>
        <div className='container'>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <Link className='navbar-brand' to={'/'}>
              <img src={logo} alt='Logo' />
              FindMyCert
            </Link>
            <ul className='navbar-nav mr-auto'>
              <NavDropdown
                menuId='certificate'
                name='Certificate'
                changeToggle={toggleMenu}
                toggle={openMenu === 'certificate'}
              >
                <Link className='dropdown-item' to='/search-certificates' onClick={closeOpenMenu}>
                  Search
                </Link>
                {hasElevatedRole() && (
                  <Fragment>
                    <div className='dropdown-divider'></div>
                    <Link className='dropdown-item' reloadDocument to={'edit-heat'} onClick={closeOpenMenu}>
                      Create Heat
                    </Link>
                    <Link className='dropdown-item' reloadDocument to={'edit-supplier-certificate'} onClick={closeMenu}>
                      Create Supplier Certificate
                    </Link>
                    <Link className='dropdown-item' to='/edit-certificate' onClick={closeOpenMenu}>
                      Create Alfa Laval Certificate
                    </Link>
                  </Fragment>
                )}
              </NavDropdown>

              <NavDropdown menuId='mtr' name='MTR' changeToggle={toggleMenu} toggle={openMenu === 'mtr'}>
                <Link className='dropdown-item' to='/search-certificates' onClick={closeOpenMenu}>
                  Search
                </Link>
                <Link className='dropdown-item' to='/adhoc-mtr' onClick={closeOpenMenu}>
                  Create ADHOC MTR
                </Link>
                {hasElevatedRole() && (
                  <Fragment>
                    <div className='dropdown-divider' />
                    <Link className='dropdown-item' to='/edit-mtr' onClick={closeOpenMenu}>
                      Create MTR
                    </Link>
                  </Fragment>
                )}
              </NavDropdown>

              <NavDropdown menuId='qdoc' name='Q-doc' changeToggle={toggleMenu} toggle={openMenu === 'qdoc'}>
                <Link className='dropdown-item' to='/search-qdocs' onClick={closeOpenMenu}>
                  Search
                </Link>
                {hasElevatedRole() && (
                  <Fragment>
                    <div className='dropdown-divider'></div>
                    <Link className='dropdown-item' reloadDocument to={'/edit-qdoc'} onClick={closeOpenMenu}>
                      Create Q-doc
                    </Link>
                  </Fragment>
                )}
              </NavDropdown>
              {isAdmin() && (
                <NavDropdown menuId='admin' name='Admin' changeToggle={toggleMenu} toggle={openMenu === 'admin'}>
                  <Fragment>
                    <Link className='dropdown-item' to='/admin/manufacturer' onClick={closeOpenMenu}>
                      Manufacturer
                    </Link>
                  </Fragment>
                  <Fragment>
                    <Link className='dropdown-item' to='/admin/steelwork' onClick={closeOpenMenu}>
                      Steelwork
                    </Link>
                  </Fragment>
                  <Fragment>
                    <Link className='dropdown-item' to='/admin/supplier' onClick={closeOpenMenu}>
                      Supplier
                    </Link>
                  </Fragment>
                  <div className='dropdown-divider'></div>
                  <Fragment>
                    <Link className='dropdown-item' to='/admin/asme-certification' onClick={closeOpenMenu}>
                      ASME Certification
                    </Link>
                  </Fragment>
                </NavDropdown>
              )}
            </ul>
            {userComponent}
          </div>
        </div>
      </nav>
      {isInternetExplorer && (
        <div className='container'>
          <i>
            You are using Internet Explorer. Please use Edge or Chrome or the application will not work correctly with
            full functionality
          </i>
        </div>
      )}
    </>
  );
}

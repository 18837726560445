import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Download from '../Download/Download.jsx';

import './Certificate.css';

export default class Certificate extends Component {
  renderSupplierCertificates(supplierCertificates) {
    return supplierCertificates.map(certificate => {
      return (
        <div className='well' key={certificate.id}>
          <Link to={`/supplier-certificate/${certificate.id}/details`} target='_blank' rel='noopener noreferrer'>
            Supplier Certificate
          </Link>
          <div className='row'>
            <div className='col-sm-3'>
              <b>Lot number:</b> {certificate.lotNumber}
            </div>
            <div className='col-sm-3'>
              <b>Created:</b> {new Date(certificate.created).toLocaleDateString('sv-SE')}
            </div>
          </div>
          <br />
          <h6>Dimensions</h6>
          {this.renderProperties(certificate.dimensions, 3)}
          <br />
          <h6>Mechanical data</h6>
          {this.renderProperties(certificate.mechanicalProperties, 3)}
          <hr />
          <h6>Supplier Certificate Heats</h6>
          {this.renderHeats(certificate.heats)}
        </div>
      );
    });
  }

  renderHeats(heats) {
    return heats.map((heat, i) => {
      return (
        <Fragment key={i + heat.id}>
          <hr />
          <div className='row'>
            <div className='col-sm-4'>
              <Link to={`/heat/${heat.id}/details`}>Heat: {heat.heatNumber}</Link>
            </div>
            <div className='col-sm-4'>
              <b>Supplier:</b> {heat.supplier.name}
            </div>
          </div>
          <br />
          <h6>Material standards</h6>
          {heat.selectedStandards.map((standard, i) => {
            return (
              <div className='row' key={i + standard.id}>
                <div className='col-sm-4'>
                  <b>Standard:</b> {standard.name}
                </div>
                <div className='col-sm-4'>
                  <b>Grade:</b> {standard.materialGrade.name}
                </div>
                <div className='col-sm-4'>
                  <b>Revision:</b> {standard.standardRevision}
                </div>
              </div>
            );
          })}
          <br />
          <h6>Chemical data</h6>
          {this.renderSetOfChemicalProperties(heat.chemicalProperties, 4)}
        </Fragment>
      );
    });
  }

  renderChemicalProperties(range, nrOfItemsPerRow) {
    const output = [];
    let i;
    for (i = 0; i < range.length; i += nrOfItemsPerRow) {
      const props = range.slice(i, i + nrOfItemsPerRow);
      output.push(this.renderSetOfProperties(props, i));
    }

    return <Fragment>{output}</Fragment>;
  }

  renderSetOfChemicalProperties(props, i) {
    return (
      <div key={i + Date.now()} className='row'>
        {props.map(prop => {
          return (
            <div className='col-sm-3' key={prop.element}>
              <b>{prop.element}: </b>
              {prop.value}
            </div>
          );
        })}
      </div>
    );
  }

  renderProperties(range, nrOfItemsPerRow) {
    const output = [];
    let i;
    for (i = 0; i < range.length; i += nrOfItemsPerRow) {
      const props = range.slice(i, i + nrOfItemsPerRow);
      output.push(this.renderSetOfProperties(props, i));
    }

    return <Fragment>{output}</Fragment>;
  }

  renderSetOfProperties(props, i) {
    return (
      <div key={i + Date.now()} className='row'>
        {props.map(prop => {
          return (
            <div className='col-sm-3' key={prop.validatorId}>
              <b>{prop.description}: </b>
              {prop.value} {prop.unit}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    if (!this.props.data) {
      return (
        <Fragment>
          <br />
          <div>
            <h5>No {this.props.type} was found with the provided id</h5>
            Please contact support if this problem reoccurs
          </div>
        </Fragment>
      );
    }

    const certificate = this.props.data;

    return (
      <Fragment>
        <div>
          <br />
          <h4>{this.props.type}</h4>
          <br />
          <h5>General</h5>
          <div className='well'>
            <div className='row'>
              <div className='col-sm-4'>
                <b>{this.props.type} Id:</b> {certificate.documentId}
              </div>
              <div className='col-sm-7'>
                <b>Revision:</b> {certificate.revision}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <b>Item:</b> {certificate.item.itemNumber} {certificate.item.name}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <b>Short number:</b> {certificate.shortNumber}
              </div>
              <div className='col-sm-8'>
                <b>Marking:</b> {certificate.marking}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-4'>
                <b>Created:</b> {new Date(certificate.created).toLocaleDateString('sv-SE')}
              </div>
              <div className='col-sm-8'>
                <b>Created by:</b> {certificate.createdBy}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-8'>
                <b>Certificate File: </b>
                <Download
                  fileName={certificate.fileName}
                  identifier={certificate.id}
                  documentType={'alfa-laval-certificate'}
                  alt={'Download Alfa Laval Certificate file'}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Status: </b>
                {certificate.invalid ? 'Invalid' : 'Valid'}
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-sm-12 text-break'>
                <b>Comment: </b> {certificate.comment}
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-sm-4'>
                <b>Surface standard:</b> {certificate.surfaceStandard.description}
              </div>
              <div className='col-sm-8'>
                <b>Surface:</b> {certificate.surfaceStandard.surface.description}
                {': '}
                Inner: {certificate.surfaceStandard.surface.innerDiameter}
                {', '}
                Outer: {certificate.surfaceStandard.surface.outerDiameter}
              </div>
            </div>
          </div>
          <br />
          <h5>Data</h5>
          {this.renderSupplierCertificates(certificate.supplierCertificates)}
        </div>
        <br />
        <Link to={`/${this.props.editRoute}/${certificate.id}`} className='btn btn-primary'>
          Edit
        </Link>
      </Fragment>
    );
  }
}

export const FETCH_BASICSUPPLIERCERTIFICATES_PENDING = 'FETCH_BASICSUPPLIERCERTIFICATES_PENDING';
export const FETCH_BASICSUPPLIERCERTIFICATES_SUCCESS = 'FETCH_BASICSUPPLIERCERTIFICATES_SUCCESS';
export const FETCH_BASICSUPPLIERCERTIFICATES_ERROR = 'FETCH_BASICSUPPLIERCERTIFICATES_ERROR';

export function fetchBasicSupplierCertificatesPending() {
  return {
    type: FETCH_BASICSUPPLIERCERTIFICATES_PENDING
  };
}

export function fetchBasicSupplierCertificatesSuccess(data) {
  return {
    type: FETCH_BASICSUPPLIERCERTIFICATES_SUCCESS,
    data: data
  };
}

export function fetchBasicSupplierCertificatesError(error) {
  return {
    type: FETCH_BASICSUPPLIERCERTIFICATES_ERROR,
    error: error
  };
}

import React, { useMemo } from 'react';
import { Row } from 'react-bootstrap';

export function FormRow({ children, className, ...restProps }) {
  const classes = useMemo(() => ['mb-4', className].join(' ').trim(), [className]);

  return (
    <Row className={classes} {...restProps}>
      {children}
    </Row>
  );
}

export const FETCH_ITEM_PENDING = 'FETCH_ITEM_PENDING';
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_ERROR = 'FETCH_ITEM_ERROR';
export const FETCH_ITEM_CLEAR = 'FETCH_ITEM_CLEAR';

export function fetchItemPending() {
  return {
    type: FETCH_ITEM_PENDING
  };
}

export function fetchItemSuccess(item) {
  return {
    type: FETCH_ITEM_SUCCESS,
    item: item
  };
}

export function fetchItemError(error) {
  return {
    type: FETCH_ITEM_ERROR,
    error: error
  };
}

export function fetchItemClear() {
  return {
    type: FETCH_ITEM_CLEAR
  };
}

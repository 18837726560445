import { fetchSurfacesPending, fetchSurfacesSuccess, fetchSurfacesError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTokenIfExists } from 'auth/utils';

const fetchSurfacesAsync = createAsyncThunk('surface/fetch', async (type, thunkAPI) => {
  thunkAPI.dispatch(fetchSurfacesPending());

  try {
    const headers = {
      'Content-Type': 'application/json; v=2.0',
      Accept: 'application/json; v=2.0'
    };

    const token = await getTokenIfExists(thunkAPI.extra.acquireTokenSilent);

    if (token) {
      headers['Authorization'] = 'Bearer ' + token.accessToken;
    }
    const res = await fetch('api/surface/' + type, {
      headers: headers
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchSurfacesError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchSurfacesSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchSurfacesError(error));
  }
});

export default fetchSurfacesAsync;

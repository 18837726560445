import React, { useCallback, useEffect, useRef, useState } from 'react';
import './filetransfer.scss';

import WarningModal from '../Modals/WarningModal.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFileClear } from 'store/uploads/actions.js';
import uploadFileAsync from 'store/uploads/uploadFile.js';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

export function FileTransfer({ setFileData, fileName, entityId, uploadDocumentType, downloadDocumentType }) {
  const ref = useRef(null);

  const [fileState, setFileState] = useState({ file: undefined, fileName: '' });
  const [showUploadFailedModal, setShowUploadFailedModal] = useState(false);

  const dispatch = useDispatch();
  const uploadFile = useSelector(state => state.uploadFile);

  useEffect(() => {
    dispatch(uploadFileClear());
  }, [dispatch]);

  useEffect(() => {
    if (uploadFile.error) {
      setShowUploadFailedModal(true);
    }

    if (!uploadFile.pending && uploadFile.data) {
      setFileData(fileState.fileName, uploadFile.data);
    }
  }, [uploadFile, fileState]);

  const onFileChange = useCallback(
    input => {
      let newFileName;
      let newFile;
      if (input.target.files?.length > 0) {
        newFileName = input.target.files[0].name;
        newFile = input.target.files[0];
      } else if (input.files?.length > 0) {
        newFileName = input.files[0].name;
        newFile = input.files[0];
      } else {
        return;
      }

      setFileState({ fileName: newFileName, file: newFile });

      dispatch(uploadFileAsync({ type: uploadDocumentType, file: newFile }));
    },
    [dispatch, uploadDocumentType]
  );

  const toggleUploadFailedModal = useCallback(() => {
    setShowUploadFailedModal(prevState => !prevState);
  }, []);

  console.log(fileName);

  return (
    <>
      <Form.Group className='form-group'>
        <Form.Label htmlFor='file'>Upload file *</Form.Label>
        <InputGroup>
          <Form.Label className='custom-file-label flex-grow-1' htmlFor='file'>
            {fileName || 'Choose file'}
            <Form.Control
              ref={ref}
              className='custom'
              type='file'
              id='file'
              onChange={onFileChange}
              required={!fileName}
            />
            <div className='custom-file-browse-button'>Browse</div>
            {uploadFile.pending && (
              <div className='spinner-border spinner-border-sm status' role='status'>
                <span className='sr-only'>Uploading file...</span>
              </div>
            )}
            {uploadFile.error && fileName && (
              <div
                className='fa fa-exclamation-triangle status'
                role='status'
                title='Unable to upload file, please try again'
              >
                <span className='sr-only'>Unable to upload file, please try again</span>
              </div>
            )}
            {uploadFile.data && fileName && (
              <div className='fa fa-check status' role='status' title='File uploaded'>
                <span className='sr-only'>Unable to upload file, please try again</span>
              </div>
            )}
          </Form.Label>

          {entityId && fileName && (
            <InputGroup.Text as='a' href={`/api/download/${downloadDocumentType}/${entityId}`}>
              View
            </InputGroup.Text>
          )}
        </InputGroup>
      </Form.Group>
      <UploadFailedModal
        showUploadFailedModal={showUploadFailedModal}
        uploadFile={uploadFile}
        toggleUploadFailedModal={toggleUploadFailedModal}
      />
    </>
  );
}

function UploadFailedModal({ showUploadFailedModal, uploadFile, toggleUploadFailedModal }) {
  if (!showUploadFailedModal) {
    return null;
  }
  const error = uploadFile?.error;
  const innerErrors = error?.innerErrors.map(x => x.innerErrors).flat();
  const body = (
    <>
      <div>Reason: {error?.message}</div>
      {innerErrors.map(innerError => {
        return <div key={innerError.message}>{innerError.message}</div>;
      })}
    </>
  );
  const header = 'Unable to upload file.';

  return <WarningModal show={true} toggle={toggleUploadFailedModal} header={header} body={body} />;
}

import { POST_QDOC_PENDING, POST_QDOC_SUCCESS, POST_QDOC_ERROR, POST_QDOC_CLEAR } from './actions';

const initialState = {
  pending: false,
  data: undefined,
  error: undefined
};

export default function postQDocReducer(state = initialState, action) {
  switch (action.type) {
    case POST_QDOC_PENDING:
      return {
        ...state,
        pending: true
      };
    case POST_QDOC_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
        error: undefined
      };
    case POST_QDOC_ERROR:
      return {
        ...state,
        pending: false,
        data: undefined,
        error: action.error
      };
    case POST_QDOC_CLEAR:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

import { useCallback } from 'react';
import { useApi } from 'api/useApi';

export function useDocumentIds(type) {
  const { postJson } = useApi();

  const generateItems = useCallback(
    async items => {
      try {
        const itemsWithIds = items.filter(x => x.documentId !== undefined);
        if (itemsWithIds.length === items.length) {
          return items;
        }

        const itemsWithoutDocumentIds = items.filter(x => x.documentId === undefined);
        if (itemsWithoutDocumentIds.length) {
          const url = `document-id?documentType=${type}&nrOfIds=${itemsWithoutDocumentIds.length}`;
          const ids = await postJson(url);

          ids.forEach((docId, index) => {
            let item = itemsWithoutDocumentIds[index];
            item.documentId = docId;
            itemsWithIds.push(item);
          });
        }

        return itemsWithIds;
      } catch (_) {
        return [];
      }
    },
    [type, postJson]
  );

  return { generateItems };
}

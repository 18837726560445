import { fetchHeatsPending, fetchHeatsSuccess, fetchHeatsError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchBasicHeatsAsync = createAsyncThunk('heat/list/basic-information', async (heatNumber, thunkAPI) => {
  thunkAPI.dispatch(fetchHeatsPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/heat/list/basic-information?heatNumber=' + heatNumber, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchHeatsError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchHeatsSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchHeatsError(error));
  }
});

export default fetchBasicHeatsAsync;

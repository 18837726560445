import {
  fetchDocumentCertificationPending,
  fetchDocumentCertificationSuccess,
  fetchDocumentCertificationError
} from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchDocumentCertificationAsync = createAsyncThunk(
  'document/asme-certification/current',
  async (id, thunkAPI) => {
    thunkAPI.dispatch(fetchDocumentCertificationPending());

    try {
      const token = await thunkAPI.extra.acquireTokenSilent();
      const res = await fetch('api/document/asme-certification/current', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token.accessToken,
          'Content-Type': 'application/json; v=1.0',
          Accept: 'application/json; v=1.0'
        }
      });
      if (!res.ok) {
        thunkAPI.dispatch(fetchDocumentCertificationError({ message: 'Unknown error' }));
        return;
      }

      const data = await res.json();
      thunkAPI.dispatch(fetchDocumentCertificationSuccess(data));
    } catch (error) {
      thunkAPI.dispatch(fetchDocumentCertificationError(error));
    }
  }
);

export default fetchDocumentCertificationAsync;

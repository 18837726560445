import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchMtrAsync from '../../store/mtr/fetchMtr';
import CertificateSource from './CertificateSource.jsx';
import fetchDocumentCertificationAsync from '../../store/documentCertification/fetchDocumentCertification';
import Unauthorized from '../Layout/Unauthoried.jsx';
import { useParams } from 'react-router';
import Spinner from 'components/Layout/Spinner';
import { useAuth } from 'auth/useAuth';

export function MtrSourceDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { hasElevatedRole, isAsmeSupplier } = useAuth();

  const mtr = useSelector(state => state.mtr);
  const documentCertification = useSelector(state => state.documentCertification);

  useEffect(() => {
    if (hasElevatedRole() && id) {
      dispatch(fetchMtrAsync(id));

      if (isAsmeSupplier()) {
        dispatch(fetchDocumentCertificationAsync());
      }
    }
  }, [dispatch, id, hasElevatedRole, isAsmeSupplier]);

  const mtrData = useMemo(() => {
    let data;

    if (mtr.data && !mtr.pending) {
      data = mtr.data;

      if (isAsmeSupplier() && documentCertification.data && !documentCertification.pending) {
        data.documentCertification = documentCertification.data;
      }
    }
    return data;
  }, [mtr, documentCertification, isAsmeSupplier]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (!mtr) {
    return null;
  }
  if (!mtr.data || mtr.pending) {
    return <Spinner />;
  }

  return <CertificateSource data={mtrData} updateComplete={() => {}} type='MTR' />;
}

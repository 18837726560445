import { fetchChemicalRangesPending, fetchChemicalRangesSuccess, fetchChemicalRangesError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchChemicalRangesAsync = createAsyncThunk('chemical-property/valid-ranges', async (materials, thunkAPI) => {
  thunkAPI.dispatch(fetchChemicalRangesPending());

  const queryParams = materials
    .map(
      (material, i) =>
        `${i > 0 ? '&' : ''}[${i}].materialStandardId=${material.materialStandardId}&[${i}].materialGradeId=${
          material.materialGradeId
        }`
    )
    .join('');

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch(`api/chemical-property/valid-ranges?${queryParams}`, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchChemicalRangesError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchChemicalRangesSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchChemicalRangesError(error));
  }
});

export default fetchChemicalRangesAsync;

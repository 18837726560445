export const FETCH_CHEMICALRANGES_PENDING = 'FETCH_CHEMICALRANGES_PENDING';
export const FETCH_CHEMICALRANGES_SUCCESS = 'FETCH_CHEMICALRANGES_SUCCESS';
export const FETCH_CHEMICALRANGES_ERROR = 'FETCH_CHEMICALRANGES_ERROR';

export function fetchChemicalRangesPending() {
  return {
    type: FETCH_CHEMICALRANGES_PENDING
  };
}

export function fetchChemicalRangesSuccess(chemicalRanges) {
  return {
    type: FETCH_CHEMICALRANGES_SUCCESS,
    chemicalRanges: chemicalRanges
  };
}

export function fetchChemicalRangesError(error) {
  return {
    type: FETCH_CHEMICALRANGES_ERROR,
    error: error
  };
}

import React from 'react';
import { SearchAll } from '../Search';
import { Container, Row, Col } from 'react-bootstrap';

export function Home() {
  return (
    <Container className='mt-3'>
      <Row>
        <Col>
          <h3 className='text-center'>Welcome to Alfa Laval&apos;s Certificate Database</h3>
        </Col>
      </Row>
      <SearchAll useQdocs={true} useCerts={true} />
      <Container className='mt-3'>
        <Row>
          <Col xs='0' sm='1' lg='2' xl='3' />
          <Col xs='12' sm='10' lg='8' xl='6'>
            <h5>What can you find?</h5>
            <p>
              Certificates and MTR&apos;s (Material Test Report): search by heat number, item number, Alfa Laval ID
              and/or MTR ID.
            </p>
            <p>Q-docs: search by serial number and/or item number.</p>

            <h6>Need Installation Material as product model CAD files?</h6>
            <p className='small'>
              <a
                href='https://alfalaval.partcommunity.com/3d-cad-models/installation-material-alfa-laval?info=alfalaval%2Finstallation_material&cwid=5496'
                target='_blank'
                rel='noopener noreferrer'
              >
                Visit the Alfa Laval CAD portal here.
              </a>
            </p>
          </Col>
          <Col xs='0' sm='1' lg='2' xl='3' />
        </Row>
      </Container>
    </Container>
  );
}

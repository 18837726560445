import { postEditHeatPending, postEditHeatSuccess, postEditHeatError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const postHeatAsync = createAsyncThunk('heat/create', async (heat, thunkAPI) => {
  thunkAPI.dispatch(postEditHeatPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/heat/create', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(heat)
    });
    const data = await res.json();
    if (!res.ok) {
      thunkAPI.dispatch(postEditHeatError(data));
      return;
    }
    thunkAPI.dispatch(postEditHeatSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(postEditHeatError(error));
  }
});

export default postHeatAsync;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout.jsx';

import './App.css';
import { AsmeCertification } from './components/Admin/AsmeCertification.jsx';
import { CertificateDetails } from './components/Certificates/CertificateDetails.jsx';
import { CertificateSourceDetails } from './components/CertificateSource/CertificateSourceDetails.jsx';
import { CreateAdHocMtr } from './components/Mtr/CreateAdHocMtr';
import { EditAlfaLavalCertificate } from './components/Certificates/EditAlfaLavalCertificate.jsx';
import { EditHeat } from './components/Heats/EditHeat.jsx';
import { EditMtr } from './components/Mtr/EditMtr.jsx';
import { EditQDoc } from './components/QDoc/EditQDoc.jsx';
import { EditSupplierCertificate } from './components/SupplierCertificates/EditSupplierCertificate.jsx';
import { HeatDetails } from './components/Heats/HeatDetails.jsx';
import { Home } from 'components/Layout/Home.jsx';
import { Manufacturer } from './components/Admin/Manufacturer.jsx';
import { MtrDetails } from './components/Mtr/MtrDetails.jsx';
import { MtrSourceDetails } from './components/CertificateSource/MtrSourceDetails.jsx';
import { QDocDetails } from './components/QDoc/QDocDetails.jsx';
import { SearchAll } from 'components/Search';
import { Steelwork } from './components/Admin/Steelwork.jsx';
import { Supplier } from './components/Admin/Supplier.jsx';
import { SupplierCertificateDetails } from './components/SupplierCertificates/SupplierCertificateDetails.jsx';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout className='App' />}>
          <Route index element={<Home />} />
          <Route path='home' element={<Home />} />

          <Route path='edit-heat' element={<EditHeat />} />
          <Route path='edit-heat/:id' element={<EditHeat />} />
          <Route path='heat/:id/details' element={<HeatDetails />} />

          <Route path='edit-supplier-certificate' element={<EditSupplierCertificate />} />
          <Route path='edit-supplier-certificate/:id' element={<EditSupplierCertificate />} />
          <Route path='supplier-certificate/:id/details' element={<SupplierCertificateDetails />} />

          <Route path='edit-certificate' element={<EditAlfaLavalCertificate />} />
          <Route path='edit-certificate/:id' element={<EditAlfaLavalCertificate />} />

          <Route path='edit-mtr' element={<EditMtr />} />
          <Route path='edit-mtr/:id' element={<EditMtr />} />

          <Route path='edit-qdoc' element={<EditQDoc />} />
          <Route path='edit-qdoc/:id' element={<EditQDoc />} />
          <Route path='qdoc/:id/details' element={<QDocDetails />} />

          <Route path='adhoc-mtr' element={<CreateAdHocMtr />} />
          <Route path='certificate/:id/details' element={<CertificateDetails />} />
          <Route path='mtr/:id/details' element={<MtrDetails />} />

          <Route path='search-certificates' element={<SearchAll useQdocs={false} useCerts={true} />} />
          <Route path='search-qdocs' element={<SearchAll useQdocs={true} useCerts={false} />} />

          <Route path='admin/manufacturer' element={<Manufacturer />} />
          <Route path='admin/steelwork' element={<Steelwork />} />
          <Route path='admin/supplier' element={<Supplier />} />
          <Route path='admin/asme-certification' element={<AsmeCertification />} />
        </Route>

        <Route path='/certificate-source/:id' element={<CertificateSourceDetails />} />
        <Route path='/mtr-source/:id' element={<MtrSourceDetails />} />
      </Routes>
    </>
  );
}

export default App;

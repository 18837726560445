export const SEARCH_QDOCS_PENDING = 'SEARCH_QDOCS_PENDING';
export const SEARCH_QDOCS_SUCCESS = 'SEARCH_QDOCS_SUCCESS';
export const SEARCH_QDOCS_ERROR = 'SEARCH_QDOCS_ERROR';

export function searchQDocsPending() {
  return {
    type: SEARCH_QDOCS_PENDING,
    data: null,
    error: null
  };
}

export function searchQDocsSuccess(data) {
  return {
    type: SEARCH_QDOCS_SUCCESS,
    data: data,
    error: null
  };
}

export function searchQDocsError(error) {
  return {
    type: SEARCH_QDOCS_ERROR,
    error: error,
    data: null
  };
}

import React, { useCallback, useMemo } from 'react';
import './SearchAll.css';
import { useAuth } from 'auth/useAuth';
import { Form, Container, Button, Row } from 'react-bootstrap';

export function SearchForm({
  useCerts,
  useQdocs,
  advancedSearch,
  searchInputForm,
  onAdvancedSearch,
  search,
  onSearchInputFormChange
}) {
  const { hasElevatedRole } = useAuth();

  const onToggleAdvancedSearch = useCallback(() => onAdvancedSearch(!advancedSearch), [
    onAdvancedSearch,
    advancedSearch
  ]);

  const placeholder = useMemo(() => {
    let placeHolder = hasElevatedRole()
      ? 'Heat, item, PO, lot or serial number, Alfa Laval or MTR ID...'
      : 'Heat, item, or serial number, Alfa Laval or MTR ID...';
    if (useCerts && !useQdocs) {
      placeHolder = hasElevatedRole()
        ? 'Heat, item, PO or lot number, Alfa Laval or MTR ID...'
        : 'Heat or item number, Alfa Laval or MTR ID...';
    }
    if (useQdocs && !useCerts) {
      placeHolder = 'Serial or item number...';
    }
    return placeHolder;
  }, [useCerts, useQdocs, hasElevatedRole]);

  const onUpdateSearchInputForm = useCallback(
    e => {
      const { id, value } = e.target;
      onSearchInputFormChange(prevState => ({ ...prevState, [id]: value }));
    },
    [onSearchInputFormChange]
  );

  const onSearch = useCallback(
    async e => {
      e.preventDefault();
      await search(1);
    },
    [search]
  );

  return (
    <Form className='col-12' onSubmit={onSearch}>
      <Container>
        <Form.Group className='mb-3'>
          <Button type='button' variant='link' onClick={onToggleAdvancedSearch}>{`${
            advancedSearch ? 'Hide' : 'Show'
          } advanced search`}</Button>
        </Form.Group>
        {!advancedSearch && (
          <Form.Group className='mb-3'>
            <input
              id='keyword'
              type='text'
              className='form-control'
              placeholder={placeholder}
              onChange={onUpdateSearchInputForm}
              value={searchInputForm.keyword}
            />
          </Form.Group>
        )}
      </Container>
      {advancedSearch && (
        <Container className='mb-3'>
          <Row className='mb-3'>
            <Form.Group className='col-xs-1 col-md-3'>
              <label htmlFor='heatNumber'>Heat No.</label>
              <input
                id='heatNumber'
                type='text'
                className='form-control'
                onChange={onUpdateSearchInputForm}
                value={searchInputForm.heatNumber}
              />
            </Form.Group>
            <Form.Group className='col-xs-1 col-md-3'>
              <label htmlFor='itemNumber'>Item No.</label>
              <input
                id='itemNumber'
                type='text'
                className='form-control'
                onChange={onUpdateSearchInputForm}
                value={searchInputForm.itemNumber}
              />
            </Form.Group>
            <Form.Group className='col-xs-1 col-md-3'>
              <label htmlFor='alfaLavalId'>Alfa Laval ID</label>
              <input
                id='alfaLavalId'
                type='text'
                className='form-control'
                onChange={onUpdateSearchInputForm}
                value={searchInputForm.alfaLavalId}
              />
            </Form.Group>
            <Form.Group className='col-xs-1 col-md-3'>
              <label htmlFor='mtrId'>MTR ID</label>
              <input
                id='mtrId'
                type='text'
                className='form-control'
                onChange={onUpdateSearchInputForm}
                value={searchInputForm.mtrId}
              />
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            {hasElevatedRole() && (
              <Form.Group className='col-xs-1 col-md-3'>
                <label htmlFor='lotNumber'>Lot No.</label>
                <input
                  id='lotNumber'
                  type='text'
                  className='form-control'
                  onChange={onUpdateSearchInputForm}
                  value={searchInputForm.lotNumber}
                />
              </Form.Group>
            )}
            {hasElevatedRole() && (
              <Form.Group className='col-xs-1 col-md-3'>
                <label htmlFor='poNumber'>PO No.</label>
                <input
                  id='poNumber'
                  type='text'
                  className='form-control'
                  onChange={onUpdateSearchInputForm}
                  value={searchInputForm.poNumber}
                />
              </Form.Group>
            )}
            {useQdocs && (
              <Form.Group className='col-xs-1 col-md-3 '>
                <label htmlFor='serialNumber'>Q-doc Serial No.</label>
                <input
                  id='serialNumber'
                  type='text'
                  className='form-control'
                  onChange={onUpdateSearchInputForm}
                  value={searchInputForm.serialNumber}
                />
              </Form.Group>
            )}
          </Row>
        </Container>
      )}
      <Container className='mb-3'>
        <Form.Group>
          <Button type='submit' className='btn btn-primary d-block ml-auto'>
            Search
          </Button>
        </Form.Group>
      </Container>
    </Form>
  );
}

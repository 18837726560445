import { FETCH_DOCUMENTID_PENDING, FETCH_DOCUMENTID_SUCCESS, FETCH_DOCUMENTID_ERROR } from './actions';

const initialState = {
  pending: false,
  data: [],
  error: null
};

export default function documentIdsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENTID_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_DOCUMENTID_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data
      };
    case FETCH_DOCUMENTID_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getDocumentId = state => state.data;
export const getDocumentIdPending = state => state.pending;
export const getDocumentIdError = state => state.error;

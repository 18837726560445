import React, { useCallback } from 'react';
import { FileTransfer } from 'components/FileTransfer/FileTransfer';
import { Col, Row } from 'react-bootstrap';

export function EditQdocFileUpload({ uploadFile, setFileData, id, fileName }) {
  const onSetFileData = useCallback((fileName, fileId) => setFileData(fileName, fileId), [setFileData]);

  return (
    <>
      <Row>
        <h5>File</h5>
      </Row>
      <Row>
        <Col sm={8}>
          <FileTransfer
            uploadFile={uploadFile}
            setFileData={onSetFileData}
            fileName={fileName}
            entityId={id}
            uploadDocumentType='Qdoc'
            downloadDocumentType='Qdoc'
          />
        </Col>
      </Row>
    </>
  );
}

import { useAuth } from 'auth/useAuth';
import { useCallback, useState } from 'react';

export function useApi() {
  const { getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const execute = useCallback(
    async ({ url, body, suppressError, method, headers }) => {
      try {
        setIsLoading(true);
        const token = await getToken();
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        const res = await fetch(`api/${url}`, {
          method,
          headers: {
            ...headers
          },
          body: body ? JSON.stringify(body) : undefined
        });

        if (!res.ok) {
          throw new ApiError(`HTTP error, status = ${res.status}`, {
            details: res.bodyUsed ? await res.json() : res
          });
        }

        if (res.status === 204) return;

        return res.json();
      } catch (error) {
        if (!suppressError) console.error(error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [getToken]
  );

  const getJson = useCallback(
    (url, suppressError) =>
      execute({ url, suppressError, method: 'GET', headers: { 'Content-Type': 'application/json' } }),
    [execute]
  );
  const postJson = useCallback(
    (url, body, suppressError) =>
      execute({ url, body, suppressError, method: 'POST', headers: { 'Content-Type': 'application/json' } }),
    [execute]
  );
  const patchJson = useCallback(
    (url, body, suppressError) =>
      execute({ url, body, suppressError, method: 'PATCH', headers: { 'Content-Type': 'application/json' } }),
    [execute]
  );
  const deleteJson = useCallback(
    (url, suppressError) =>
      execute({ url, suppressError, method: 'DELETE', headers: { 'Content-Type': 'application/json' } }),
    [execute]
  );

  return { getJson, postJson, patchJson, deleteJson, isLoading };
}

class ApiError extends Error {
  constructor(message, options) {
    super(message);
    this.name = 'ApiError';
    this.details = options?.details;
  }
}

export const POST_STEELWORK_PENDING = 'POST_STEELWORK_PENDING';
export const POST_STEELWORK_SUCCESS = 'POST_STEELWORK_SUCCESS';
export const POST_STEELWORK_ERROR = 'POST_STEELWORK_ERROR';
export const POST_STEELWORK_CLEAR = 'POST_STEELWORK_CLEAR';

export function postSteelworkPending() {
  return {
    type: POST_STEELWORK_PENDING
  };
}

export function postSteelworkSuccess(data) {
  return {
    type: POST_STEELWORK_SUCCESS,
    data: data
  };
}

export function postSteelworkError(error) {
  return {
    type: POST_STEELWORK_ERROR,
    error: error
  };
}

export function postSteelworkClear() {
  return {
    type: POST_STEELWORK_CLEAR
  };
}

import { POST_EDITHEAT_PENDING, POST_EDITHEAT_SUCCESS, POST_EDITHEAT_ERROR, POST_EDITHEAT_CLEAR } from './actions';

const initialState = {
  pending: false,
  data: undefined,
  error: null
};

export default function editHeatReducer(state = initialState, action) {
  switch (action.type) {
    case POST_EDITHEAT_PENDING:
      return {
        ...state,
        pending: true
      };
    case POST_EDITHEAT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.heat,
        error: null
      };
    case POST_EDITHEAT_ERROR:
      return {
        data: undefined,
        pending: false,
        error: action.error
      };
    case POST_EDITHEAT_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}

import React from 'react';

const Unauthorized = () => {
  return (
    <div>
      <br />
      <br />
      <div className='form-row col-sm-12'>
        <span>Unauthorized. Please contact support and place a ticket if you want access to FindMyCert...</span>
      </div>
    </div>
  );
};

export default Unauthorized;

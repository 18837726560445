import { FETCH_MATERIALS_PENDING, FETCH_MATERIALS_SUCCESS, FETCH_MATERIALS_ERROR } from './actions';

const initialState = {
  pending: false,
  data: [],
  error: null
};

export default function materialsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MATERIALS_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_MATERIALS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.materials
      };
    case FETCH_MATERIALS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getMaterials = state => state.materials;
export const getMaterialsPending = state => state.pending;
export const getMaterialsError = state => state.error;

import { FETCH_MTR_PENDING, FETCH_MTR_SUCCESS, FETCH_MTR_ERROR, FETCH_MTR_CLEAR } from './actions';

const initialState = {
  pending: false,
  data: null,
  error: null
};

export default function mtrReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MTR_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_MTR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data
      };
    case FETCH_MTR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case FETCH_MTR_CLEAR:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export const getMtr = state => state.heat;
export const getMtrPending = state => state.pending;
export const getMtrError = state => state.error;

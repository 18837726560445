import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion, Col, Row } from 'react-bootstrap';
import { PaginatedResult } from './PaginatedResult.jsx';
import { calucalatePageCount, stopPropagation } from './utils';
import { PAGE_COUNT } from './useSearch';

import './SearchCertificatesResult.css';
import { useAuth } from 'auth/useAuth.js';

export function SearchHeats({ shouldRender, search }) {
  const { pending, data, error } = useSelector(state => state.searchHeats);
  const [pageCount, setPageCount] = useState(1);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    if (!pending) {
      setPageCount(calucalatePageCount(data?.totalCount, PAGE_COUNT));
    }
  }, [data, pending]);

  if (!shouldRender) {
    return null;
  }
  const totalCount = hasElevatedRole()
    ? data?.totalCount
    : data?.totalCount > data?.results?.length && data?.totalCount > 15
    ? `${15}+`
    : data?.results?.length;

  return (
    <Accordion className='mt-4'>
      <Accordion.Item eventKey='heatsSearch'>
        <Accordion.Header>
          <Row className='flex-grow-1'>
            <Col xs={4}>
              <h5>
                Heats&nbsp;
                {pending ? <i className='fas fa-circle-notch fa-spin' /> : totalCount ? `(${totalCount})` : undefined}
              </h5>
            </Col>
            {error && (
              <Col xs={8}>
                <p className='color-error'>{error.message}</p>
              </Col>
            )}{' '}
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <Accordion>
            {data?.results?.map((heat, i) => (
              <HeatRow key={heat.heat.id + ' ' + i} heat={heat.heat} />
            ))}
          </Accordion>
          <br />
          <PaginatedResult totalCount={data?.totalCount} pageCount={pageCount} search={search} pending={pending} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function HeatRow({ heat }) {
  return (
    <Accordion.Item eventKey={heat.id} className={heat.invalid ? 'invalid-card' : undefined}>
      <Accordion.Header>
        <Col>
          <Row className='align-items-top'>
            <Col>
              <b>Heat</b>
              <ul className='list-unstyled mb-md-0'>
                <li>
                  <SearchResult type={'heat'} id={heat.id} description={`${heat.heatNumber} ${heat.supplier}`} />
                </li>
              </ul>
            </Col>
            <Col>
              <b>Type</b>
              <ul className='list-unstyled mb-md-0'>
                <li>{heat.certificateType}</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          <Col>
            <b>Material standards</b>
            <ul className='list-unstyled mb-md-0'>
              {heat.materialStandards &&
                heat.materialStandards.map((material, index) => (
                  <li key={`${heat.id}_ms_${index}`}>
                    <div>{`${material.materialStandard} ${material.materialGrade}${
                      material.materialStandardRevision ? `, ${material.materialStandardRevision}` : ''
                    }`}</div>
                  </li>
                ))}
            </ul>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function SearchResult({ type, id, description, invalid }) {
  return (
    <Link
      className={invalid ? 'invalid-certificate' : undefined}
      key={id}
      to={`/${type}/${id}/details`}
      target='_blank'
      rel='noopener noreferrer'
      onClick={stopPropagation}
      title={invalid ? 'The certificate is invalid' : undefined}
    >
      {`${description}`} {invalid && <i className='fa fa-exclamation m-1' />}
    </Link>
  );
}

export const FETCH_MANUFACTURERS_PENDING = 'FETCH_MANUFACTURERS_PENDING';
export const FETCH_MANUFACTURERS_SUCCESS = 'FETCH_MANUFACTURERS_SUCCESS';
export const FETCH_MANUFACTURERS_ERROR = 'FETCH_MANUFACTURERS_ERROR';

export function fetchManufacturersPending() {
  return {
    type: FETCH_MANUFACTURERS_PENDING
  };
}

export function fetchManufacturersSuccess(manufacturers) {
  return {
    type: FETCH_MANUFACTURERS_SUCCESS,
    manufacturers: manufacturers
  };
}

export function fetchManufacturersError(error) {
  return {
    type: FETCH_MANUFACTURERS_ERROR,
    error: error
  };
}

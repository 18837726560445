import React, { Component, Fragment } from 'react';
import { NavMenu } from './NavMenu.jsx';
import { Outlet } from 'react-router';

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      close: false
    };
  }

  toggleMenu() {
    this.setState({
      close: !this.state.close
    });
  }

  render() {
    return (
      <Fragment>
        <NavMenu closeAll={this.state.close} closeMenu={this.toggleMenu.bind(this)} />
        <div className='container' onClick={this.toggleMenu.bind(this)}>
          <Outlet />
        </div>
      </Fragment>
    );
  }
}
export default Layout;

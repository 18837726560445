import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Unauthorized from '../Layout/Unauthoried.jsx';
import fetchSuppliersAsync from '../../store/suppliers/fetchSuppliers';
import postSupplierAsync from '../../store/editSupplier/postSupplier';

import { WriteSupplier } from './WriteSupplier.jsx';
import { useAuth } from 'auth/useAuth.js';
import { postSupplierClear } from 'store/editSupplier/actions.js';

export function Supplier() {
  const suppliers = useSelector(state => state.suppliers);
  const editSupplier = useSelector(state => state.editSupplier);
  const dispatch = useDispatch();

  const { isAdmin } = useAuth();

  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);

  const fetchData = useCallback(() => {
    dispatch(postSupplierClear());
    dispatch(fetchSuppliersAsync());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!editSupplier?.error && !editSupplier?.pending && !!editSupplier?.data) {
      fetchData();
    }
  }, [editSupplier, fetchData]);

  useEffect(() => {
    if (suppliers?.data) {
      const mappedItems = suppliers.data.map(x => ({
        id: x.id,
        revision: x.revision,
        created: x.created,
        createdBy: x.createdBy,
        name: x.name,
        invalid: x.invalid,
        useCertificate: x.documentTypeRoles.includes('Certificate') === true,
        useQDoc: x.documentTypeRoles.includes('Qdoc') === true,
        revisions: x.revisions
      }));
      setItems(mappedItems);
      setAllItems(mappedItems);
    }
  }, [suppliers]);

  const updateItems = useCallback(items => {
    setItems([...items]);
  }, []);

  const save = useCallback(
    item => {
      item.documentTypeRoles = [];
      if (item.useCertificate) {
        item.documentTypeRoles.push('Certificate');
      }
      if (item.useQDoc) {
        item.documentTypeRoles.push('Qdoc');
      }

      dispatch(postSupplierAsync(item));
    },
    [dispatch]
  );

  if (!isAdmin()) {
    return <Unauthorized />;
  }
  return (
    <WriteSupplier
      name='Supplier'
      items={items}
      allItems={allItems}
      save={save}
      saveResult={editSupplier}
      refreshData={fetchData}
      setItems={updateItems}
      isAdmin={isAdmin}
    />
  );
}

import {
  POST_EDITSUPPLIERCERTIFICATE_PENDING,
  POST_EDITSUPPLIERCERTIFICATE_SUCCESS,
  POST_EDITSUPPLIERCERTIFICATE_ERROR,
  EDITSUPPLIERCERTIFICATE
} from './actions';

const initialState = {
  pending: false,
  data: undefined,
  error: null
};

export default function editSupplierCertificate(state = initialState, action) {
  switch (action.type) {
    case POST_EDITSUPPLIERCERTIFICATE_PENDING:
      return {
        ...state,
        pending: true
      };
    case POST_EDITSUPPLIERCERTIFICATE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.supplierCertificate,
        error: null
      };
    case POST_EDITSUPPLIERCERTIFICATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case EDITSUPPLIERCERTIFICATE:
      return {
        ...state,
        pending: false,
        data: action.supplierCertificate,
        error: null
      };

    default:
      return state;
  }
}

import { FETCH_STEELWORKS_PENDING, FETCH_STEELWORKS_SUCCESS, FETCH_STEELWORKS_ERROR } from './actions';

const initialState = {
  pending: false,
  data: [],
  error: null
};

export default function steelworksReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STEELWORKS_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_STEELWORKS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.steelworks,
        error: null
      };
    case FETCH_STEELWORKS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: null
      };
    default:
      return state;
  }
}

export const getSteelworks = state => state.steelWorks;
export const getSteelworksPending = state => state.pending;
export const getSteelworksError = state => state.error;

import {
  POST_ASMECERTIFICATION_PENDING,
  POST_ASMECERTIFICATION_SUCCESS,
  POST_ASMECERTIFICATION_ERROR,
  POST_ASMECERTIFICATION_CLEAR
} from './actions';

const initialState = {
  pending: false,
  successful: false,
  error: null
};

export default function asmeCertificationReducer(state = initialState, action) {
  switch (action.type) {
    case POST_ASMECERTIFICATION_PENDING:
      return {
        ...state,
        pending: true
      };
    case POST_ASMECERTIFICATION_SUCCESS:
      return {
        ...state,
        pending: false,
        successful: true,
        error: null
      };
    case POST_ASMECERTIFICATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        successful: false
      };
    case POST_ASMECERTIFICATION_CLEAR:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export const POST_ADHOCMTR_PENDING = 'POST_ADHOCMTR_PENDING';
export const POST_ADHOCMTR_SUCCESS = 'POST_ADHOCMTR_SUCCESS';
export const POST_ADHOCMTR_ERROR = 'POST_ADHOCMTR_ERROR';
export const POST_ADHOCMTR_CLEAR = 'POST_ADHOCMTR_CLEAR';

export function postAdHocMtrPending() {
  return {
    type: POST_ADHOCMTR_PENDING
  };
}

export function postAdHocMtrSuccess(certificate) {
  return {
    type: POST_ADHOCMTR_SUCCESS,
    certificate: certificate
  };
}

export function postAdHocMtrError(error) {
  return {
    type: POST_ADHOCMTR_ERROR,
    error: error
  };
}

export function postAdHocMtrClear() {
  return {
    type: POST_ADHOCMTR_CLEAR
  };
}

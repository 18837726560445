import { SEARCH_QDOCS_PENDING, SEARCH_QDOCS_SUCCESS, SEARCH_QDOCS_ERROR } from './actions';

const initialState = {
  pending: false,
  data: null,
  error: null
};

export default function qdocsReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_QDOCS_PENDING:
      return {
        ...state,
        pending: true
      };
    case SEARCH_QDOCS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
        error: null
      };
    case SEARCH_QDOCS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: null
      };
    default:
      return state;
  }
}

export const getQDocs = state => state.data;
export const getQDocsPending = state => state.pending;
export const getQDocsError = state => state.error;

export const FETCH_CERTIFICATETYPES_PENDING = 'FETCH_CERTIFICATETYPES_PENDING';
export const FETCH_CERTIFICATETYPES_SUCCESS = 'FETCH_CERTIFICATETYPES_SUCCESS';
export const FETCH_CERTIFICATETYPES_ERROR = 'FETCH_CERTIFICATETYPES_ERROR';

export function fetchCertificateTypesPending() {
  return {
    type: FETCH_CERTIFICATETYPES_PENDING
  };
}

export function fetchCertificateTypesSuccess(certificateTypes) {
  return {
    type: FETCH_CERTIFICATETYPES_SUCCESS,
    certificateTypes: certificateTypes
  };
}

export function fetchCertificateTypesError(error) {
  return {
    type: FETCH_CERTIFICATETYPES_ERROR,
    error: error
  };
}

import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Unauthorized from '../Layout/Unauthoried.jsx';
import Download from '../Download/Download.jsx';
import Spinner from '../Layout/Spinner';

import fetchQDocAsync from '../../store/qdoc/fetchQDoc';
import { useAuth } from 'auth/useAuth.js';

export function QDocDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const qdoc = useSelector(state => state.qdoc);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    dispatch(fetchQDocAsync(id));
  }, [dispatch, id]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (qdoc.pending) {
    return <Spinner />;
  }
  if (!qdoc.data) {
    return null;
  }
  if (!qdoc.data.id) {
    return (
      <Fragment>
        <br />
        <div>
          <h5>No QDoc was found with the provided id</h5>
          Please contact support if this problem reoccurs
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <br />
      <h4>Q-doc details</h4>
      <br />
      <div className='well'>
        <div className='row'>
          <div className='col-sm-5'>
            <b>Serial Number: </b>
            {qdoc.data.serialNumber}
          </div>
          <div className='col-sm-5'>
            <b>Item Numbers: </b>
            {qdoc.data.itemNumbers.join(', ')}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5'>
            <b>Product Group: </b>
            {qdoc.data.product.productGroup.description}
          </div>
          <div className='col-sm-5'>
            <b>Product Type: </b>
            {qdoc.data.product.productType.description}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5'>
            <b>Supplier: </b>
            {qdoc.data.supplier.description}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5'>
            <b>Created: </b>
            {new Date(qdoc.data.created).toLocaleString('sv-SE')}
          </div>
          <div className='col-sm-5'>
            <b>Created by: </b>
            {qdoc.data.createdBy}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5'>
            <b>Id: </b>
            {qdoc.data.id}
          </div>
          <div className='col-sm-5'>
            <b>Revision: </b>
            {qdoc.data.revision}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5'>
            <b>Status: </b>
            {qdoc.data.invalid ? 'Invalid' : 'Valid'}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-5'>
            <b>Q-doc file: </b>{' '}
            <Download
              fileName={qdoc.data.fileName}
              identifier={qdoc.data.id}
              documentType='qdoc'
              alt={'Download Q-doc file'}
            />
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='col-sm-10 text-break'>
            <b>Comment: </b> {qdoc.data.comment}
          </div>
        </div>
      </div>
      <br />
      <Link to={'/edit-qdoc/' + qdoc.data.id} className='btn btn-primary'>
        Edit
      </Link>
    </div>
  );
}

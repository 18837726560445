export const POST_EDITHEAT_PENDING = 'POST_EDITHEAT_PENDING';
export const POST_EDITHEAT_SUCCESS = 'POST_EDITHEAT_SUCCESS';
export const POST_EDITHEAT_ERROR = 'POST_EDITHEAT_ERROR';
export const POST_EDITHEAT_CLEAR = 'POST_EDITHEAT_CLEAR';

export function postEditHeatPending() {
  return {
    type: POST_EDITHEAT_PENDING
  };
}

export function postEditHeatSuccess(heat) {
  return {
    type: POST_EDITHEAT_SUCCESS,
    heat: heat
  };
}

export function postEditHeatError(error) {
  return {
    type: POST_EDITHEAT_ERROR,
    error: error
  };
}

export function postEditHeatClear() {
  return {
    type: POST_EDITHEAT_CLEAR
  };
}

import { FETCH_CERTIFICATES_PENDING, FETCH_CERTIFICATES_SUCCESS, FETCH_CERTIFICATES_ERROR } from './actions';

const initialState = {
  pending: false,
  data: null,
  error: null
};

export default function certificatesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CERTIFICATES_PENDING:
      return {
        ...state,
        pending: true,
        data: null,
        error: null
      };
    case FETCH_CERTIFICATES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
        error: null
      };
    case FETCH_CERTIFICATES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: null
      };
    default:
      return state;
  }
}

export const getCertificates = state => state.data;
export const getCertificatesPending = state => state.pending;
export const getCertificatesError = state => state.error;

import { fetchHeatPending, fetchHeatSuccess, fetchHeatError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchHeatAsync = createAsyncThunk('heat/fetchHeat', async (id, thunkAPI) => {
  thunkAPI.dispatch(fetchHeatPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/heat/' + id, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchHeatError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();

    thunkAPI.dispatch(fetchHeatSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchHeatError(error));
  }
});

export default fetchHeatAsync;

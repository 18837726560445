import { FETCH_SURFACES_PENDING, FETCH_SURFACES_SUCCESS, FETCH_SURFACES_ERROR } from './actions';

const initialState = {
  pending: false,
  data: [],
  error: null
};

export default function surfacesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SURFACES_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_SURFACES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.surfaces
      };
    case FETCH_SURFACES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getSurfaces = state => state.surfaces;
export const getSurfacesPending = state => state.pending;
export const getSurfacesError = state => state.error;

export const FETCH_MATERIALS_PENDING = 'FETCH_MATERIALS_PENDING';
export const FETCH_MATERIALS_SUCCESS = 'FETCH_MATERIALS_SUCCESS';
export const FETCH_MATERIALS_ERROR = 'FETCH_MATERIALS_ERROR';

export function fetchMaterialsPending() {
  return {
    type: FETCH_MATERIALS_PENDING
  };
}

export function fetchMaterialsSuccess(materials) {
  return {
    type: FETCH_MATERIALS_SUCCESS,
    materials: materials
  };
}

export function fetchMaterialsError(error) {
  return {
    type: FETCH_MATERIALS_ERROR,
    error: error
  };
}

import { SEARCH_HEATS_PENDING, SEARCH_HEATS_SUCCESS, SEARCH_HEATS_ERROR } from './actions';

const initialState = {
  pending: false,
  data: null,
  error: null
};

export default function heatsReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_HEATS_PENDING:
      return {
        ...state,
        pending: true
      };
    case SEARCH_HEATS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data,
        error: null
      };
    case SEARCH_HEATS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: null
      };
    default:
      return state;
  }
}

export const getHeats = state => state.data;
export const getHeatsPending = state => state.pending;
export const getHeatsError = state => state.error;

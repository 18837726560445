import {
  FETCH_BASICSUPPLIERCERTIFICATES_PENDING,
  FETCH_BASICSUPPLIERCERTIFICATES_SUCCESS,
  FETCH_BASICSUPPLIERCERTIFICATES_ERROR
} from './actions';

const initialState = {
  pending: false,
  data: [],
  error: null
};

export default function basicSupplierCertificatesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BASICSUPPLIERCERTIFICATES_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_BASICSUPPLIERCERTIFICATES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data
      };
    case FETCH_BASICSUPPLIERCERTIFICATES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getBasicSupplierCertificates = state => state.data;
export const getBasicSupplierCertificatesPending = state => state.pending;
export const getBasicSupplierCertificatesError = state => state.error;

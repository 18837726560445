import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropdown from '../Dropdown/Dropdown.jsx';
import TextInput from '../TextInput/TextInput.jsx';
import { Col, Row, Table } from 'react-bootstrap';
import { FormRow } from 'components/FormRow/index.jsx';

class Materials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMaterialId: undefined,
      selectedMaterial: undefined,
      standardRevision: undefined,
      materials: []
    };
  }

  componentDidMount() {
    this.setState({
      materials: this.props.selectedMaterials
    });
  }

  updateSelectedMaterialId(materialId) {
    this.setState({
      selectedMaterialId: materialId,
      selectedMaterialGradeId: undefined,
      standardRevision: undefined
    });
  }

  updateSelectedMaterialGradeId(materialGradeId) {
    if (!materialGradeId) {
      return;
    }
    this.setState({
      selectedMaterialGradeId: materialGradeId
    });
  }

  updateStandardRevision(revision) {
    this.setState({
      standardRevision: revision.trim()
    });
  }

  addMaterial() {
    const exists = this.state.materials.some(
      x =>
        x.materialStandardId === this.state.selectedMaterialId &&
        x.materialGradeId === this.state.selectedMaterialGradeId &&
        x.materialStandardRevision === this.state.standardRevision
    );
    if (exists) {
      return null;
    }

    const selectedMaterial = this.props.materials.data.filter(
      material => material.id === this.state.selectedMaterialId
    );

    const selectedGrade = selectedMaterial[0].grades.filter(grade => grade.id === this.state.selectedMaterialGradeId);

    const material = {
      materialStandardId: this.state.selectedMaterialId,
      materialStandard: selectedMaterial[0].standard,
      materialGradeId: this.state.selectedMaterialGradeId,
      materialGrade: selectedGrade[0].grade,
      materialStandardRevision: this.state.standardRevision
    };

    const materials = [...(this.state.materials ?? []), material];

    this.setState({ materials });
    this.props.updateMaterials(materials);
  }

  removeMaterial(combo) {
    let index = -1;
    const materials = this.state.materials;
    materials.some((x, i) => {
      if (
        x.materialStandardId === combo.materialStandardId &&
        x.materialGradeId === combo.materialGradeId &&
        x.materialStandardRevision === combo.materialStandardRevision
      ) {
        index = i;
        return true;
      }
      return false;
    });
    if (index < 0) {
      return null;
    }
    materials.splice(index, 1);

    this.setState({ materials: materials });
    this.props.updateMaterials(materials);
  }

  renderMaterials() {
    const materials = this.props.materials;

    return (
      <Dropdown
        data={materials}
        elementName='materials'
        entityDescription='Material standard'
        valueProperty='standard'
        update={this.updateSelectedMaterialId.bind(this)}
        value={this.state.selectedMaterialId}
        error={this.props.error}
        required={!this.state.materials.length}
        width='col-sm-3'
      />
    );
  }

  renderMaterialGrades() {
    let grades = [];
    if (this.state.selectedMaterialId) {
      const materials = this.props.materials.data.filter(material => material.id === this.state.selectedMaterialId);
      grades = materials[0].grades;
    }
    const data = { data: grades };
    return (
      <Dropdown
        data={data}
        elementName='materialGrades'
        entityDescription='Material grade'
        valueProperty='grade'
        update={this.updateSelectedMaterialGradeId.bind(this)}
        loading={this.props.loading}
        value={this.state.selectedMaterialGradeId}
        required={!this.state.materials.length}
        width='col-sm-3'
      />
    );
  }

  renderMaterialStandardRevision() {
    return (
      <TextInput
        elementId='materialStandardRevision'
        entityDescription='Standard revision'
        update={this.updateStandardRevision.bind(this)}
        required={false}
        value={this.state.standardRevision}
      />
    );
  }

  renderSelectedMaterials(combos) {
    return combos.map(combo => {
      return (
        <tr key={combo.materialStandardId + combo.materialGradeId + combo.materialStandardRevision}>
          <td>{combo.materialStandard}</td>
          <td>{combo.materialGrade}</td>
          <td>{combo.materialStandardRevision}</td>
          <td>
            <i className='fa fa-minus' onClick={() => this.removeMaterial(combo)}></i>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <>
        <FormRow className='align-items-end'>
          <Col md={3}>{this.renderMaterials()}</Col>
          <Col md={3}>{this.renderMaterialGrades()}</Col>
          <Col md={2}>{this.renderMaterialStandardRevision()}</Col>
          <Col md={1}>
            <button
              disabled={!(this.state.selectedMaterialId && this.state.selectedMaterialGradeId)}
              type='button'
              className='btn btn-secondary'
              onClick={this.addMaterial.bind(this)}
            >
              <i className='fa fa-plus'></i>
            </button>
          </Col>
        </FormRow>
        {this.state.materials.length > 0 && (
          <Row>
            <Col sm={8}>
              <Row>
                <h6>Selected materials</h6>
              </Row>
              <Table striped hover id='materialTable'>
                <thead>
                  <tr>
                    <th>Material Standard</th>
                    <th>MaterialGrade</th>
                    <th>Revision</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{this.renderSelectedMaterials(this.state.materials)}</tbody>
              </Table>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

export default connect(mapStateToProps)(Materials);

import WarningModal from 'components/Modals/WarningModal';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

export function DuplicateModal({ qDocs, toggleDuplicateModal, serialNumber, load }) {
  const filteredQDocs = useMemo(
    () => qDocs.filter(x => x.serialNumber.toUpperCase() === serialNumber.toUpperCase() && !x.invalid),
    [qDocs, serialNumber]
  );

  const body = useMemo(() => {
    if (!filteredQDocs.length) {
      return null;
    }

    return (
      <div>
        <div>There is already an existing Q-doc with the same serial number. Please change the serial number.</div>
        <Link to={'qdoc/' + filteredQDocs[0].id + '/details'} target='_blank' rel='noopener noreferrer'>
          {filteredQDocs[0].serialNumber}
        </Link>
      </div>
    );
  }, [filteredQDocs]);

  const onToggle = useCallback(() => toggleDuplicateModal(), [toggleDuplicateModal]);
  const onLoad = useCallback(() => load(filteredQDocs[0].id), [filteredQDocs, load]);

  if (filteredQDocs.length === 0) return null;

  return (
    <WarningModal
      show={true}
      toggle={onToggle}
      header='Warning. Duplicate Q-doc found'
      body={body}
      continue={onLoad}
      continueButton='Edit'
    />
  );
}

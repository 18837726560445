import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import fetchBasicHeatsAsync from '../../store/basicHeats/fetchBasicHeats';
import fetchDimensionRangesAsync from '../../store/dimensionRanges/fetchDimensionRanges';
import fetchMechanicalRangesAsync from '../../store/mechanicalRanges/fetchMechanicalRanges';
import fetchSupplierCertificateAsync from '../../store/supplierCertificate/fetchSupplierCertificate';
import { editSupplierCertificate as editSupplierCertificateAction } from 'store/editSupplierCertificate/actions';
import uploadFileAsync from '../../store/uploads/uploadFile';

import { FileTransfer } from '../FileTransfer/FileTransfer.jsx';
import MechanicalRangesInputs from '../MechanicalRanges/MechanicalRangesInputs.jsx';
import DimensionRangesInputs from '../DimensionRanges/DimensionRangesInputs.jsx';
import Dropdown from '../Dropdown/Dropdown.jsx';
import TextInput from '../TextInput/TextInput.jsx';
import SaveModal from '../Modals/SaveModal.jsx';
import Spinner from '../Layout/Spinner';
import WarningModal from '../Modals/WarningModal.jsx';
import PurchaseOrderNumbers from './PurchaseOrderNumbers.jsx';
import postSupplierCertificateAsync from '../../store/editSupplierCertificate/postSupplierCertificate';
import Unauthorized from '../Layout/Unauthoried.jsx';

import './EditSupplierCertificate.css';
import { useParams, useNavigate } from 'react-router';
import { useAuth } from 'auth/useAuth';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { FormRow } from 'components/FormRow';

const FORGING_WALL_THICKNESS_VALIDATOR_ID = '787d6856-f239-4e81-8ec7-847e6ad7e29a';

const initialState = {
  id: undefined,
  heatNumber: '',
  selectedHeatId: undefined,
  selectedHeat: undefined,
  selectedMaterial: undefined,
  selectedMaterialStandardId: undefined,
  selectedMaterialGradeId: undefined,
  selectedHeatAndMaterials: [],
  selectedPoNumbers: [],
  fileId: undefined,
  fileName: '',
  lotNumber: undefined,
  comment: undefined,
  mechanicalProperties: [],
  dimensionUnit: '',
  dimensionProperties: [],
  revision: 0,
  invalid: false,
  searchHeatsClicked: false,
  disabledMechanicalProperties: false,
  disabledDimensionProperties: false,
  showSaveModal: false,
  saveClicked: false,
  showHeatNotFoundModal: false,
  showHeatHasInvalidMaterialStandard: false
};

function loadHeatsAndMaterial(heats) {
  const result = [];
  heats.forEach(hm => {
    const heat = {
      id: hm.id,
      heatNumber: hm.heatNumber,
      supplier: hm.supplier,
      certificateTypeId: hm.certificateTypeId
    };
    const material = {
      materialStandardId: hm.selectedStandard.id,
      materialStandard: hm.selectedStandard.name,
      materialGradeId: hm.selectedStandard.materialGrade.id,
      materialGrade: hm.selectedStandard.materialGrade.name,
      materialStandardRevision: hm.selectedStandard.standardRevision
    };
    result.push({ heat: heat, material: material });
  });
  return result;
}

export function EditSupplierCertificate() {
  const form = useRef();

  const [state, setState] = useState(initialState);

  const { hasElevatedRole } = useAuth();

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const supplierCertificate = useSelector(state => state.supplierCertificate);
  const dimensionRanges = useSelector(state => state.dimensionRanges);
  const mechanicalRanges = useSelector(state => state.mechanicalRanges);
  const basicHeats = useSelector(state => state.basicHeats);
  const editSupplierCertificate = useSelector(state => state.editSupplierCertificate);
  const uploadFile = useSelector(state => state.uploadFile);

  const clearState = useCallback(() => {
    setState({
      ...initialState,
      selectedPoNumbers: [],
      selectedHeatAndMaterials: [],
      mechanicalProperties: [],
      dimensionProperties: []
    });
    dispatch(editSupplierCertificateAction(undefined));
  }, [dispatch]);

  const clearSupplierCertificate = useCallback(() => {
    clearState();
    navigate('/edit-supplier-certificate', { replace: true });
  }, [clearState, navigate]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, [clearState]);

  useEffect(() => {
    if (!id) clearSupplierCertificate();
  }, [id, clearSupplierCertificate]);

  useEffect(() => {
    if (id && id !== state.id) {
      dispatch(fetchSupplierCertificateAsync(id));
    }
  }, [id, dispatch, state.id]);

  useEffect(() => {
    if (!supplierCertificate.pending && supplierCertificate.data) {
      const heats = loadHeatsAndMaterial(supplierCertificate.data.heats);

      if (heats.length) {
        const certificateTypeId = heats[0].heat.certificateTypeId;
        dispatch(fetchMechanicalRangesAsync(certificateTypeId));
        dispatch(fetchDimensionRangesAsync(supplierCertificate.data.heats[0].selectedStandard.id));
      }

      const mechanicalProperties = supplierCertificate.data.mechanicalProperties;
      /*
       * Wall thickness has been removed as a dimension of forging standards. To allow existing supplier certificates with
       * forging standards to be saved, the wall thickness dimension has to be removed from the loaded data.
       */
      const dimensions = supplierCertificate.data.dimensions.filter(
        x => x.validatorId !== FORGING_WALL_THICKNESS_VALIDATOR_ID
      );
      let unit = '';
      if (dimensions.length) {
        unit = supplierCertificate.data.dimensions[0].unit;
      }
      const cert = supplierCertificate.data;

      setState({
        id: cert.id,
        lotNumber: cert.lotNumber,
        comment: cert.comment,
        selectedHeatAndMaterials: heats,
        dimensionProperties: dimensions,
        mechanicalProperties: mechanicalProperties,
        dimensionUnit: unit,
        selectedPoNumbers: cert.poNumbers,
        fileName: cert.fileName,
        revision: cert.revision,
        disabledMechanicalProperties: mechanicalProperties.length === 0,
        disabledDimensionProperties: dimensions.length === 0,
        invalid: cert.invalid
      });
    }
  }, [supplierCertificate, dispatch]);

  const toggleHeatHasInvalidMaterialStandardModal = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      showHeatHasInvalidMaterialStandard: !prevState.showHeatHasInvalidMaterialStandard
    }));
  }, []);

  const toggleNoHeatFoundModal = useCallback(() => {
    setState(prevState => ({ ...prevState, showHeatNotFoundModal: !prevState.showHeatNotFoundModal }));
  }, []);

  useEffect(() => {
    if (!dimensionRanges.pending && !dimensionRanges.data && state.selectedHeatAndMaterials?.length) {
      setState(prevState => ({
        ...prevState,
        showHeatHasInvalidMaterialStandard: !prevState.showHeatHasInvalidMaterialStandard
      }));
    }
  }, [dimensionRanges, state.selectedHeatAndMaterials]);

  const updateLotNumber = useCallback(lotNumber => {
    setState(prevState => ({ ...prevState, lotNumber }));
  }, []);

  const updateComment = useCallback(e => {
    const value = e.currentTarget.value;
    setState(prevState => ({ ...prevState, comment: value }));
  }, []);

  const updateHeatNumber = useCallback(e => {
    const value = e.currentTarget.value;
    setState(prevState => ({ ...prevState, heatNumber: value }));
  }, []);

  const updateInvalid = useCallback(() => {
    setState(prevState => ({ ...prevState, invalid: !prevState.invalid }));
  }, []);

  const searchHeats = useCallback(
    value => {
      setState(prevState => ({
        ...prevState,
        selectedHeatId: undefined,
        selectedHeat: undefined,
        selectedMaterial: undefined,
        selectedMaterialStandardId: undefined,
        selectedMaterialGradeId: undefined,
        searchHeatsClicked: true
      }));

      dispatch(fetchBasicHeatsAsync(value));
    },
    [dispatch]
  );

  const onKeyUp = useCallback(
    e => {
      if (e.key === 'Enter' && e.target.id === 'searchHeatNumber') {
        if (e.target.value && e.target.value.length) {
          searchHeats(e.target.value);
        }
      }
    },
    [searchHeats]
  );

  const searchHeatsByNumber = useCallback(() => {
    searchHeats(state.heatNumber);
  }, [searchHeats, state]);

  useEffect(() => {
    if (!basicHeats.pending && basicHeats?.heats?.length === 0 && state.searchHeatsClicked) {
      toggleNoHeatFoundModal();
    }
  }, [basicHeats, toggleNoHeatFoundModal, state.searchHeatsClicked]);

  const setFileData = useCallback((fileName, fileId) => {
    setState(prevState => ({ ...prevState, fileName: fileName, fileId: fileId }));
  }, []);

  const setSelectedMechanicalProperties = useCallback(mechanicalProperties => {
    setState(prevState => ({ ...prevState, mechanicalProperties: mechanicalProperties }));
  }, []);

  const setSelectedDimensionProperties = useCallback(dimensionProperties => {
    setState(prevState => ({ ...prevState, dimensionProperties: dimensionProperties }));
  }, []);

  const setSelectedDimensionUnit = useCallback(unit => {
    setState(prevState => ({ ...prevState, dimensionUnit: unit }));
  }, []);

  const addSelectedHeatAndMaterial = useCallback(async () => {
    const selected = {
      heat: state.selectedHeat,
      material: state.selectedMaterial
    };

    const exists = state.selectedHeatAndMaterials.some(x => {
      if (x.heat.id === state.selectedHeatId && x.material.materialGradeId === state.selectedMaterialGradeId) {
        return true;
      }
      return false;
    });

    const combos = state.selectedHeatAndMaterials;
    if (!exists) {
      combos.push(selected);
    }

    setState(prevState => ({ ...prevState, selectedHeatAndMaterials: combos }));

    if (!mechanicalRanges.data.length) {
      dispatch(fetchMechanicalRangesAsync(selected.heat.certificateType.id));
    }
    dispatch(fetchDimensionRangesAsync(selected.material.materialStandardId));
  }, [state, dispatch, mechanicalRanges]);

  const removeSelectedHeatAndMaterial = useCallback(
    combo => {
      const combos = state.selectedHeatAndMaterials;
      const index = combos.map(x => x.material.materialGradeId).indexOf(combo.material.materialGradeId);
      if (index < 0) {
        return;
      }
      combos.splice(index, 1);

      let dimensionProperties = state.dimensionProperties;
      let mechanicalProperties = state.mechanicalProperties;
      if (!combos.length) {
        dimensionProperties = [];
        mechanicalProperties = [];
      }

      setState(prevState => ({
        ...prevState,
        selectedHeatAndMaterials: combos,
        dimensionProperties: dimensionProperties,
        mechanicalProperties: mechanicalProperties
      }));
    },
    [state]
  );

  const renderSelectedHeatAndMaterials = useCallback(
    combos => {
      return combos.map(combo => {
        return (
          <tr key={combo.heat.id + ':' + combo.material.materialGradeId}>
            <td>
              {combo.heat.heatNumber}, {combo.heat.supplier.name}
            </td>
            <td>
              {combo.material.materialStandard}, {combo.material.materialGrade}
              {combo.material.materialStandardRevision && `, ${combo.material.materialStandardRevision}`}
            </td>
            <td>
              <i className='fa fa-minus' onClick={() => removeSelectedHeatAndMaterial(combo)}></i>
            </td>
          </tr>
        );
      });
    },
    [removeSelectedHeatAndMaterial]
  );

  const setSelectedPoNumbers = useCallback(poNumbers => {
    setState(prevState => ({ ...prevState, selectedPoNumbers: poNumbers }));
  }, []);

  const setSelectedHeat = useCallback(
    heatId => {
      const index = basicHeats.heats.map(x => x.id).indexOf(heatId);
      if (index < 0) {
        return;
      }
      setState(prevState => ({
        ...prevState,
        selectedHeatId: heatId,
        selectedHeat: basicHeats?.heats[index],
        selectedMaterial: undefined,
        selectedMaterialStandardId: undefined,
        selectedMaterialGradeId: undefined
      }));
    },
    [basicHeats]
  );

  const setSelectedMaterialStandard = useCallback(
    materialGradeId => {
      if (!state.selectedHeatId) {
        return;
      }
      const materials = state.selectedHeat.material.filter(x => x.materialGradeId === materialGradeId);
      if (!materials.length) {
        return;
      }

      setState(prevState => ({
        ...prevState,
        selectedMaterial: materials[0],
        selectedMaterialStandardId: materials[0].materialStandardId,
        selectedMaterialGradeId: materialGradeId
      }));
    },
    [state]
  );

  const disableDimensionProperties = useCallback(() => {
    setState(prevState => ({ ...prevState, disabledDimensionProperties: !prevState.disabledDimensionProperties }));
  }, []);

  const renderMaterialStandards = useCallback(() => {
    let materialStandards = [];
    if (state.selectedHeat) {
      materialStandards = state.selectedHeat.material;
    }

    return (
      <Dropdown
        data={materialStandards}
        elementName='materialStandards'
        entityDescription='Material standard'
        valueProperty='materialStandard,materialGrade,materialStandardRevision'
        optionId='materialGradeId'
        update={setSelectedMaterialStandard}
        value={state.selectedMaterialGradeId}
        required={!state.selectedHeatAndMaterials.length}
        disabled={basicHeats.pending}
      />
    );
  }, [state, basicHeats, setSelectedMaterialStandard]);

  const disableMechanicalProperties = useCallback(() => {
    setState(prevState => ({ ...prevState, disabledMechanicalProperties: !prevState.disabledMechanicalProperties }));
  }, []);

  const closeSaveModal = useCallback(() => {
    setState(prevState => ({ ...prevState, showSaveModal: false, saveClicked: false }));

    if (editSupplierCertificate?.data?.id) {
      navigate(`/edit-supplier-certificate/${editSupplierCertificate.data.id}`, { replace: true });
    }
  }, [id, editSupplierCertificate, navigate]);

  const beginSave = useCallback(() => {
    form.current.reportValidity();
    if (!form.current.checkValidity()) {
      return;
    }
    setState(prevState => ({ ...prevState, showSaveModal: !prevState.showSaveModal, saveClicked: false }));
  }, [form]);

  const saveSupplierCertificate = useCallback(() => {
    if (!form.current.checkValidity()) {
      return;
    }
    setState(prevState => ({ ...prevState, saveClicked: true }));

    const heats = state.selectedHeatAndMaterials.map(selected => {
      return {
        heatId: selected.heat.id,
        standardId: selected.material.materialStandardId,
        gradeId: selected.material.materialGradeId
      };
    });

    const mechanicalProperties = state.disabledMechanicalProperties ? [] : state.mechanicalProperties;
    const dimensionProperties = state.disabledDimensionProperties ? [] : state.dimensionProperties;
    const revision = state.revision + 1;
    const fileId = uploadFile.data;
    const supplierCertificate = {
      id: state.id,
      heats: heats,
      lotNumber: state.lotNumber,
      comment: state.comment,
      mechanicalProperties: mechanicalProperties,
      dimensions: dimensionProperties,
      fileId: fileId,
      fileName: state.fileName,
      poNumbers: state.selectedPoNumbers,
      revision: revision,
      invalid: state.invalid,
      skippedMechanicalProperties: state.disabledMechanicalProperties,
      skippedDimensions: state.disabledDimensionProperties
    };

    dispatch(postSupplierCertificateAsync(supplierCertificate));
  }, [dispatch, state, uploadFile]);

  useEffect(() => {
    if (!editSupplierCertificate.pending && editSupplierCertificate.data) {
      const id = editSupplierCertificate.data.id;
      const revision = state.revision + 1;
      setState(prevState => ({ ...prevState, id: id, revision: revision }));
    }
  }, [editSupplierCertificate]);

  const renderSaveModal = useCallback(() => {
    let body = '';
    if (!state.saveClicked || editSupplierCertificate.pending) {
      body = <div>Do you want to save the Supplier Certificate?</div>;
    } else if (editSupplierCertificate.data) {
      body = (
        <div>
          Successfully saved the{' '}
          <Link
            to={`/supplier-certificate/${editSupplierCertificate.data.id}/details`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Supplier Certificate {editSupplierCertificate.data.dimension}
          </Link>
        </div>
      );
    } else if (editSupplierCertificate.error) {
      const error = editSupplierCertificate.error;
      body = (
        <Fragment>
          <div>Unable to save the Supplier Certificate.</div>
          <div>Reason: {error.message}.</div>
          {error.innerErrors &&
            error.innerErrors.map(x => {
              return <div key={x.message}>{x.message}</div>;
            })}
        </Fragment>
      );
    }

    return (
      <SaveModal
        show={state.showSaveModal}
        toggle={closeSaveModal}
        save={saveSupplierCertificate}
        header='Save Supplier Certificate'
        body={body}
        pending={editSupplierCertificate.pending}
        data={editSupplierCertificate.data}
        createAnother={clearSupplierCertificate}
        large={editSupplierCertificate}
      />
    );
  }, [editSupplierCertificate, state, closeSaveModal, saveSupplierCertificate, clearSupplierCertificate]);

  const renderNoHeatFoundModal = useCallback(() => {
    if (!state.showHeatNotFoundModal) {
      return null;
    }
    const body = (
      <div>
        <div>{`No heat was found with id: ${state.heatNumber}. Please search for another heat.`}</div>
      </div>
    );
    const header = 'Heat not found';

    return <WarningModal show={true} toggle={toggleNoHeatFoundModal} header={header} body={body} />;
  }, [state, toggleNoHeatFoundModal]);

  const renderHeatHasInvalidMaterialStandard = useCallback(() => {
    if (!state.showHeatHasInvalidMaterialStandard) {
      return null;
    }
    const heatNumber = state.selectedHeatAndMaterials?.[0]?.heat?.heatNumber;
    const materialStandard = state.selectedHeatAndMaterials?.[0]?.material?.materialStandard;
    const body = (
      <div>
        <div>{`The heat with number: ${heatNumber} and material standard: ${materialStandard} is invalid. Please edit the heat, remove the invalid material standard and select valid material standard(s) before proceeding with creating / editing the supplier certificate.`}</div>
      </div>
    );
    const header = 'Material Standard invalid on Heat';

    return <WarningModal show={true} toggle={toggleHeatHasInvalidMaterialStandardModal} header={header} body={body} />;
  }, [state, toggleHeatHasInvalidMaterialStandardModal]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (supplierCertificate.pending) {
    return <Spinner />;
  }

  return (
    <Container className='pt-4'>
      <Form
        ref={form}
        onSubmit={e => {
          e.preventDefault();
        }}
        style={{ opacity: state.showSaveModal ? 0.5 : 1 }}
      >
        <Row>
          <h3>Supplier certificate</h3>
        </Row>
        <Row>
          <Col sm={6}>
            {state.id && (
              <h5>
                Id: {state.id}, Revision: {state.revision}
              </h5>
            )}
          </Col>
          <Col sm={3} className='d-flex justify-content-end'>
            <Form.Check
              type='checkbox'
              title='Once invalid is checked you cannot use the Supplier Certificate for new Certificates. You can however uncheck it again and save.'
              label={<h5>Invalid</h5>}
              checked={state.invalid}
              id='invalid'
              onChange={updateInvalid}
            />
          </Col>
        </Row>
        <Row>
          <h5>General data</h5>
        </Row>
        <FormRow className='align-items-end'>
          <Form.Group as={Col} sm={4}>
            <Form.Label htmlFor='searchHeatNumber'>
              Search heat by number {!state.selectedHeatAndMaterials.length && '*'}
            </Form.Label>
            <Form.Control
              type='text'
              className='form-control'
              id='searchHeatNumber'
              minLength='1'
              onKeyUp={onKeyUp}
              value={state.heatNumber ?? ''}
              onChange={updateHeatNumber}
              disabled={state.selectedHeatAndMaterials.length > 0}
              required={!state.selectedHeatAndMaterials.length}
            />
          </Form.Group>
          <Col sm={2}>
            <Button
              variant='secondary'
              onClick={searchHeatsByNumber}
              disabled={!state.heatNumber || state.selectedHeatAndMaterials.length > 0}
            >
              <i className={basicHeats.pending ? 'fas fa-circle-notch fa-spin' : 'fa fa-search'}></i>
            </Button>
          </Col>
        </FormRow>
        {state.searchHeatsClicked && basicHeats?.heats?.length > 0 && (
          <FormRow className='align-items-end'>
            <Col sm={4}>
              <Dropdown
                data={basicHeats.heats}
                elementName='basicHeats'
                entityDescription='Heat'
                valueProperty='heatNumber,supplier.name'
                update={setSelectedHeat}
                value={state.selectedHeatId}
                disabled={state.selectedHeatAndMaterials.length > 0 || basicHeats.pending}
                required={!state.selectedHeatAndMaterials.length}
              />
            </Col>
            <Col sm={4}>{renderMaterialStandards()}</Col>
            <Col sm={2}>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={addSelectedHeatAndMaterial}
                disabled={!state.selectedMaterialGradeId}
              >
                <i className='fa fa-plus'></i>
              </button>
            </Col>
          </FormRow>
        )}
        {state.selectedHeatAndMaterials.length > 0 && (
          <Row>
            <Col sm={8}>
              <label htmlFor='itemsTable'>Selected heats</label>
              <table className='table table-sm table-striped table-hover col-sm-8' id='itemsTable'>
                <thead>
                  <tr>
                    <th>Heat</th>
                    <th>Material</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{renderSelectedHeatAndMaterials(state.selectedHeatAndMaterials)}</tbody>
              </table>
            </Col>
          </Row>
        )}
        {state.selectedHeatAndMaterials.length > 0 && (
          <>
            <FormRow>
              <Col sm={4}>
                <TextInput
                  elementId='lotNumber'
                  entityDescription='Lot number'
                  update={updateLotNumber}
                  required={false}
                  value={state.lotNumber}
                />
              </Col>
            </FormRow>
            <PurchaseOrderNumbers update={setSelectedPoNumbers} numbers={state.selectedPoNumbers} />
            <FormRow>
              <Col sm={8}>
                <FileTransfer
                  uploadFileAsync={(type, file) => dispatch(uploadFileAsync(type, file))}
                  uploadFile={uploadFile}
                  setFileData={setFileData}
                  fileName={state.fileName}
                  entityId={state.id}
                  downloadDocumentType='supplier-certificate'
                  uploadDocumentType='SupplierCertificate'
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col sm={8}>
                <label htmlFor='comment'>Comment</label>
                <textarea
                  type='text'
                  className='form-control'
                  rows='3'
                  id='comment'
                  onChange={updateComment}
                  value={state.comment}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col sm={10}>
                <MechanicalRangesInputs
                  ranges={mechanicalRanges}
                  update={setSelectedMechanicalProperties}
                  values={state.mechanicalProperties}
                  disableComponent={disableMechanicalProperties}
                  isDisabled={state.disabledMechanicalProperties}
                  label='Mechanical data'
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col sm={10}>
                <DimensionRangesInputs
                  ranges={dimensionRanges}
                  updatDimensions={setSelectedDimensionProperties}
                  updateUnit={setSelectedDimensionUnit}
                  label='Dimension data'
                  unit={state.dimensionUnit}
                  values={state.dimensionProperties}
                  disableComponent={disableDimensionProperties}
                  isDisabled={state.disabledDimensionProperties}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col xs='auto'>
                <Button
                  variant='secondary'
                  title='Note that this button will reset the entered data...'
                  className='btn btn-secondary'
                  onClick={clearSupplierCertificate}
                >
                  Clear
                </Button>
              </Col>
              <Col xs='auto'>
                <Button onClick={beginSave} disabled={uploadFile?.pending || !state?.fileName}>
                  Save
                </Button>
              </Col>
            </FormRow>
          </>
        )}
      </Form>
      {renderSaveModal()}
      {renderNoHeatFoundModal()}
      {renderHeatHasInvalidMaterialStandard()}
    </Container>
  );
}

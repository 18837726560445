export const FETCH_SURFACES_PENDING = 'FETCH_SURFACES_PENDING';
export const FETCH_SURFACES_SUCCESS = 'FETCH_SURFACES_SUCCESS';
export const FETCH_SURFACES_ERROR = 'FETCH_SURFACES_ERROR';

export function fetchSurfacesPending() {
  return {
    type: FETCH_SURFACES_PENDING
  };
}

export function fetchSurfacesSuccess(surfaces) {
  return {
    type: FETCH_SURFACES_SUCCESS,
    surfaces: surfaces
  };
}

export function fetchSurfacesError(error) {
  return {
    type: FETCH_SURFACES_ERROR,
    error: error
  };
}

import { postManufacturerPending, postManufacturerSuccess, postManufacturerError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const postManufacturerAsync = createAsyncThunk('manufacturer/create', async (manufacturer, thunkAPI) => {
  thunkAPI.dispatch(postManufacturerPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/manufacturer/create', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json; v=2.0',
        Accept: 'application/json; v=2.0'
      },
      body: JSON.stringify(manufacturer)
    });
    const data = await res.json();
    if (!res.ok) {
      thunkAPI.dispatch(postManufacturerError(data));
      return;
    }
    thunkAPI.dispatch(postManufacturerSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(postManufacturerError(error));
  }
});

export default postManufacturerAsync;

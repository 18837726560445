export const POST_QDOC_PENDING = 'POST_QDOC_PENDING';
export const POST_QDOC_SUCCESS = 'POST_QDOC_SUCCESS';
export const POST_QDOC_ERROR = 'POST_QDOC_ERROR';
export const POST_QDOC_CLEAR = 'POST_QDOC_CLEAR';

export function postQDocPending() {
  return {
    type: POST_QDOC_PENDING
  };
}

export function postQDocSuccess(data) {
  return {
    type: POST_QDOC_SUCCESS,
    data: data
  };
}

export function postQDocError(error) {
  return {
    type: POST_QDOC_ERROR,
    error: error
  };
}

export function postQDocClear() {
  return {
    type: POST_QDOC_CLEAR
  };
}

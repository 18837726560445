import { UPLOAD_FILE_PENDING, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_ERROR, UPLOAD_FILE_CLEAR } from './actions';

const initialState = {
  pending: false,
  data: null,
  error: null
};

export default function uploadFileReducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE_PENDING:
      return {
        ...state,
        data: null,
        error: null,
        pending: true
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: undefined,
        data: action.data
      };
    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: null
      };
    case UPLOAD_FILE_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}

import React, { Component } from 'react';

class NavDropdown extends Component {
  toggleDropdown(e) {
    e.preventDefault();

    this.props.changeToggle(this.props.menuId);
  }

  render() {
    const classDropdownMenu = 'dropdown-menu' + (this.props.toggle ? ' show' : '');
    return (
      <li className='nav-item dropdown'>
        <a
          className='nav-link dropdown-toggle'
          href='/'
          id='navbarDropdown'
          role='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
          onClick={e => {
            this.toggleDropdown(e);
          }}
        >
          {this.props.name}
        </a>
        <div className={classDropdownMenu} aria-labelledby='navbarDropdown'>
          {this.props.children}
        </div>
      </li>
    );
  }
}

export default NavDropdown;

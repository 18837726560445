export const FETCH_DOCUMENTID_PENDING = 'FETCH_DOCUMENTID_PENDING';
export const FETCH_DOCUMENTID_SUCCESS = 'FETCH_DOCUMENTID_SUCCESS';
export const FETCH_DOCUMENTID_ERROR = 'FETCH_DOCUMENTID_ERROR';

export function fetchDocumentIdPending() {
  return {
    type: FETCH_DOCUMENTID_PENDING
  };
}

export function fetchDocumentIdSuccess(data) {
  return {
    type: FETCH_DOCUMENTID_SUCCESS,
    data: data
  };
}

export function fetchDocumentIdError(error) {
  return {
    type: FETCH_DOCUMENTID_ERROR,
    error: error
  };
}

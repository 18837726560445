import { FETCH_SUPPLIERS_PENDING, FETCH_SUPPLIERS_SUCCESS, FETCH_SUPPLIERS_ERROR } from './actions';

const initialState = {
  pending: false,
  data: [],
  error: null
};

export default function suppliersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPPLIERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_SUPPLIERS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data
      };
    case FETCH_SUPPLIERS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getSuppliers = state => state.suppliers;
export const getSuppliersPending = state => state.pending;
export const getSuppliersError = state => state.error;

import React, { useContext, createContext } from 'react';

export const EnviromentContext = createContext({ requestScopes: [] });

export const EnviromentProvider = ({ requestScope, children }) => {
  const requestScopes = requestScope ?? [];
  return <EnviromentContext.Provider value={{ requestScopes }}>{children}</EnviromentContext.Provider>;
};

export const useEnviroment = () => {
  const context = useContext(EnviromentContext);
  if (context === undefined) {
    throw new Error('useEnviroment must be used within a EnviromentProvider');
  }
  return { requestScopes: context.requestScopes };
};

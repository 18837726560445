import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Download from '../Download/Download.jsx';

import './SupplierCertificate.css';

export default class SupplierCertificate extends Component {
  renderMaterialStandards(heat) {
    return heat.selectedStandards.map(standard => {
      return (
        <div className='row' key={standard.materialStandardId + standard.materialGradeId}>
          <div className='col-sm-6'>
            <b>Material standard:</b> {standard.materialStandard}
          </div>
          <div className='col-sm-6'>
            <b>Material grade:</b> {standard.materialGrade}
          </div>
        </div>
      );
    });
  }

  renderProperties(range, nrOfItemsPerRow) {
    const output = [];
    let i;
    for (i = 0; i < range.length; i += nrOfItemsPerRow) {
      const props = range.slice(i, i + nrOfItemsPerRow);
      output.push(this.renderSetOfProperties(props, i));
    }

    return <Fragment>{output}</Fragment>;
  }

  renderSetOfProperties(props, i) {
    return (
      <div key={i + Date.now()} className='row'>
        {props.map(prop => this.renderProperty(prop), this)}
      </div>
    );
  }

  renderProperty(prop) {
    if (!prop) {
      return null;
    }

    const value = prop.optionId
      ? `${prop.optionDescription}, ${prop.value} ${prop.unit}`
      : `${prop.value} ${prop.unit}`;

    return (
      <div className='col-sm-4' key={prop.validatorId}>
        <b>{prop.description}: </b>
        {value}
      </div>
    );
  }

  renderHeats(heats) {
    return heats.map((heat, i) => {
      return (
        <Fragment key={i + heat.id}>
          <div className='row'>
            <div className='col-sm-3'>
              <b>Heat number:</b>
              <Link to={'/heat/' + heat.id + '/details'}> {heat.heatNumber}</Link>
            </div>
            <div className='col-sm-3'>
              <b>Supplier:</b> {heat.supplier.name}
            </div>
            <div className='col-sm-6'>
              <b>Standards:</b> {heat.selectedStandard.name} {heat.selectedStandard.materialGrade.name}
              {heat.selectedStandard.standardRevision && `, ${heat.selectedStandard.standardRevision}`}
            </div>
          </div>
          <br />
        </Fragment>
      );
    });
  }

  render() {
    if (!this.props.data) {
      return (
        <Fragment>
          <br />
          <div>
            <h5>No Supplier Certificate was found with the provided id</h5>
            Please contact support if this problem reoccurs
          </div>
        </Fragment>
      );
    }

    const data = this.props.data;

    return (
      <Fragment>
        <div>
          <br />
          <h4>Supplier Certificate details</h4>
          <br />
          <h5>General</h5>
          <div className='well'>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Lot number:</b> {data.lotNumber}
              </div>
              <div className='col-sm-6'>
                <b>Shape:</b> {data.shape}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Created:</b> {new Date(data.created).toLocaleDateString()}
              </div>
              <div className='col-sm-6'>
                <b>Created by:</b> {data.createdBy}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Id:</b> {data.id}
              </div>
              <div className='col-sm-6'>
                <b>Revision:</b> {data.revision}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Supplier Certificate file: </b>
                <Download
                  fileName={data.fileName}
                  identifier={data.id}
                  documentType={'supplier-certificate'}
                  alt={'Download Supplier Certificate file'}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <b>Status: </b>
                {data.invalid ? 'Invalid' : 'Valid'}
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 text-break'>
                <b>PO Numbers: </b>{' '}
                {data.poNumbers && data.poNumbers.length > 0 && <span>{data.poNumbers.join(', ')}</span>}
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-sm-12 text-break'>
                <b>Comment: </b> {data.comment}
              </div>
            </div>
          </div>
          <br />
          <h5>Dimensions</h5>
          <div className='well'>{this.renderProperties(data.dimensions, 3)}</div>
          <br />
          <h5>Mechanical data</h5>
          <div className='well'>{this.renderProperties(data.mechanicalProperties, 3)}</div>
          <br />
          <h5>Heats</h5>
          <div className='well'>{this.renderHeats(data.heats)}</div>
        </div>
        <br />
        <Link to={`/edit-supplier-certificate/${data.id}`} className='btn btn-primary'>
          Edit
        </Link>
      </Fragment>
    );
  }
}

import { fetchMaterialsPending, fetchMaterialsSuccess, fetchMaterialsError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchMaterialsAsync = createAsyncThunk('material/list', async (certificateId, thunkAPI) => {
  thunkAPI.dispatch(fetchMaterialsPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/material/' + certificateId, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchMaterialsError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchMaterialsSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchMaterialsError(error));
  }
});

export default fetchMaterialsAsync;

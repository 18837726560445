import React, { Component, Fragment } from 'react';
import TextInput from '../TextInput/TextInput.jsx';
import { Button, Col, FormLabel, Row, Table } from 'react-bootstrap';
import { FormRow } from 'components/FormRow';

export default class PurchaseOrderNumbers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poNumber: null
    };
  }

  updatePoNumber(number) {
    this.setState({
      poNumber: number
    });
  }

  addPoNumber() {
    if (!this.state.poNumber) {
      return;
    }
    const poNumbers = this.props.numbers;
    if (poNumbers.some(x => x === this.state.poNumber)) {
      return;
    }

    poNumbers.push(this.state.poNumber);
    this.props.update(poNumbers);
    this.setState({ poNumber: '' });
  }

  removeSelectedPo(po) {
    const poNumbers = this.props.numbers;
    const index = poNumbers.indexOf(po);
    if (index < 0) {
      return;
    }
    poNumbers.splice(index, 1);

    this.props.update(poNumbers);
  }

  renderSelectedPoNumbers(poNumbers) {
    return poNumbers.map(po => {
      return (
        <tr key={po}>
          <td>{po}</td>
          <td align='right'>
            <i className='fa fa-minus' onClick={() => this.removeSelectedPo(po)}></i>
          </td>
        </tr>
      );
    });
  }

  render() {
    const numbers = this.props.numbers;
    return (
      <>
        <FormRow className='align-items-end'>
          <Col sm={4}>
            <TextInput
              elementId='poNumber'
              entityDescription='PO number'
              update={this.updatePoNumber.bind(this)}
              required={false}
              value={this.state.poNumber}
            />
          </Col>
          <Col sm={2}>
            <Button variant='secondary' onClick={this.addPoNumber.bind(this)}>
              <i className='fa fa-plus'></i>
            </Button>
          </Col>
        </FormRow>
        {numbers && numbers.length > 0 && (
          <Row>
            <Col sm={4}>
              <FormLabel htmlFor='poTable'>Selected PO numbers</FormLabel>
              <Table striped id='poTable'>
                <thead>
                  <tr>
                    <th colSpan={2}>PO</th>
                  </tr>
                </thead>
                <tbody>{this.renderSelectedPoNumbers(this.props.numbers)}</tbody>
              </Table>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

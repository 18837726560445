export const FETCH_HEAT_PENDING = 'FETCH_HEAT_PENDING';
export const FETCH_HEAT_SUCCESS = 'FETCH_HEAT_SUCCESS';
export const FETCH_HEAT_ERROR = 'FETCH_HEAT_ERROR';

export function fetchHeatPending() {
  return {
    type: FETCH_HEAT_PENDING
  };
}

export function fetchHeatSuccess(heat) {
  return {
    type: FETCH_HEAT_SUCCESS,
    heat: heat
  };
}

export function fetchHeatError(error) {
  return {
    type: FETCH_HEAT_ERROR,
    error: error
  };
}

import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class WarningModal extends Component {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.continue = this.continue.bind(this);
  }

  toggleModal() {
    this.props.toggle();
  }

  continue() {
    this.props.continue();
    this.props.toggle();
  }

  render() {
    return (
      <Modal role='document' show={this.props.show} aria-hidden='true' onHide={this.toggleModal}>
        <Modal.Header closeButton>
          <h4 className='modal-title'>{this.props.header}</h4>
        </Modal.Header>
        <Modal.Body>
          <div>{this.props.body}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button type='button' variant='secondary' onClick={this.toggleModal}>
            Close
          </Button>
          {this.props.continueButton && (
            <Button variant='primary' type='button' onClick={this.continue}>
              {this.props.continueButton}&nbsp;
              {this.props.pending && (
                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WarningModal;

import { POST_ADHOCMTR_PENDING, POST_ADHOCMTR_SUCCESS, POST_ADHOCMTR_ERROR, POST_ADHOCMTR_CLEAR } from './actions';

const initialState = {
  pending: false,
  data: undefined,
  error: null
};

export default function createAdHocMtr(state = initialState, action) {
  switch (action.type) {
    case POST_ADHOCMTR_PENDING:
      return {
        ...state,
        pending: true
      };
    case POST_ADHOCMTR_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.certificate
      };
    case POST_ADHOCMTR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case POST_ADHOCMTR_CLEAR: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

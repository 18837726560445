export const FETCH_MECHANICALRANGES_PENDING = 'FETCH_MECHANICALRANGES_PENDING';
export const FETCH_MECHANICALRANGES_SUCCESS = 'FETCH_MECHANICALRANGES_SUCCESS';
export const FETCH_MECHANICALRANGES_ERROR = 'FETCH_MECHANICALRANGES_ERROR';

export function fetchMechanicalRangesPending() {
  return {
    type: FETCH_MECHANICALRANGES_PENDING
  };
}

export function fetchMechanicalRangesSuccess(ranges) {
  return {
    type: FETCH_MECHANICALRANGES_SUCCESS,
    ranges: ranges
  };
}

export function fetchMechanicalRangesError(error) {
  return {
    type: FETCH_MECHANICALRANGES_ERROR,
    error: error
  };
}

import React, { useCallback, useState } from 'react';
import TextInput from 'components/TextInput/TextInput';
import { Row, Col, Button } from 'react-bootstrap';

export function EditQDocSerial({ serialNumber, id, updateSerialNumber, searchQDocs }) {
  const onSerialNumberChange = useCallback(value => updateSerialNumber(value), [updateSerialNumber]);
  const [pending, setPending] = useState(false);

  const onSearch = useCallback(async () => {
    setPending(true);
    try {
      await searchQDocs();
    } catch (e) {
      console.error(e);
    } finally {
      setPending(false);
    }
  }, [searchQDocs]);

  return (
    <Row sm={12} className='align-items-end mb-4'>
      <Col sm={4}>
        <TextInput
          elementId='serialId'
          entityDescription='Serial number'
          update={onSerialNumberChange}
          required={true}
          value={serialNumber}
          disabled={id}
          pattern='.{3,}'
          title='3 characters minimum'
        />
      </Col>
      <Col>
        <Button
          type='button'
          className='btn btn-secondary'
          onClick={onSearch}
          disabled={serialNumber?.length < 3 || id}
        >
          Confirm <i className={pending ? 'fas fa-circle-notch fa-spin' : ''}></i>
        </Button>
      </Col>
    </Row>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchItemPending, fetchItemSuccess, fetchItemError } from './actions';
import { getTokenIfExists } from 'auth/utils';

const fetchItemAsync = createAsyncThunk('items/fetch-item', async (id, thunkAPI) => {
  thunkAPI.dispatch(fetchItemPending());

  try {
    const headers = { 'Content-Type': 'application/json' };
    const token = await getTokenIfExists(thunkAPI.extra.acquireTokenSilent);
    if (token) {
      headers['Authorization'] = 'Bearer ' + token.accessToken;
    }
    const res = await fetch('api/item/' + id, {
      headers: headers
    });
    if (!res.ok) {
      if (res.status === 404) {
        thunkAPI.dispatch(fetchItemError({ message: 'Not found' }));
      }
      thunkAPI.dispatch(fetchItemError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchItemSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchItemError(error));
  }
});

export default fetchItemAsync;

import {
  FETCH_ALFALAVALCERTIFICATE_PENDING,
  FETCH_ALFALAVALCERTIFICATE_SUCCESS,
  FETCH_ALFALAVALCERTIFICATE_ERROR
} from './actions';

const initialState = {
  pending: false,
  data: null,
  error: null
};

export default function alfalavalCertificateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALFALAVALCERTIFICATE_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_ALFALAVALCERTIFICATE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data
      };
    case FETCH_ALFALAVALCERTIFICATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getAlfaLavalCertificate = state => state.heat;
export const getAlfaLavalCertificatePending = state => state.pending;
export const getAlfaLavalCertificateError = state => state.error;

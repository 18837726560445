export const FETCH_STEELWORKS_PENDING = 'FETCH_STEELWORKS_PENDING';
export const FETCH_STEELWORKS_SUCCESS = 'FETCH_STEELWORKS_SUCCESS';
export const FETCH_STEELWORKS_ERROR = 'FETCH_STEELWORKS_ERROR';

export function fetchSteelworksPending() {
  return {
    type: FETCH_STEELWORKS_PENDING
  };
}

export function fetchSteelworksSuccess(steelworks) {
  return {
    type: FETCH_STEELWORKS_SUCCESS,
    steelworks: steelworks
  };
}

export function fetchSteelworksError(error) {
  return {
    type: FETCH_STEELWORKS_ERROR,
    error: error
  };
}

import { fetchCertificateTypesPending, fetchCertificateTypesSuccess, fetchCertificateTypesError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchCertificateTypesAsync = createAsyncThunk('certificate-type/list', async (_, thunkAPI) => {
  thunkAPI.dispatch(fetchCertificateTypesPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/certificate-type/list', {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchCertificateTypesError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchCertificateTypesSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchCertificateTypesError(error));
  }
});

export default fetchCertificateTypesAsync;

export const POST_EDITSUPPLIERCERTIFICATE_PENDING = 'POST_EDITSUPPLIERCERTIFICATE_PENDING';
export const POST_EDITSUPPLIERCERTIFICATE_SUCCESS = 'POST_EDITSUPPLIERCERTIFICATE_SUCCESS';
export const POST_EDITSUPPLIERCERTIFICATE_ERROR = 'POST_EDITSUPPLIERCERTIFICATE_ERROR';
export const EDITSUPPLIERCERTIFICATE = 'EDITSUPPLIERCERTIFICATE';

export function postEditSupplierCertificatePending() {
  return {
    type: POST_EDITSUPPLIERCERTIFICATE_PENDING
  };
}

export function postEditSupplierCertificateSuccess(supplierCertificate) {
  return {
    type: POST_EDITSUPPLIERCERTIFICATE_SUCCESS,
    supplierCertificate: supplierCertificate
  };
}

export function postEditSupplierCertificateError(error) {
  return {
    type: POST_EDITSUPPLIERCERTIFICATE_ERROR,
    error: error
  };
}

export function editSupplierCertificate(editSupplierCertificate) {
  return {
    type: EDITSUPPLIERCERTIFICATE,
    editSupplierCertificate: editSupplierCertificate
  };
}

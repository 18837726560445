import { fetchQDocPending, fetchQDocSuccess, fetchQDocError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchQDocAsync = createAsyncThunk('qdoc/fetch', async (id, thunkAPI) => {
  thunkAPI.dispatch(fetchQDocPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/qdoc/' + id, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchQDocError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchQDocSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchQDocError(error));
  }
});

export default fetchQDocAsync;

export const FETCH_ALFALAVALCERTIFICATE_PENDING = 'FETCH_ALFALAVALCERTIFICATE_PENDING';
export const FETCH_ALFALAVALCERTIFICATE_SUCCESS = 'FETCH_ALFALAVALCERTIFICATE_SUCCESS';
export const FETCH_ALFALAVALCERTIFICATE_ERROR = 'FETCH_ALFALAVALCERTIFICATE_ERROR';

export function fetchAlfaLavalCertificatePending() {
  return {
    type: FETCH_ALFALAVALCERTIFICATE_PENDING
  };
}

export function fetchAlfaLavalCertificateSuccess(data) {
  return {
    type: FETCH_ALFALAVALCERTIFICATE_SUCCESS,
    data: data
  };
}

export function fetchAlfaLavalCertificateError(error) {
  return {
    type: FETCH_ALFALAVALCERTIFICATE_ERROR,
    error: error
  };
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCertificatesPending, fetchCertificatesSuccess, fetchCertificatesError } from './actions';
import { getTokenIfExists } from 'auth/utils';

const searchCertificatesAsync = createAsyncThunk(
  'certificate/search',
  async (
    { keyword, heatNumber, itemNumber, lotNumber, poNumber, alfaLavalId, mtrId, pageOffset, pageCount },
    thunkAPI
  ) => {
    thunkAPI.dispatch(fetchCertificatesPending());
    if (!pageOffset) {
      pageOffset = 0;
    }
    const headers = { 'Content-Type': 'application/json' };
    const token = await getTokenIfExists(thunkAPI.extra.acquireTokenSilent);
    if (token) {
      headers['Authorization'] = 'Bearer ' + token.accessToken;
    }
    let res;
    try {
      if (keyword) {
        res = await fetch(`api/search/certificate?keyword=${keyword}&pageOffset=${pageOffset}&pageCount=${pageCount}`, {
          headers: headers
        });
      } else {
        res = await fetch(
          `api/search/certificate/advanced?heatNumber=${heatNumber}&itemNumber=${itemNumber}&lotNumber=${lotNumber}&poNumber=${poNumber}&alfaLavalId=${alfaLavalId}&mtrId=${mtrId}&pageOffset=${pageOffset}&pageCount=${pageCount}`,
          {
            headers: headers
          }
        );
      }
      if (!res.ok) {
        switch (res.status) {
          case 400:
            return thunkAPI.dispatch(
              fetchCertificatesError({ message: 'Please provide at least three characters when searching.' })
            );
          case 404:
            return thunkAPI.dispatch(
              fetchCertificatesError({
                message: 'No Alfa Laval- nor MTR certificates were found. Please refine your search.'
              })
            );
          default:
            return thunkAPI.dispatch(
              fetchCertificatesError({
                message: 'Unable to fetch certificates. If this error reoccurs please contact support.'
              })
            );
        }
      }
      const data = await res.json();
      thunkAPI.dispatch(fetchCertificatesSuccess(data));
    } catch (error) {
      thunkAPI.dispatch(
        fetchCertificatesError({
          message: 'Unable to fetch certificates. If this error reoccurs please contact support.'
        })
      );
    }
  }
);

export default searchCertificatesAsync;

import { FETCH_HEAT_PENDING, FETCH_HEAT_SUCCESS, FETCH_HEAT_ERROR } from './actions';

const initialState = {
  pending: false,
  heat: null,
  error: null
};

export default function basicHeatReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_HEAT_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_HEAT_SUCCESS:
      return {
        ...state,
        pending: false,
        heat: action.heat
      };
    case FETCH_HEAT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getHeat = state => state.heat;
export const getHeatPending = state => state.pending;
export const getHeatError = state => state.error;

export const FETCH_SUPPLIERCERTIFICATES_PENDING = 'FETCH_SUPPLIERCERTIFICATES_PENDING';
export const FETCH_SUPPLIERCERTIFICATES_SUCCESS = 'FETCH_SUPPLIERCERTIFICATES_SUCCESS';
export const FETCH_SUPPLIERCERTIFICATES_ERROR = 'FETCH_SUPPLIERCERTIFICATES_ERROR';

export function fetchSupplierCertificatesPending() {
  return {
    type: FETCH_SUPPLIERCERTIFICATES_PENDING
  };
}

export function fetchSupplierCertificatesSuccess(data) {
  return {
    type: FETCH_SUPPLIERCERTIFICATES_SUCCESS,
    data: data
  };
}

export function fetchSupplierCertificatesError(error) {
  return {
    type: FETCH_SUPPLIERCERTIFICATES_ERROR,
    error: error
  };
}

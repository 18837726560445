export const FETCH_QDOCS_PENDING = 'FETCH_QDOCS_PENDING';
export const FETCH_QDOCS_SUCCESS = 'FETCH_QDOCS_SUCCESS';
export const FETCH_QDOCS_ERROR = 'FETCH_QDOCS_ERROR';

export function fetchQDocsPending() {
  return {
    type: FETCH_QDOCS_PENDING
  };
}

export function fetchQDocsSuccess(data) {
  return {
    type: FETCH_QDOCS_SUCCESS,
    data: data
  };
}

export function fetchQDocsError(error) {
  return {
    type: FETCH_QDOCS_ERROR,
    error: error
  };
}

import { FETCH_CHEMICALRANGES_PENDING, FETCH_CHEMICALRANGES_SUCCESS, FETCH_CHEMICALRANGES_ERROR } from './actions';

const initialState = {
  pending: false,
  data: [],
  error: null
};

export default function chemicalRangesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHEMICALRANGES_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_CHEMICALRANGES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.chemicalRanges
      };
    case FETCH_CHEMICALRANGES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getChemicalRanges = state => state.materials;
export const getChemicalRangesPending = state => state.pending;
export const getChemicalRangesError = state => state.error;

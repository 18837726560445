import { fetchSteelworksPending, fetchSteelworksSuccess, fetchSteelworksError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchSteelworksAsync = createAsyncThunk('steelwork/list', async (steelwork, thunkAPI) => {
  thunkAPI.dispatch(fetchSteelworksPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/steelwork/list', {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json; v=2.0',
        Accept: 'application/json; v=2.0'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchSteelworksError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchSteelworksSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchSteelworksError(error));
  }
});

export default fetchSteelworksAsync;

import {
  FETCH_SUPPLIERCERTIFICATE_PENDING,
  FETCH_SUPPLIERCERTIFICATE_SUCCESS,
  FETCH_SUPPLIERCERTIFICATE_ERROR
} from './actions';

const initialState = {
  pending: false,
  data: null,
  error: null
};

export default function supplierCertificateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPPLIERCERTIFICATE_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_SUPPLIERCERTIFICATE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data
      };
    case FETCH_SUPPLIERCERTIFICATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getSupplierCertificate = state => state.data;
export const getSupplierCertificatePending = state => state.pending;
export const getSupplierCertificateError = state => state.error;

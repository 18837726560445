import { FETCH_HEATS_PENDING, FETCH_HEATS_SUCCESS, FETCH_HEATS_ERROR } from './actions';

const initialState = {
  pending: false,
  heats: null,
  error: null
};

export default function heatReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_HEATS_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_HEATS_SUCCESS:
      return {
        ...state,
        pending: false,
        heats: action.heats
      };
    case FETCH_HEATS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getHeats = state => state.heats;
export const getHeatsPending = state => state.pending;
export const getHeatsError = state => state.error;

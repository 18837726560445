export const FETCH_HEATS_PENDING = 'FETCH_HEATS_PENDING';
export const FETCH_HEATS_SUCCESS = 'FETCH_HEATS_SUCCESS';
export const FETCH_HEATS_ERROR = 'FETCH_HEATS_ERROR';

export function fetchHeatsPending() {
  return {
    type: FETCH_HEATS_PENDING
  };
}

export function fetchHeatsSuccess(heats) {
  return {
    type: FETCH_HEATS_SUCCESS,
    heats: heats
  };
}

export function fetchHeatsError(error) {
  return {
    type: FETCH_HEATS_ERROR,
    error: error
  };
}

import { FETCH_DIMENSIONRANGES_PENDING, FETCH_DIMENSIONRANGES_SUCCESS, FETCH_DIMENSIONRANGES_ERROR } from './actions';

const initialState = {
  pending: false,
  data: null,
  error: null
};

export default function dimensionRangesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DIMENSIONRANGES_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_DIMENSIONRANGES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.dimensionRanges,
        error: action.error
      };
    case FETCH_DIMENSIONRANGES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: action.dimensionRanges
      };
    default:
      return state;
  }
}

export const getDimensionRanges = state => state.dimensionRanges;
export const getDimensionRangesPending = state => state.pending;
export const getDimensionRangesError = state => state.error;

import React, { Component } from 'react';

export default class DimensionRangesDecimalInput extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    this.state = {
      value: this.props.value,
      classes: 'form-control'
    };
  }

  async componentDidMount() {
    this.setState({
      value: this.props.value
    });
    this.validate();
  }

  updateInput = e => {
    e.preventDefault();
    if (!this.validate()) {
      return;
    }
    // convert to float
    const value = +e.target.value;

    this.props.onChange(e.target.id, value, this.props.predefinedValue);
  };

  validate() {
    if (!this.inputRef.current || !this.inputRef.current.value) {
      return false;
    }
    if (!this.inputRef.current.checkValidity()) {
      this.setState({
        classes: 'form-control is-invalid'
      });
      return false;
    } else {
      this.setState({
        classes: 'form-control'
      });
      return true;
    }
  }

  render() {
    return (
      <div className='form-group col-sm-4'>
        <label htmlFor={this.props.property.id}>{this.props.property.description} *</label>
        <input
          type='number'
          step='any'
          className={this.state.classes}
          id={this.props.property.id}
          ref={this.inputRef}
          placeholder={this.props.property.description}
          onChange={this.updateInput.bind(this)}
          defaultValue={this.props.value}
          min='0'
          lang='sv'
          required={true}
        />
      </div>
    );
  }
}

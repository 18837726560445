import React, { Component } from 'react';
import DecimalInput from '../DecimalInput/DecimalInput.jsx';
import { Col, Form, Row } from 'react-bootstrap';
import { FormRow } from 'components/FormRow/index.jsx';

export default class ChemicalRangesInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chemicalValues: []
    };
  }

  componentDidMount() {
    this.setState({
      chemicalValues: this.props.chemicalValues
    });
  }

  shouldComponentUpdate(nextProps, _nextState) {
    if (this.props.isDisabled !== nextProps.isDisabled) {
      return true;
    }
    if (this.props.materials !== nextProps.materials) {
      return true;
    }
    if (this.props.chemicalRanges !== nextProps.chemicalRanges) {
      return true;
    }

    return false;
  }

  updateChemicalProperty(id, value) {
    let props = [];
    if (this.state.chemicalValues.length) {
      props = this.state.chemicalValues;
    }

    const index = props.map(e => e.element).indexOf(id);
    if (index > -1) {
      props[index].value = value;
    } else {
      props.push({ element: id, value: value });
    }
    this.setState({ chemicalValues: props });
    this.props.updateChemicalProperties(props);
  }

  updateDisableChemicalProperties() {
    this.props.disableComponent();
  }

  renderChemicalProperties(range, itemsPerRow, values) {
    const output = [];
    let i;
    for (i = 0; i < range.length; i += itemsPerRow) {
      const props = range.slice(i, i + itemsPerRow);
      output.push(this.renderSetOfChemicalProperties(props, i, values));
    }

    return <>{output}</>;
  }

  renderSetOfChemicalProperties(props, i, values) {
    return <Row key={i + Date.now()}>{props.map(prop => this.renderChemicalProperty(prop, values), this)}</Row>;
  }

  renderChemicalProperty(prop, values) {
    let value = null;
    const result = values.filter(x => x.element === prop.element);
    if (result.length) {
      value = result[0].value;
    }
    if (!prop) {
      return null;
    }
    return (
      <DecimalInput
        key={prop.element}
        property={prop}
        onChange={this.updateChemicalProperty.bind(this)}
        value={value}
      />
    );
  }

  render() {
    if (!this.props.chemicalRanges) {
      return null;
    }
    return (
      <>
        <FormRow className='align-items-center'>
          <Col xs='auto'>
            <h5 className='mb-0'>Chemical data</h5>
          </Col>
          <Col xs='auto'>
            <Form.Check
              checked={this.props.isDisabled}
              onChange={this.updateDisableChemicalProperties.bind(this)}
              id='disableChemicalProperties'
              label='Skip'
              title='Check Skip if you want to ignore Chemical data'
            />
          </Col>
        </FormRow>
        {!this.props.isDisabled &&
          this.renderChemicalProperties(this.props.chemicalRanges, 4, this.props.chemicalValues)}
      </>
    );
  }
}

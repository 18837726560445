import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';

export function ActionRow({ clear, toggleSaveModal, uploadFile, fileName }) {
  const onClear = useCallback(() => clear(), [clear]);
  const onToggleSaveModal = useCallback(() => toggleSaveModal(), [toggleSaveModal]);

  return (
    <Row className='mb-4'>
      <Col sm='auto'>
        <button
          type='button'
          title='Note that this button will reset the entered data...'
          className='btn btn-secondary'
          onClick={onClear}
        >
          Clear
        </button>
      </Col>
      <Col sm='auto'>
        <button
          type='button'
          className='btn btn-primary'
          onClick={onToggleSaveModal}
          disabled={uploadFile?.pending || fileName === null}
        >
          Save
        </button>
      </Col>
    </Row>
  );
}

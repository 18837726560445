import { postEditMtrPending, postEditMtrSuccess, postEditMtrError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const postMtrAsync = createAsyncThunk('mtr/create', async (certificate, thunkAPI) => {
  thunkAPI.dispatch(postEditMtrPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('/api/mtr/create', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(certificate)
    });
    const data = await res.json();
    if (!res.ok) {
      thunkAPI.dispatch(postEditMtrError(data));
      return;
    }
    thunkAPI.dispatch(postEditMtrSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(postEditMtrError(error));
  }
});

export default postMtrAsync;

import rootReducer from './reducers';
import { configureStore as ReduxConfigureStore } from '@reduxjs/toolkit';

export function configureStore(msalInstance, requestScopes) {
  const store = ReduxConfigureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            acquireTokenSilent: () => {
              return msalInstance.acquireTokenSilent({
                scopes: requestScopes,
                account: msalInstance.getActiveAccount() ?? undefined
              });
            }
          }
        }
      })
  });

  return store;
}

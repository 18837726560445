import React, { Component } from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

export default class TextInput extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    this.state = {
      classes: 'form-control col-sm-12'
    };
  }

  componentDidMount() {
    this.inputRef.current.value = this.props.value ? this.props.value : '';
  }

  componentDidUpdate() {
    if (!this.props.value !== this.inputRef.current.value) {
      this.inputRef.current.value = this.props.value ? this.props.value : '';
    }
  }

  update = e => {
    this.validate();
    this.props.update(e.target.value);
  };

  validate() {
    if (!this.inputRef.current) {
      return false;
    }
    if (!this.inputRef.current.checkValidity()) {
      this.setState({
        classes: 'form-control col-sm-12 is-invalid'
      });
      return false;
    } else {
      this.setState({
        classes: 'form-control col-sm-12'
      });
      return true;
    }
  }

  render() {
    return (
      <FormGroup>
        <FormLabel htmlFor={this.props.elementId}>
          {this.props.entityDescription} {this.props.required && '*'}
        </FormLabel>
        <FormControl
          type='text'
          ref={this.inputRef}
          className={this.state.classes}
          id={this.props.elementId}
          onChange={e => this.update(e)}
          required={this.props.required}
          disabled={this.props.disabled}
          pattern={this.props.pattern}
          title={this.props.title}
        ></FormControl>
      </FormGroup>
    );
  }
}

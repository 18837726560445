import { fetchProductsPending, fetchProductsSuccess, fetchProductsError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchProductsAsync = createAsyncThunk('product/list', async (certificateId, thunkAPI) => {
  thunkAPI.dispatch(fetchProductsPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/product/list', {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchProductsError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchProductsSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchProductsError(error));
  }
});

export default fetchProductsAsync;

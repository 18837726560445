import React, { useCallback } from 'react';
import { Col, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';

export function EditQDocComment({ comment, updateComment }) {
  const onCommentChange = useCallback(event => updateComment(event?.target?.value), [updateComment]);
  return (
    <Row className='mb-4'>
      <Col sm={8}>
        <FormGroup>
          <FormLabel htmlFor='comment'>Comment</FormLabel>
          <FormControl as='textarea' rows={3} id='comment' onChange={onCommentChange} value={comment} />
        </FormGroup>
      </Col>
    </Row>
  );
}

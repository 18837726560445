import { fetchSuppliersPending, fetchSuppliersSuccess, fetchSuppliersError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchSuppliersAsync = createAsyncThunk('supplier/list', async (documentType, thunkAPI) => {
  thunkAPI.dispatch(fetchSuppliersPending());

  try {
    documentType = documentType ? documentType : '';
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/supplier/' + documentType, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json; v=2.0',
        Accept: 'application/json; v=2.0'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchSuppliersError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchSuppliersSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchSuppliersError(error));
  }
});

export default fetchSuppliersAsync;

export const POST_EDITCERTIFICATE_PENDING = 'POST_EDITCERTIFICATE_PENDING';
export const POST_EDITCERTIFICATE_SUCCESS = 'POST_EDITCERTIFICATE_SUCCESS';
export const POST_EDITCERTIFICATE_ERROR = 'POST_EDITCERTIFICATE_ERROR';
export const EDITCERTIFICATE = 'EDITCERTIFICATE';

export function postEditCertificatePending() {
  return {
    type: POST_EDITCERTIFICATE_PENDING
  };
}

export function postEditCertificateSuccess(certificate) {
  return {
    type: POST_EDITCERTIFICATE_SUCCESS,
    certificate: certificate
  };
}

export function postEditCertificateError(error) {
  return {
    type: POST_EDITCERTIFICATE_ERROR,
    error: error
  };
}

export function editCertificate(certificate) {
  return {
    type: EDITCERTIFICATE,
    certificate: certificate
  };
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { searchQDocsPending, searchQDocsSuccess, searchQDocsError } from './actions';
import { getTokenIfExists } from 'auth/utils';

const searchQDocsAsync = createAsyncThunk(
  'qdocs/search',
  async ({ keyword, serialNumber, itemNumber, pageOffset, pageCount }, thunkAPI) => {
    thunkAPI.dispatch(searchQDocsPending());

    const headers = { 'Content-Type': 'application/json' };
    const token = await getTokenIfExists(thunkAPI.extra.acquireTokenSilent);

    if (token) {
      headers['Authorization'] = 'Bearer ' + token.accessToken;
    }
    let res;
    try {
      if (keyword) {
        res = await fetch(`api/search/qdoc?keyword=${keyword}&pageOffset=${pageOffset}&pageCount=${pageCount}`, {
          headers: headers
        });
      } else {
        res = await fetch(
          `api/search/qdoc/advanced?serialNumber=${serialNumber}&itemNumber=${itemNumber}&pageOffset=${pageOffset}&pageCount=${pageCount}`,
          {
            headers: headers
          }
        );
      }
      if (!res.ok) {
        switch (res.status) {
          case 400:
            return thunkAPI.dispatch(
              searchQDocsError({ message: 'Please provide at least three characters when searching.' })
            );
          case 404:
            return thunkAPI.dispatch(searchQDocsError({ message: 'No Q-docs were found. Please refine your search.' }));
          default:
            return thunkAPI.dispatch(
              searchQDocsError({
                message: 'Unable to fetch Q-docs. If this error reoccurs please contact support.'
              })
            );
        }
      }
      const data = await res.json();
      thunkAPI.dispatch(searchQDocsSuccess(data));
    } catch (error) {
      thunkAPI.dispatch(
        searchQDocsError({
          message: 'Unable to fetch Q-docs. If this error reoccurs please contact support.'
        })
      );
    }
  }
);

export default searchQDocsAsync;

import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion, Col, Row } from 'react-bootstrap';
import Download from '../Download/Download';
import { PaginatedResult } from './PaginatedResult.jsx';
import { calucalatePageCount, stopPropagation } from './utils';
import { PAGE_COUNT } from './useSearch';

import './SearchCertificatesResult.css';
import { useAuth } from 'auth/useAuth';

export function SearchSupplierCertificates({ shouldRender, search }) {
  const { pending, data, error } = useSelector(state => state.searchSupplierCertificates);
  const [pageCount, setPageCount] = useState(1);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    if (!pending) {
      setPageCount(calucalatePageCount(data?.totalCount, PAGE_COUNT));
    }
  }, [data, pending]);

  if (!shouldRender) {
    return null;
  }
  const totalCount = hasElevatedRole()
    ? data?.totalCount
    : data?.totalCount > data?.results.length && data?.totalCount > 15
    ? `${15}+`
    : data?.results.length;

  return (
    <Accordion className='mt-4'>
      <Accordion.Item eventKey='supplierCertSearch'>
        <Accordion.Header>
          <Row className='flex-grow-1'>
            <Col xs={4}>
              <h5>
                Supplier Certificates&nbsp;
                {pending ? <i className='fas fa-circle-notch fa-spin' /> : totalCount ? `(${totalCount})` : undefined}
              </h5>
            </Col>
            {error && (
              <Col xs={8}>
                <p className='color-error'>{error.message}</p>
              </Col>
            )}
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <Accordion>
            {data?.results?.map((certificate, index) => (
              <CertificateRow key={`${certificate.id}_${index}`} certificate={certificate} />
            ))}
          </Accordion>
          <br />
          {data && (
            <PaginatedResult totalCount={data.totalCount} pageCount={pageCount} search={search} pending={pending} />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function CertificateRow({ certificate }) {
  const supplierCertificate = certificate?.supplierCertificate;

  const { hasElevatedRole } = useAuth();

  return (
    supplierCertificate && (
      <Accordion.Item
        eventKey={supplierCertificate.id}
        className={supplierCertificate.invalid ? 'invalid-card' : undefined}
      >
        <Accordion.Header>
          <Col>
            <Row className='align-items-top'>
              <Col md={3}>
                <b>Heats</b>
                <ul className='list-unstyled mb-md-0'>
                  {certificate.heats.map((heat, index) => (
                    <li key={`supplier_${heat.id}_${index}`}>
                      <SearchResult type='heat' id={heat.id} description={`${heat.heatNumber} ${heat.supplier}`} />
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md={4}>
                <b>Supplier Certificate</b>
                <ul className='list-unstyled mb-md-0'>
                  <li>
                    <SearchResult
                      type='supplier-certificate'
                      id={supplierCertificate.id}
                      description={`${supplierCertificate.shape.trim()} ${supplierCertificate.dimension.trim() ||
                        'N/A'}`}
                    />
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <b>Download</b>
                <ul className='list-unstyled mb-md-0'>
                  <li>
                    <Download
                      fileName={supplierCertificate.id}
                      identifier={supplierCertificate.id}
                      documentType='supplier-certificate'
                      alt={'Download Supplier Certificate file'}
                      onClick={stopPropagation}
                    />
                    {supplierCertificate.invalid && <i className='fa fa-exclamation m-1 invalid-certificate' />}
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Accordion.Header>
        {hasElevatedRole() ? (
          <Accordion.Body>
            <div className='container'>
              <SupplierCertificate certificate={certificate} />
            </div>
          </Accordion.Body>
        ) : null}
      </Accordion.Item>
    )
  );
}

function SearchResult({ type, id, description, invalid }) {
  return (
    <Link
      className={invalid ? 'invalid-certificate' : undefined}
      key={id}
      to={`/${type}/${id}/details`}
      target='_blank'
      rel='noopener noreferrer'
      onClick={stopPropagation}
      title={invalid ? 'The certificate is invalid' : undefined}
    >
      {`${description}`} {invalid && <i className='fa fa-exclamation m-1' />}
    </Link>
  );
}

function SupplierCertificate({ certificate }) {
  const supplierCertificate = certificate.supplierCertificate;
  return (
    <Fragment key={supplierCertificate.id}>
      <div className='row'>
        <b>
          Supplier certificate:{' '}
          <SearchResult
            type='supplier-certificate'
            id={supplierCertificate.id}
            description={`${supplierCertificate.shape.trim()} ${supplierCertificate.dimension.trim() || 'N/A'}`}
            invalid={supplierCertificate.invalid}
          />
        </b>
      </div>
      <div className='row'>
        <b>Lot numbers:</b>
        <span className='ml-1 text-break'>{certificate.lotNumber}</span>
      </div>
      <div className='row'>
        <b>PO numbers:</b>
        <span className='ml-1 text-break'>{certificate.purchaseOrderNumbers.join(', ')}</span>
      </div>
      <br />
    </Fragment>
  );
}

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import fetchAlfaLavalCertificateAsync from '../../store/alfalavalCertificate/fetchAlfaLavalCertificate';
import Spinner from '../Layout/Spinner';
import Certificate from '../CertificateBase/Certificate.jsx';
import Unauthorized from '../Layout/Unauthoried.jsx';
import { useAuth } from 'auth/useAuth';

export function CertificateDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { hasElevatedRole } = useAuth();

  const alfalavalCertificate = useSelector(state => state.alfalavalCertificate);

  useEffect(() => {
    if (hasElevatedRole() && id) {
      dispatch(fetchAlfaLavalCertificateAsync(id));
    }
  }, [id, dispatch, hasElevatedRole]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (!alfalavalCertificate) {
    return null;
  }
  if (!alfalavalCertificate.data && alfalavalCertificate.pending) {
    return <Spinner />;
  }
  return <Certificate data={alfalavalCertificate.data} type='Alfa Laval Certificate' editRoute='edit-certificate' />;
}

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditCertificateBase from '../CertificateBase/EditCertificateBase.jsx';
import CertificateSource from '../CertificateSource/CertificateSource.jsx';
import postAlfaLavalCertificateAsync from '../../store/editAlfaLavalCertificate/postAlfaLavalCertificate';
import { editCertificate as editCertificateAction } from '../../store/editAlfaLavalCertificate/actions.js';
import Unauthorized from '../Layout/Unauthoried.jsx';
import { useNavigate, useLocation, useParams } from 'react-router';
import { useDocumentIds } from 'components/CertificateBase/useDocumentIds.js';
import fetchSurfacesAsync from 'store/surfaces/fetchSurfaces.js';
import { useApi } from 'api/useApi.js';
import { useAuth } from 'auth/useAuth.js';

const TYPE = 'AlfaLavalCertificate';
const DESCRIPTION = 'Certificate';

export function EditAlfaLavalCertificate() {
  const [certificateData, setCertificateData] = useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [pdfHtmlRendered, setPdfHtmlRendered] = useState(undefined);
  const [pendingSave, setPendingSave] = useState(undefined);
  const [pdfHtmlData, setPdfHtmlData] = useState(undefined);
  const [savedCertificate, setSavedCertificate] = useState(undefined);
  const [savedResponse, setSavedResponse] = useState(undefined);

  const { hasElevatedRole, isAsmeSupplier } = useAuth();

  const surfaces = useSelector(state => state.surfaces);
  const editCertificate = useSelector(state => state.editCertificate);

  const { getJson } = useApi();

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const clearState = useCallback(() => {
    setPendingSave(false);
    setPdfHtmlData(undefined);
    setPdfHtmlRendered(false);
    setSavedCertificate(undefined);
    setSavedResponse(undefined);
    dispatch(editCertificateAction(undefined));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, [clearState]);

  useEffect(() => {
    dispatch(fetchSurfacesAsync(TYPE));
  }, [dispatch]);

  const fetchBasicSupplierCertificatesAsync = useCallback(
    async heatId => {
      return await getJson(`supplier-certificate/basic-information/${encodeURIComponent(heatId)}`);
    },
    [getJson]
  );

  const { generateItems } = useDocumentIds(TYPE);

  const refresh = useCallback(() => {
    navigate(0);
  }, [navigate]);

  const resetUrl = useCallback(() => {
    navigate('/edit-certificate/', { replace: true });
  }, [navigate]);

  const loadCertificate = useCallback(
    async certId => {
      if (!certId) {
        return null;
      }
      return getJson(`alfa-laval-certificate/${certId}`);
    },
    [getJson]
  );

  useEffect(() => {
    if (editCertificate?.pending || (!editCertificate?.data && !editCertificate.error)) return;

    if (editCertificate?.data?.length) {
      const certId = editCertificate.data[0].certificateId;

      const itemNumber = editCertificate.data[0].itemNumber;
      const items = certificateData?.items?.filter(x => x.itemNumber === itemNumber);
      const item = items?.length ? items[0] : undefined;
      const documentId = editCertificate.data[0].documentId;
      setCertificateData(undefined);
      navigate(`/edit-certificate/${certId}`, { replace: true });

      setPendingSave(false);
      setPdfHtmlData(undefined);
      setPdfHtmlRendered(false);
      setSavedCertificate(editCertificate);

      setSavedResponse({ id: certId, item: item, documentId: documentId });
    } else {
      setPendingSave(false);
      setPdfHtmlData(undefined);
      setPdfHtmlRendered(false);
      setSavedCertificate(editCertificate);
    }
  }, [editCertificate, certificateData, navigate]);

  const saveCertificate = useCallback(async () => {
    setPendingSave(true);

    const data = { ...certificateData };

    data.items.forEach(item => {
      var element = document.getElementById(item.documentId);
      item.html = element.innerHTML;
    }, this);

    dispatch(postAlfaLavalCertificateAsync(data));
    setCertificateData(data);
  }, [certificateData, dispatch]);

  const onSetCertificateData = useCallback(data => {
    setCertificateData(data);
    return data;
  }, []);

  const onPdfHtmlRendered = useCallback(() => {
    setPdfHtmlRendered(prevState => !prevState);
  }, []);

  const startRenderPdfHtml = useCallback((certificateData, selectedCertificates, selectedStandard) => {
    setPdfHtmlData({
      certificateData: certificateData,
      selectedCertificates: selectedCertificates,
      selectedStandard: selectedStandard
    });
  }, []);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }

  return (
    <>
      <EditCertificateBase
        save={saveCertificate}
        savedCertificate={savedCertificate}
        savedResponse={savedResponse}
        pendingSave={pendingSave}
        generateItemsWithDocumentIds={generateItems}
        setCertificateData={onSetCertificateData}
        certificateData={certificateData}
        loadCertificate={loadCertificate}
        type={TYPE}
        description={DESCRIPTION}
        id={id}
        renderPdfHtml={startRenderPdfHtml}
        pdfHtmlRendered={onPdfHtmlRendered}
        resetUrl={resetUrl}
        location={location}
        navigate={navigate}
        surfaces={surfaces}
        fetchBasicSupplierCertificatesAsync={fetchBasicSupplierCertificatesAsync}
        clearState={clearState}
        isAsmeSupplier={isAsmeSupplier}
        refresh={refresh}
      />
      {pdfHtmlData && (
        <div style={{ display: 'none' }}>
          <CertificateSource
            data={pdfHtmlData.certificateData}
            supplierCertificates={pdfHtmlData.selectedCertificates}
            standard={pdfHtmlData.selectedStandard}
            updateComplete={onPdfHtmlRendered}
            type={TYPE}
          />
        </div>
      )}
    </>
  );
}

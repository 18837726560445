export const FETCH_CERTIFICATES_PENDING = 'FETCH_CERTIFICATES_PENDING';
export const FETCH_CERTIFICATES_SUCCESS = 'FETCH_CERTIFICATES_SUCCESS';
export const FETCH_CERTIFICATES_ERROR = 'FETCH_CERTIFICATES_ERROR';

export function fetchCertificatesPending() {
  return {
    type: FETCH_CERTIFICATES_PENDING
  };
}

export function fetchCertificatesSuccess(data) {
  return {
    type: FETCH_CERTIFICATES_SUCCESS,
    data: data
  };
}

export function fetchCertificatesError(error) {
  return {
    type: FETCH_CERTIFICATES_ERROR,
    error: error
  };
}

export const POST_EDITMTR_PENDING = 'POST_EDITMTR_PENDING';
export const POST_EDITMTR_SUCCESS = 'POST_EDITMTR_SUCCESS';
export const POST_EDITMTR_ERROR = 'POST_EDITMTR_ERROR';
export const POST_EDITMTR_CLEAR = 'POST_EDITMTR_CLEAR';

export function postEditMtrPending() {
  return {
    type: POST_EDITMTR_PENDING
  };
}

export function postEditMtrSuccess(certificate) {
  return {
    type: POST_EDITMTR_SUCCESS,
    certificate: certificate
  };
}

export function postEditMtrError(error) {
  return {
    type: POST_EDITMTR_ERROR,
    error: error
  };
}

export function postEditMtrClear() {
  return {
    type: POST_EDITMTR_CLEAR
  };
}

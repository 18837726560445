import React, { useState, useCallback } from 'react';
import TextInput from '../../TextInput/TextInput.jsx';
import { Row, Col, Table, Button } from 'react-bootstrap';

export function EditQDocItems({ addItemNumber, selectedItems, removeItemNumber }) {
  const [itemNumber, setItemNumber] = useState();
  const [pending, setPending] = useState(false);

  const onUpdate = useCallback(item => {
    setItemNumber(item);
  }, []);

  const onAddItemNumber = useCallback(async () => {
    try {
      setPending(true);
      await addItemNumber(itemNumber);

      setItemNumber(undefined);
    } catch (e) {
      console.error(e);
    } finally {
      setPending(false);
    }
  }, [addItemNumber, itemNumber]);

  return (
    <>
      <Row sm={12}>
        <h5>Items</h5>
      </Row>
      <Row sm={12} className='align-items-end mb-2'>
        <Col sm={4}>
          <TextInput
            elementId='serialId'
            entityDescription='Item number'
            update={onUpdate}
            value={itemNumber}
            required={selectedItems?.length === 0}
          />
        </Col>
        <Col>
          <Button variant='secondary' onClick={onAddItemNumber} disabled={!itemNumber}>
            <i className={pending ? 'fas fa-circle-notch fa-spin' : 'fa fa-plus'}></i>
          </Button>
        </Col>
      </Row>
      {selectedItems?.length > 0 && (
        <Row>
          <Col sm={4}>
            <Table className='table table-sm table-striped table-hover' id='itemsTable'>
              <tbody>
                {selectedItems.map(item => (
                  <tr key={item.itemNumber}>
                    <td className='p-1'>
                      {item.itemNumber}
                      <i
                        className={item.warning ? 'fa fa-exclamation-triangle' : null}
                        title='The item was not found in GIM. You can still use the item.'
                      />
                    </td>
                    <td className='py-1 px-2' align='right'>
                      <i className='fa fa-minus' onClick={() => removeItemNumber(item)}></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </>
  );
}

import React, { Component } from 'react';

export default class DecimalInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: 'form-control col-sm-12'
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.value
    });
  }

  update = e => {
    e.preventDefault();
    if (!this.validateEvent(e)) {
      return;
    }
    // convert to float
    const value = +e.target.value;

    this.props.onChange(e.target.id, value);
  };

  validateEvent(e) {
    if (!e.target || !e.target.value) {
      return false;
    }
    if (!e.target.checkValidity()) {
      this.setState({
        classes: 'form-control col-sm-12 is-invalid'
      });
      return false;
    } else {
      this.setState({
        classes: 'form-control col-sm-12'
      });
      return true;
    }
  }

  hasValidRange() {
    if (this.props.property.validationRange) {
      return !isNaN(this.props.property.validationRange.min) && !isNaN(this.props.property.validationRange.max);
    }
    return false;
  }

  labelText() {
    if (!this.hasValidRange()) {
      return (
        <span>
          <b>{this.props.property.element}</b>
        </span>
      );
    }
    return (
      <span>
        <b>{this.props.property.element}</b> min: {this.props.property.validationRange.min}, max:{' '}
        {this.props.property.validationRange.max}
      </span>
    );
  }

  render() {
    const validationRange = this.props.property.validationRange
      ? this.props.property.validationRange
      : { min: undefined, max: undefined };
    return (
      <div className='form-group col-sm-3'>
        <label htmlFor={this.props.property.element}>
          {this.labelText()} {this.hasValidRange() && '*'}
        </label>
        <input
          type='number'
          step='any'
          min={validationRange.min}
          max={validationRange.max}
          className={this.state.classes}
          id={this.props.property.element}
          onChange={this.update.bind(this)}
          defaultValue={this.props.value}
          required={this.hasValidRange()}
          disabled={!this.hasValidRange()}
          lang='sv'
        ></input>
      </div>
    );
  }
}

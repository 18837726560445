import { postEditCertificatePending, postEditCertificateSuccess, postEditCertificateError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const postAlfaLavalCertificateAsync = createAsyncThunk(
  'alfa-laval-certificate/create',
  async (certificate, thunkAPI) => {
    thunkAPI.dispatch(postEditCertificatePending());

    try {
      const token = await thunkAPI.extra.acquireTokenSilent();
      const res = await fetch('/api/alfa-laval-certificate/create', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token.accessToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(certificate)
      });
      const data = await res.json();
      if (!res.ok) {
        thunkAPI.dispatch(postEditCertificateError(data));
        return;
      }

      thunkAPI.dispatch(postEditCertificateSuccess(data));
    } catch (error) {
      thunkAPI.dispatch(postEditCertificateError(error));
    }
  }
);

export default postAlfaLavalCertificateAsync;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchSupplierCertificateAsync from '../../store/supplierCertificate/fetchSupplierCertificate';
import Spinner from '../Layout/Spinner';
import SupplierCertificate from './SupplierCertificate.jsx';
import Unauthorized from '../Layout/Unauthoried.jsx';
import { useParams } from 'react-router';
import { useAuth } from 'auth/useAuth';

export function SupplierCertificateDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const supplierCertificate = useSelector(state => state.supplierCertificate);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    if (hasElevatedRole() && id) {
      dispatch(fetchSupplierCertificateAsync(id));
    }
  }, [id, dispatch, hasElevatedRole]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (!supplierCertificate) {
    return null;
  }
  if (!supplierCertificate.data && supplierCertificate.pending) {
    return <Spinner />;
  }
  return <SupplierCertificate data={supplierCertificate.data} />;
}

/**
 * If the user is not logged in, acquireTokenSilent will throw an error.
 * So in the case were we want a token if the user is logged but be able to
 * handle the case where the user is not logged in, we can use this util method.
 * @param {*} acquireTokenSilent method to acquire token silently
 * @returns
 */
export async function getTokenIfExists(acquireTokenSilent) {
  try {
    return await acquireTokenSilent?.();
  } catch (error) {
    console.error(error);
  }
  return undefined;
}

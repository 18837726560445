import React, { Fragment, useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion, Row, Col } from 'react-bootstrap';
import Download from '../Download/Download';
import { PaginatedResult } from './PaginatedResult.jsx';
import { calucalatePageCount, stopPropagation } from './utils';
import { PAGE_COUNT } from './useSearch';

import './SearchCertificatesResult.css';
import { useApi } from 'api/useApi';
import { useAuth } from 'auth/useAuth';

export function SearchCertificates({ shouldRender, search }) {
  const { pending, data, error } = useSelector(state => state.searchCertificates);
  const [pageCount, setPageCount] = useState(1);

  const { hasElevatedRole } = useAuth();

  useEffect(() => {
    if (!pending) {
      setPageCount(calucalatePageCount(data?.totalCount, PAGE_COUNT));
    }
  }, [data, pending]);

  const totalCount = useMemo(
    () =>
      hasElevatedRole()
        ? data?.totalCount
        : data?.totalCount > data?.results.length && data?.totalCount > 15
        ? `${15}+`
        : data?.results.length,
    [data, hasElevatedRole]
  );

  if (!shouldRender) return null;

  return (
    <Accordion className='mt-4'>
      <Accordion.Item eventKey='certificateSearch'>
        <Accordion.Header>
          <Row className='flex-grow-1'>
            <Col xs={4}>
              <h5 className='mb-0'>
                Certificates&nbsp;
                {pending ? <i className='fas fa-circle-notch fa-spin' /> : totalCount ? `(${totalCount})` : undefined}
              </h5>
            </Col>
            <Col className='flex-shrink-0' xs={8}>
              {error && <p className='color-error mb-0'>{error.message}</p>}
            </Col>
          </Row>
        </Accordion.Header>
        <Accordion.Body>
          <Accordion>
            {data?.results?.map((certificate, i) => (
              <CertificateRow key={i} certificate={certificate} />
            ))}
          </Accordion>
          <br />
          <PaginatedResult totalCount={data?.totalCount} pageCount={pageCount} search={search} pending={pending} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function CertificateRow({ certificate }) {
  const [supplierCertificates, setSupplierCertificates] = useState([]);
  const [isLoadingSupplierCertificates, setIsLoadingSupplierCertificates] = useState(false);
  const { getJson } = useApi();

  const { hasElevatedRole } = useAuth();

  const invalidCertificate = certificate.alfaLavalCertificate && certificate.alfaLavalCertificate.invalid;
  const invalidMtr = certificate.mtr && certificate.mtr.invalid;

  const loadSupplierCertificate = useCallback(async () => {
    setIsLoadingSupplierCertificates(true);
    const ids = certificate.supplierCertificates
      .map(x => x.id)
      .filter(id => !supplierCertificates.some(x => x.id === id));

    Promise.all(
      ids.map(id => {
        return getJson(`supplier-certificate/${id}`);
      })
    )
      .then(supplierCertificates => {
        setSupplierCertificates(prevState => [...prevState, ...supplierCertificates]);
      })
      .finally(() => setIsLoadingSupplierCertificates(false));
  }, [supplierCertificates, certificate, getJson]);

  return (
    <Accordion.Item
      eventKey={certificate?.alfaLavalCertificate ?? certificate?.mtr ?? 0}
      className={invalidCertificate || invalidMtr ? 'invalid-card' : undefined}
    >
      <Accordion.Header onClick={loadSupplierCertificate}>
        <Col>
          <Row className='align-items-top'>
            <Col md={3}>
              <b>Heats</b>
              <ul className='list-unstyled mb-md-0'>
                {certificate?.heats?.map((heat, index) => (
                  <li key={`certificate_${heat.id}_${index}`}>
                    <SearchResult type='heat' id={heat.id} description={`${heat.heatNumber} ${heat.supplier}`} />
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={3}>
              <b>Item number</b>
              <ul className='list-unstyled mb-md-0'>
                <li> {certificate?.item && certificate?.item?.itemNumber}</li>
              </ul>
            </Col>
            <Col md={3}>
              <b>Dimensions</b>
              <ul className='list-unstyled mb-md-0'>
                {certificate?.supplierCertificates?.map(cert => (
                  <SearchResult
                    key={`supplier-certificate_${cert.id}`}
                    type='supplier-certificate'
                    id={cert.id}
                    description={`${cert.shape.trim()} ${cert.dimension.trim() ||
                      cert.dimensionDescription.trim() ||
                      'N/A'}`}
                  />
                ))}
              </ul>
            </Col>
            <Col md={2}>
              {certificate?.alfaLavalCertificate ? (
                <Fragment>
                  <b>Certificate</b>
                  <ul className='list-unstyled mb-md-0'>
                    <li>
                      <Download
                        fileName={certificate.alfaLavalCertificate.fileName}
                        identifier={certificate.alfaLavalCertificate.id}
                        documentType={'alfa-laval-certificate'}
                        alt={''}
                        onClick={stopPropagation}
                      />
                      <SearchResult
                        type='certificate'
                        id={certificate.alfaLavalCertificate.id}
                        description={certificate.alfaLavalCertificate.documentId}
                        invalid={certificate.alfaLavalCertificate.invalid}
                      />
                    </li>
                  </ul>
                </Fragment>
              ) : null}
              {certificate?.mtr ? (
                <Fragment>
                  <b>MTR</b>
                  <ul className='list-unstyled mb-md-0'>
                    <li>
                      <Download
                        fileName={certificate.mtr.fileName}
                        identifier={certificate.mtr.id}
                        documentType={'mtr'}
                        alt={''}
                        onClick={stopPropagation}
                      />
                      <SearchResult
                        type='mtr'
                        id={certificate.mtr.id}
                        description={certificate.mtr.documentId}
                        invalid={certificate.mtr.invalid}
                      />
                    </li>
                  </ul>
                </Fragment>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Accordion.Header>
      {hasElevatedRole() ? (
        <Accordion.Body>
          <SupplierCertificates
            certificate={certificate}
            supplierCertificates={supplierCertificates}
            isLoading={isLoadingSupplierCertificates}
          />
        </Accordion.Body>
      ) : null}
    </Accordion.Item>
  );
}

function SearchResult({ type, id, description, invalid }) {
  const { hasElevatedRole } = useAuth();

  if (hasElevatedRole()) {
    return (
      <Link
        className={invalid ? 'invalid-certificate' : undefined}
        key={id}
        to={`/${type}/${id}/details`}
        target='_blank'
        rel='noopener noreferrer'
        onClick={stopPropagation}
        title={invalid ? 'The certificate is invalid' : undefined}
      >
        {`${description}`} {invalid && <i className='fa fa-exclamation m-1' />}
      </Link>
    );
  }
  return <>{`${description}`}</>;
}

function SupplierCertificates({ certificate, supplierCertificates, isLoading }) {
  if (isLoading) {
    return (
      <div>
        <i className='fas fa-circle-notch fa-spin' />
      </div>
    );
  }

  return (
    <div className='container'>
      {certificate.supplierCertificates?.map(cert => {
        const supplierCert = supplierCertificates.find(x => x.id === cert.id);
        return (
          <Fragment key={cert.id}>
            <div className='row'>
              <b>
                Supplier certificate:{' '}
                <SearchResult
                  type={'supplier-certificate'}
                  id={cert.id}
                  description={`${cert.shape.trim()} ${cert.dimension.trim() ||
                    cert.dimensionDescription.trim() ||
                    'N/A'}`}
                  invalid={cert.invalid}
                />
              </b>
            </div>
            <div className='row'>
              <b>Lot numbers:</b>
              <span className='ml-1 text-break'>{supplierCert?.lotNumber}</span>
            </div>
            <div className='row'>
              <b>PO numbers:</b>
              <span className='ml-1 text-break'>{supplierCert?.poNumbers.join(', ')}</span>
            </div>
            <br />
          </Fragment>
        );
      })}
    </div>
  );
}

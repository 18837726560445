import { postAdHocMtrPending, postAdHocMtrSuccess, postAdHocMtrError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTokenIfExists } from 'auth/utils';

const postAdHocMtrAsync = createAsyncThunk('mtro/create-ad-hoc', async (certificate, thunkAPI) => {
  thunkAPI.dispatch(postAdHocMtrPending());

  try {
    const headers = { 'Content-Type': 'application/json' };
    const token = await getTokenIfExists(thunkAPI.extra.acquireTokenSilent);

    if (token) {
      headers['Authorization'] = 'Bearer ' + token.accessToken;
    }
    const res = await fetch('/api/mtr/create-ad-hoc', {
      method: 'POST',
      accept: 'application/pdf image/*',
      headers: headers,
      body: JSON.stringify(certificate)
    });

    if (!res.ok) {
      thunkAPI.dispatch(postAdHocMtrError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.blob();
    thunkAPI.dispatch(postAdHocMtrSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(postAdHocMtrError(error));
  }
});

export default postAdHocMtrAsync;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchSupplierCertificatePending,
  fetchSupplierCertificateSuccess,
  fetchSupplierCertificateError
} from './actions';

const fetchSupplierCertificateAsync = createAsyncThunk('suppliercertificate/details', async (id, thunkAPI) => {
  thunkAPI.dispatch(fetchSupplierCertificatePending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/supplier-certificate/' + id, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchSupplierCertificateError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchSupplierCertificateSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchSupplierCertificateError(error));
  }
});

export default fetchSupplierCertificateAsync;

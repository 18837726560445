import React, { useCallback, useMemo } from 'react';
import SaveModal from 'components/Modals/SaveModal';
import { Link } from 'react-router-dom';

export function SaveQDocModal({ saveClicked, showSaveModal, serialNumber, editQDoc, toggleSaveModal, save, clear }) {
  const onToggle = useCallback(() => toggleSaveModal(), [toggleSaveModal]);
  const onSave = useCallback(() => save(), [save]);
  const onClear = useCallback(() => clear(), [clear]);
  const body = useMemo(() => {
    if (!saveClicked || editQDoc?.pending) {
      return 'Do you want to save the Q-doc?';
    } else if (editQDoc?.error) {
      return (
        <>
          <div>Unable to save the Q-doc.</div>
          <div>Reason: {editQDoc.error?.message}.</div>
          {editQDoc.error?.innerErrors?.map(x => {
            return <div key={x.message}>{x.message}</div>;
          })}
        </>
      );
    } else if (editQDoc?.data) {
      return (
        <div>
          Successfully saved the Q-doc:{' '}
          <Link to={'/qdoc/' + editQDoc.data + '/details'} target='_blank' rel='noopener noreferrer'>
            {serialNumber}
          </Link>
        </div>
      );
    }
    return null;
  }, [editQDoc, saveClicked, serialNumber]);

  return (
    <SaveModal
      show={showSaveModal}
      toggle={onToggle}
      save={onSave}
      createAnother={onClear}
      header='Save Q-doc'
      body={body}
      pending={editQDoc.pending}
      data={editQDoc.data}
      large={editQDoc.error}
    />
  );
}

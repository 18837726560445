import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropdown from '../Dropdown/Dropdown.jsx';
import TextInput from '../TextInput/TextInput.jsx';
import SaveModal from '../Modals/SaveModal.jsx';
import WarningModal from '../Modals/WarningModal.jsx';
import Items from './Items.jsx';
import SupplierCertificates from './SupplierCertificates.jsx';
import FetchItemAsync from '../../store/items/fetchItem';
import Spinner from '../Layout/Spinner';

import './EditCertificate.css';
import { fetchItemClear } from 'store/items/actions.js';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { FormRow } from 'components/FormRow/index.jsx';

class EditCertificateBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      documentId: undefined,
      revision: 0,
      created: undefined,
      supplierCertificates: {},
      selectedShortNumber: '',
      selectedMarking: '',
      selectedStandardSpecificationId: undefined,
      selectedSurfaceId: undefined,
      selectedStandard: undefined,
      heatNumber: '',
      comment: '',
      selectedItems: [],
      selectedCertificates: [],
      surfaces: [],
      invalid: false,
      certified: false,

      showNoSupplierCertificatesFoundModal: false,
      showSaveModal: false,
      saveClicked: false,
      pending: false,
      showCertifiedCheckBox: false
    };
    this.baseState = { ...this.state };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.savedResponse && nextProps.savedResponse.documentId !== prevState.documentId) {
      return {
        id: nextProps.savedResponse.id,
        selectedItems: nextProps.savedResponse.item ? [nextProps.savedResponse.item] : [],
        documentId: nextProps.savedResponse.documentId
      };
    }
    return {};
  }

  clearState() {
    this.setState({
      ...this.baseState,
      selectedItems: [],
      selectedCertificates: [],
      surfaces: []
    });
    this.props.clearState();
  }

  clearCertificate() {
    this.clearState();
    this.props.resetUrl();
    this.props.setCertificateData(undefined);
  }

  async componentDidMount() {
    if (this.props.id) {
      this.setState({ pending: true });
      await this.loadCertificate(this.props.id);
    }
  }

  async loadCertificate(id) {
    const certificate = await this.props.loadCertificate(id);
    if (!certificate) {
      return;
    }
    const uniqueHeatIds = new Set();
    const supplierCertificates = [];
    certificate.supplierCertificates.forEach(cert => {
      cert.heats.forEach(heat => {
        uniqueHeatIds.add(heat.heatNumber);
      });
    });
    for (const heatId of uniqueHeatIds) {
      const basicSupplierCertificates = await this.props.fetchBasicSupplierCertificatesAsync(heatId);
      const ids = basicSupplierCertificates.map(x => x.id);
      ids.forEach((id, index) => {
        if (!supplierCertificates.some(x => x.id === id)) {
          const result = certificate.supplierCertificates.filter(x => x.id === id);
          if (result.length) {
            supplierCertificates.push(basicSupplierCertificates[index]);
          }
        }
      });
    }
    const documentId = certificate.documentId;
    const item = certificate.item;
    item.documentId = documentId;

    this.updateStandardSpecification(certificate.surfaceStandard.id);
    const canBeCertified = this.canBeCertified();

    this.setState({
      id: certificate.id,
      revision: certificate.revision,
      documentId: certificate.documentId,
      created: certificate.created,
      selectedItems: [item],
      selectedShortNumber: certificate.shortNumber,
      selectedMarking: certificate.marking,
      selectedStandardSpecificationId: certificate.surfaceStandard.id,
      selectedSurfaceId: certificate.surfaceStandard.surface.id,
      selectedCertificates: supplierCertificates,
      comment: certificate.comment,
      pending: false,
      invalid: certificate.invalid,
      certified: certificate.certified,
      showCertifiedCheckBox: canBeCertified
    });
  }

  async searchItem(itemNumber) {
    if (!itemNumber) {
      return;
    }

    await this.props.FetchItemAsync(encodeURIComponent(itemNumber));
  }

  async searchSupplierCertificateByHeatNumber(heatNumber) {
    if (!heatNumber) {
      return;
    }
    this.setState({
      supplierCertificates: {
        pending: true
      }
    });
    const res = await this.props.fetchBasicSupplierCertificatesAsync(heatNumber);
    this.setState({
      supplierCertificates: {
        data: res,
        pending: false
      }
    });
    if (!res.length) {
      this.toggleNoSupplierCertificatesFoundModal();
    }
  }

  updateComment(e) {
    if (!e.target) {
      return;
    }
    this.setState({
      comment: e.target.value
    });
  }

  setItems(items) {
    this.setState({
      selectedItems: items
    });

    this.props.fetchItemClear();
  }

  canBeCertified() {
    return this.props.isAsmeSupplier() && this.props.type === 'MTR';
  }

  setSelectedCertificates(certificates) {
    const showCertified = this.canBeCertified();
    this.setState({
      selectedCertificates: certificates,
      showCertifiedCheckBox: showCertified,
      certified: showCertified
    });
  }

  updateStandardSpecification(standardSpecificationId) {
    this.setState({
      selectedStandardSpecificationId: standardSpecificationId,
      selectedSurfaceId: undefined
    });

    const standards = this.props.surfaces.data.find(x => x.id === standardSpecificationId);
    if (!standards) {
      return;
    }
    const surfaces = standards.surfaces.map(x => {
      return {
        id: x.id,
        surfaceDescription: `${x.description}, ID: ${x.innerDiameter}, OD: ${x.outerDiameter}`,
        invalid: x.invalid
      };
    });

    this.setState({ surfaces: surfaces });
  }

  updateShortNumber(shortNumber) {
    this.setState({ selectedShortNumber: shortNumber });
  }

  updateMarking(marking) {
    this.setState({ selectedMarking: marking });
  }

  updateSurface(surfaceId) {
    this.setState({ selectedSurfaceId: surfaceId });
  }

  updateInvalid() {
    this.setState({ invalid: !this.state.invalid });
  }

  updateCertified() {
    this.setState({ certified: !this.state.certified });
  }

  formValid() {
    return this.form.checkValidity();
  }

  toggleSaveModal() {
    this.setState({
      showSaveModal: !this.state.showSaveModal,
      saveClicked: false
    });
    this.props.pdfHtmlRendered();

    if (this.props.savedCertificate?.data && !this.props.savedCertificate?.error) {
      this.props.refresh?.();
    }
  }

  toggleNoSupplierCertificatesFoundModal() {
    this.setState({
      showNoSupplierCertificatesFoundModal: !this.state.showNoSupplierCertificatesFoundModal
    });
  }

  renderNoSupplierCertificatesFoundModal() {
    if (!this.state.showNoSupplierCertificatesFoundModal) {
      return null;
    }
    const body = (
      <div>
        <div>No Supplier certificates were found with entered heat number. Please search again.</div>
      </div>
    );
    const header = 'No Supplier certificates not found';

    return (
      <WarningModal
        show={true}
        toggle={this.toggleNoSupplierCertificatesFoundModal.bind(this)}
        header={header}
        body={body}
      />
    );
  }

  renderSaveModal() {
    let body = '';
    if (!this.state.saveClicked || this.props.pendingSave) {
      body = <div>Do you want to save the Alfa Laval {this.props.description}(s)?</div>;
    } else if (
      this.props.savedCertificate?.data &&
      !this.props.savedCertificate?.pending &&
      !this.props.savedCertificate?.error
    ) {
      const items = this.props.savedCertificate.data.map(item => {
        return (
          <div key={item.certificateId}>
            <Link
              to={`/${this.props.description.toLowerCase()}/${item.certificateId}/details`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {'Item: '}
              {item.itemNumber}
              {', Id: '}
              {item.documentId}
            </Link>
          </div>
        );
      });
      body = (
        <Fragment>
          <div>Successfully saved the Alfa Laval {this.props.description}(s):</div>
          {items}
        </Fragment>
      );
    } else if (this.props.savedCertificate?.error) {
      const error = this.props.savedCertificate.error;
      body = (
        <Fragment>
          <div>Unable to save the Alfa Laval {this.props.description}(s).</div>
          <div>Reason: {error.message}.</div>
          {error.innerErrors.map(x => {
            return <div key={x.message}>{x.message}</div>;
          })}
        </Fragment>
      );
    }
    return (
      <SaveModal
        show={this.state.showSaveModal}
        toggle={this.toggleSaveModal.bind(this)}
        save={this.save.bind(this)}
        header={'Save Alfa Laval ' + this.props.description}
        body={body}
        pending={this.props.pendingSave}
        createAnother={this.clearCertificate.bind(this)}
        data={this.props.savedCertificate}
        large={this.props.savedCertificate && this.props.savedCertificate.error}
      />
    );
  }

  async save() {
    this.setState({
      saveClicked: true
    });

    this.props.save();
    const revision = this.state.revision + 1;

    this.setState({
      revision: revision
    });
  }

  async prepareSave() {
    if (await this.prepare()) {
      this.setState({
        showSaveModal: true
      });
    }
  }

  async prepare() {
    this.form.reportValidity();
    if (!this.formValid()) {
      return false;
    }
    let items = [];
    if (this.state.id && this.state.documentId) {
      // The case when a user edits an existing certificate, must not regenerate mtrId. The list of items can only be one.
      const item = this.state.selectedItems[0];
      item.documentId = this.state.documentId;
      items.push(item);
    } else {
      items = await this.props.generateItemsWithDocumentIds(this.state.selectedItems);
    }

    const surfaces = [...(this.props.surfaces.data ?? [])];

    const standard = surfaces?.find(x => x.id === this.state.selectedStandardSpecificationId);

    const standardWithSurface = {
      ...standard,
      surface: standard?.surfaces?.find(x => x.id === this.state.selectedSurfaceId)
    };

    const revision = this.state.revision + 1;
    const created = new Date();
    const certificateData = {
      id: this.state.id,
      revision: revision,
      created: created,
      items: items,
      standardSpecificationId: this.state.selectedStandardSpecificationId,
      surfaceId: this.state.selectedSurfaceId,
      shortNumber: this.state.selectedShortNumber,
      marking: this.state.selectedMarking,
      comment: this.state.comment,
      supplierCertificateIds: this.state.selectedCertificates.map(cert => cert.id),
      invalid: this.state.invalid,
      certified: this.state.certified
    };

    const updatedData = await this.props.setCertificateData(certificateData);

    this.props.renderPdfHtml(updatedData, this.state.selectedCertificates, standardWithSurface);

    this.setState({
      created: created
    });
    return true;
  }

  async createAdHocMtr() {
    if (await this.prepare()) {
      await this.props.save();
    }
  }

  render() {
    if (this.state.pending) {
      return <Spinner />;
    }
    return (
      <>
        <Form
          ref={form => (this.form = form)}
          onSubmit={e => {
            e.preventDefault();
          }}
          style={{ opacity: this.state.showSaveModal ? 0.5 : 1 }}
          className='pt-4'
        >
          <Row>
            <h3>Alfa Laval {this.props.description}</h3>
          </Row>
          {this.props.type !== 'ADHOCMTR' && (
            <FormRow>
              <Col sm={8}>
                {this.state.documentId && (
                  <h5>
                    Id: {this.state.documentId}, Revision: {this.state.revision}
                  </h5>
                )}
              </Col>
              <Col>
                <Form.Check
                  checked={this.state.invalid}
                  onChange={this.updateInvalid.bind(this)}
                  title='Once invalid is checked you cannot use or search for the Certificate. You can however uncheck it again and save.'
                  label={<h5>Invalid</h5>}
                />
              </Col>
            </FormRow>
          )}
          <Items
            currentItem={this.props.fetchItem}
            items={this.state.selectedItems ?? []}
            pending={this.props.fetchItem.pending}
            search={this.searchItem.bind(this)}
            setItems={this.setItems.bind(this)}
            editMode={this.props.type === 'ADHOCMTR' || this.state.id}
          />
          {this.state.selectedItems.length > 0 && (
            <SupplierCertificates
              certificates={this.state.supplierCertificates}
              search={this.searchSupplierCertificateByHeatNumber.bind(this)}
              setCertificates={this.setSelectedCertificates.bind(this)}
              selectedCertificates={this.state.selectedCertificates}
              pending={this.state.supplierCertificates.pending}
            />
          )}
          {this.state.selectedCertificates.length > 0 && (
            <>
              <Row>
                <h5>Specification</h5>
              </Row>
              <FormRow>
                <Col sm={4}>
                  <Dropdown
                    data={this.props.surfaces}
                    elementName='standardSpecifications'
                    entityDescription='Standard specification'
                    valueProperty='description'
                    update={this.updateStandardSpecification.bind(this)}
                    required={true}
                    value={this.state.selectedStandardSpecificationId}
                    error={this.props.surfaces.error}
                  />
                </Col>
                <Col sm={4}>
                  <Dropdown
                    data={this.state.surfaces}
                    elementName='surfaces'
                    entityDescription='Surface'
                    valueProperty='surfaceDescription'
                    update={this.updateSurface.bind(this)}
                    required={true}
                    value={this.state.selectedSurfaceId}
                  />
                </Col>
              </FormRow>
              {this.state.showCertifiedCheckBox && (
                <FormRow>
                  <Col>
                    <Form.Check
                      value=''
                      id='certifiedCheckBox'
                      checked={this.state.certified}
                      onChange={this.updateCertified.bind(this)}
                      label='Certified'
                    />
                  </Col>
                </FormRow>
              )}
              <Row>
                <h5>Markings</h5>
              </Row>
              <FormRow>
                <Col sm={4}>
                  <TextInput
                    entityDescription='Short number'
                    elementId='shortNumber'
                    update={this.updateShortNumber.bind(this)}
                    value={this.state.selectedShortNumber}
                  />
                </Col>
                <Col sm={4}>
                  <TextInput
                    entityDescription='Marking'
                    elementId='marking'
                    update={this.updateMarking.bind(this)}
                    value={this.state.selectedMarking}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Form.Group as={Col} sm={8}>
                  <Form.Label htmlFor='comment'>Comment</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows='3'
                    id='comment'
                    onChange={this.updateComment.bind(this)}
                    value={this.state.comment}
                  />
                </Form.Group>
              </FormRow>

              <FormRow>
                <Col xs='auto'>
                  <button
                    type='button'
                    title='Note that this button will reset the entered data...'
                    className='btn btn-secondary'
                    onClick={this.clearCertificate.bind(this)}
                  >
                    Clear
                  </button>
                </Col>
                <Col xs='auto'>
                  {this.props.type === 'ADHOCMTR' && (
                    <Button
                      type='button'
                      variant='primary'
                      onClick={this.createAdHocMtr.bind(this)}
                      disabled={this.props.pendingSave}
                    >
                      Create AD HOC MTR <i className={this.props.pendingSave ? 'fas fa-circle-notch fa-spin' : ''}></i>
                    </Button>
                  )}
                  {this.props.type !== 'ADHOCMTR' && (
                    <Button type='button' variant='primary' onClick={this.prepareSave.bind(this)}>
                      Save
                    </Button>
                  )}
                </Col>
              </FormRow>
            </>
          )}
        </Form>
        {this.renderNoSupplierCertificatesFoundModal()}
        {this.state.showSaveModal && this.props.pdfHtmlRendered && this.renderSaveModal()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  fetchItem: state.fetchItem
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      FetchItemAsync,
      fetchItemClear
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditCertificateBase);

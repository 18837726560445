import { fetchMtrPending, fetchMtrSuccess, fetchMtrError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchMtrAsync = createAsyncThunk('mtr/get', async (id, thunkAPI) => {
  thunkAPI.dispatch(fetchMtrPending());
  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/mtr/' + id, {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchMtrError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchMtrSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchMtrError(error));
  }
});

export default fetchMtrAsync;

import {
  fetchSupplierCertificatesPending,
  fetchSupplierCertificatesSuccess,
  fetchSupplierCertificatesError
} from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTokenIfExists } from 'auth/utils';

const searchSupplierCertificatesAsync = createAsyncThunk(
  'supplierCertificate/search',
  async ({ keyword, heatNumber, poNumber, lotNumber, pageOffset, pageCount }, thunkAPI) => {
    thunkAPI.dispatch(fetchSupplierCertificatesPending());

    if (!pageOffset) {
      pageOffset = 0;
    }
    try {
      const headers = { 'Content-Type': 'application/json' };

      const token = await getTokenIfExists(thunkAPI.extra.acquireTokenSilent);

      if (token) {
        headers['Authorization'] = 'Bearer ' + token.accessToken;
      }
      let res;
      if (keyword) {
        res = await fetch(
          `api/search/supplier-certificate?keyword=${keyword}&pageOffset=${pageOffset}&pageCount=${pageCount}`,
          {
            headers: headers
          }
        );
      } else {
        res = await fetch(
          `api/search/supplier-certificate/advanced?heatNumber=${heatNumber}&lotNumber=${lotNumber}&poNumber=${poNumber}&pageOffset=${pageOffset}&pageCount=${pageCount}`,
          {
            headers: headers
          }
        );
      }
      if (!res.ok) {
        switch (res.status) {
          case 400:
            return thunkAPI.dispatch(
              fetchSupplierCertificatesError({
                message: 'Please provide at least three characters when searching.'
              })
            );
          case 404:
            return thunkAPI.dispatch(
              fetchSupplierCertificatesError({
                message: 'No supplier certificates were found. Please refine your search.'
              })
            );
          default:
            return thunkAPI.dispatch(
              fetchSupplierCertificatesError({
                message: 'Unable to fetch supplier certificates. If this error reoccurs please contact support.'
              })
            );
        }
      }
      const data = await res.json();
      thunkAPI.dispatch(fetchSupplierCertificatesSuccess(data));
    } catch (error) {
      thunkAPI.dispatch(
        fetchSupplierCertificatesError({
          message: 'Unable to fetch supplier certificates. If this error reoccurs please contact support.'
        })
      );
    }
  }
);

export default searchSupplierCertificatesAsync;

import {
  postEditSupplierCertificatePending,
  postEditSupplierCertificateSuccess,
  postEditSupplierCertificateError
} from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const postSupplierCertificateAsync = createAsyncThunk(
  'supplier-certificate/create',
  async (supplierCertificate, thunkAPI) => {
    thunkAPI.dispatch(postEditSupplierCertificatePending());
    try {
      const token = await thunkAPI.extra.acquireTokenSilent();
      const res = await fetch('/api/supplier-certificate/create', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token.accessToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(supplierCertificate)
      });
      const data = await res.json();
      if (!res.ok) {
        thunkAPI.dispatch(postEditSupplierCertificateError(data));
        return;
      }
      thunkAPI.dispatch(postEditSupplierCertificateSuccess(data));
    } catch (error) {
      thunkAPI.dispatch(postEditSupplierCertificateError(error));
    }
  }
);

export default postSupplierCertificateAsync;

import { postAsmeCertificationPending, postAsmeCertificationSuccess, postAsmeCertificationError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const postAsmeCertificationAsync = createAsyncThunk('asme-certification', async (certification, thunkAPI) => {
  thunkAPI.dispatch(postAsmeCertificationPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/document/asme-certification', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json; v=1.0',
        Accept: 'application/json; v=1.0'
      },
      body: JSON.stringify(certification)
    });
    if (!res.ok) {
      const data = await res.json();
      thunkAPI.dispatch(postAsmeCertificationError(data));
      return;
    }
    thunkAPI.dispatch(postAsmeCertificationSuccess());
  } catch (error) {
    thunkAPI.dispatch(postAsmeCertificationError(error));
  }
});

export default postAsmeCertificationAsync;

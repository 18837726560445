import {
  POST_EDITCERTIFICATE_PENDING,
  POST_EDITCERTIFICATE_SUCCESS,
  POST_EDITCERTIFICATE_ERROR,
  EDITCERTIFICATE
} from './actions';

const initialState = {
  pending: false,
  data: undefined,
  error: null
};

export default function editCertificate(state = initialState, action) {
  switch (action.type) {
    case POST_EDITCERTIFICATE_PENDING:
      return {
        ...state,
        pending: true
      };
    case POST_EDITCERTIFICATE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.certificate,
        error: null
      };
    case POST_EDITCERTIFICATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        data: undefined
      };
    case EDITCERTIFICATE:
      return {
        ...state,
        pending: false,
        data: action.certificate
      };

    default:
      return state;
  }
}

import React, { Component } from 'react';
import { Accordion, Card, Button, Container } from 'react-bootstrap';

import './Admin.css';

export class WriteAsmeCertification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveClicked: false,
      showError: false,
      newItem: null
    };
  }

  async save(id) {
    if (!this.props.isAdmin()) {
      return;
    }

    const items = [...this.props.items];
    if (id) {
      const index = items.findIndex(x => x.id === id);
      if (index > -1) {
        items.splice(index, 1);
      }
    } else {
      items.push(this.state.newItem);
    }
    this.props.setItems(items);

    await this.props.save(items);

    this.setState({
      newItem: null
    });
  }

  updateCiSignature(e) {
    if (!e.target) {
      return;
    }
    const item = this.state.newItem;
    item.ciSignature = e.target.value;
    this.setState({
      newItem: item
    });
  }

  updateAuthorizedDate(e) {
    if (!e.target) {
      return;
    }
    const item = this.state.newItem;
    item.authorizedDate = new Date(e.target.value);
    this.setState({
      newItem: item
    });
  }

  updateExpirationDate(e) {
    if (!e.target) {
      return;
    }
    const item = this.state.newItem;
    item.expirationDate = new Date(e.target.value);
    this.setState({
      newItem: item
    });
  }

  updateExtensionDate(e) {
    if (!e.target) {
      return;
    }
    const item = this.state.newItem;
    item.extensionDate = new Date(e.target.value);
    this.setState({
      newItem: item
    });
  }

  updateValidFrom(e) {
    if (!e.target) {
      return;
    }
    const item = this.state.newItem;
    item.validFrom = new Date(e.target.value);
    this.setState({
      newItem: item
    });
  }

  renderSaveButton(id) {
    return (
      <button
        id={id}
        type='button'
        className='btn btn-primary'
        disabled={this.props.saveResult.pending}
        onClick={() => this.save(id)}
      >
        Save <i className={this.props.saveResult.pending ? 'fas fa-circle-notch fa-spin' : ''}></i>
      </button>
    );
  }

  renderItem(item) {
    if (!item) {
      return null;
    }
    return (
      <div className='col-sm-12'>
        <div className='row'>
          <div className='col-sm-12'>
            <b>CI signature:</b> {item.ciSignature}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Authorized date:</b> {new Date(item.authorizedDate).toLocaleDateString('sv-SE')}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Expiration date:</b> {new Date(item.expirationDate).toLocaleDateString('sv-SE')}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Extension date:</b> {item.extensionDate && new Date(item.extensionDate).toLocaleDateString('sv-SE')}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <button
              id={`${item.id}-delete`}
              type='button'
              className='btn btn-danger'
              disabled={this.state.saveClicked || this.props.saveResult.pending}
              onClick={() => this.save(item.id)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  }

  addNewItem() {
    const item = {
      id: null,
      ciSignature: '',
      authorizedDate: new Date(),
      expirationDate: this.getNewExpirationDate(),
      extensionDate: undefined,
      validFrom: new Date()
    };
    this.setState({ newItem: item });
  }

  getNewExpirationDate() {
    const today = new Date();
    return new Date(today.getFullYear() + 5, today.getMonth(), today.getDate());
  }

  renderErrorSaveResult() {
    if (this.props.saveResult?.error) {
      return this.renderErrorAlert(
        'Unable to save item. If this error reoccurs please contact support.',
        'showError',
        'alert-danger'
      );
    }
    return null;
  }

  renderErrorAlert(message, stateProperty, className) {
    return (
      <div className='row justify-content-center'>
        <div className='col-lg-12 col-sm-12'>
          {this.renderAlert(
            message,
            () =>
              this.setState({
                [stateProperty]: false
              }),
            className
          )}
        </div>
      </div>
    );
  }

  renderAlert(message, onActionFunc, alertClass) {
    return (
      <div className={`alert ${alertClass} alert-not-found alert-dismissible fade show`} role='alert'>
        {message}
        <button type='button' className='close' data-dismiss='alert' aria-label='Close' onClick={() => onActionFunc()}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
    );
  }

  toggle(e, id) {
    if (this.state.newItem != null) {
      e.stopPropagation();
      this.setState({ selected: undefined });
      return;
    }
    const shouldCollapse = this.state.selected === id;

    this.setState({ selected: shouldCollapse ? '' : id });
  }

  render() {
    if (!this.props.items || !this.props.isAdmin()) {
      return null;
    }
    return (
      <Container>
        <div className='row my-3'>
          <div className='col-sm-4'>
            <div className='d-flex justify-content-between'>
              <h3>{this.props.name}</h3>
              <button className='btn btn-secondary btn-sm' onClick={() => this.addNewItem()}>
                Add
              </button>
            </div>
          </div>
          <div className='col-sm-6'>{this.renderErrorSaveResult()}</div>
        </div>
        {this.state.newItem && (
          <div className='row mb-3'>
            <div className='col-sm-12'>
              <Card>
                <Card.Header>
                  <div className='d-flex justify-content-between'>
                    <h5 className='my-1'>New</h5>
                    <Button variant='white' onClick={() => this.setState({ newItem: null })}>
                      <span aria-hidden='true' className='fa fa-times' />
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <div className='form-group'>
                          <input
                            id='ciSignature'
                            className='form-control'
                            placeholder='Enter CI signature'
                            type='text'
                            onChange={e => this.updateCiSignature(e)}
                            value={this.state.newItem.ciSignature}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <div className='form-group'>
                          <label htmlFor='authorizedDate'>Authorized date</label>
                          <input
                            id='authorizedDate'
                            className='form-control'
                            type='date'
                            onChange={e => this.updateAuthorizedDate(e)}
                            value={this.state.newItem.authorizedDate.toLocaleDateString('sv-SE')}
                          />
                        </div>
                      </div>
                      <div className='col-sm-4'>
                        <div className='form-group'>
                          <label htmlFor='expirationDate'>Expiration date</label>
                          <input
                            id='expirationDate'
                            className='form-control'
                            type='date'
                            onChange={e => this.updateExpirationDate(e)}
                            value={this.state.newItem.expirationDate.toLocaleDateString('sv-SE')}
                          />
                        </div>
                      </div>
                      <div className='col-sm-4'>
                        <div className='form-group'>
                          <label htmlFor='extensionDate'>Extension date</label>
                          <input
                            id='extensionDate'
                            className='form-control'
                            type='date'
                            onChange={e => this.updateExtensionDate(e)}
                            value={
                              this.state.newItem.extensionDate &&
                              this.state.newItem.extensionDate.toLocaleDateString('sv-SE')
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row align-items-end'>
                      <div className='col-sm-4'>
                        <div className='form-group'>
                          <label htmlFor='validFrom'>Valid from</label>
                          <input
                            id='validFrom'
                            className='form-control'
                            type='date'
                            onChange={e => this.updateValidFrom(e)}
                            value={this.state.newItem.validFrom.toLocaleDateString('sv-SE')}
                          />
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>{this.renderSaveButton()}</div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
        <Accordion>
          {this.props.items.map(item => {
            return (
              <Accordion.Item key={item.id} eventKey={item.id}>
                <Accordion.Header as={Card.Header}>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <b>Valid from:</b> {new Date(item.validFrom).toLocaleDateString('sv-SE')}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body as={Card.Body}>
                  <div className='container'>{this.renderItem(item)}</div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Container>
    );
  }
}

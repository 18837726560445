import React, { useState, useMemo, useCallback } from 'react';
import { Pagination } from 'react-bootstrap';

export function PaginatedResult(props) {
  const [current, setCurrent] = useState(1);

  const search = useCallback(
    async number => {
      if (props.pending) {
        return;
      }
      if (number > props.pageCount || number <= 0) {
        return;
      }

      setCurrent(number);

      await props.search(number);
    },
    [props]
  );

  const searchOnPageNumber = useCallback(
    async e => {
      if (!e.target || !e.target.text) {
        return;
      }
      const number = parseInt(e.target.text);
      await search(number);
    },
    [search]
  );

  const pages = useMemo(() => {
    const active = current;
    const start = current > 9 ? current - 5 : 0;
    const end = Math.min(current > 9 ? current + 5 : 10, props.pageCount);

    return (
      <>
        {Array(end - start)
          .fill()
          .map((_, i) => {
            const number = start + i;
            return (
              <Pagination.Item
                key={number}
                active={number + 1 === active}
                onClick={searchOnPageNumber}
                disabled={props.pending}
              >
                {number + 1}
              </Pagination.Item>
            );
          })}
      </>
    );
  }, [current, props.pageCount, props.pending, searchOnPageNumber]);

  return (
    <Pagination size='sm' className='justify-content-center'>
      <Pagination.First disabled={props.pending} onClick={() => search(1)} />
      <Pagination.Prev disabled={props.pending} onClick={() => search(current - 1)} />
      {pages}
      <Pagination.Next disabled={props.pending} onClick={() => search(current + 1)} />
      <Pagination.Last disabled={props.pending} onClick={() => search(props.pageCount)} />
    </Pagination>
  );
}

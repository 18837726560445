import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Unauthorized from '../Layout/Unauthoried.jsx';

import fetchSteelworksAsync from '../../store/steelworks/fetchSteelworks';
import postSteelworkAsync from '../../store/editSteelwork/postSteelwork';

import { BaseWrite } from './BaseWrite.jsx';
import { useAuth } from 'auth/useAuth.js';
import { postSteelworkClear } from 'store/editSteelwork/actions.js';

export function Steelwork() {
  const steelworks = useSelector(state => state.steelworks);
  const editSteelwork = useSelector(state => state.editSteelwork);
  const dispatch = useDispatch();

  const { isAdmin } = useAuth();

  const [items, setItems] = useState([]);

  const fetchData = useCallback(() => {
    dispatch(postSteelworkClear());
    dispatch(fetchSteelworksAsync());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!editSteelwork?.error && !editSteelwork?.pending && !!editSteelwork?.data) {
      fetchData();
    }
  }, [editSteelwork, fetchData]);

  useEffect(() => {
    if (steelworks?.data) {
      setItems([...steelworks.data]);
    }
  }, [steelworks]);

  const updateItems = useCallback(items => {
    setItems([...items]);
  }, []);

  const save = useCallback(
    item => {
      dispatch(postSteelworkAsync(item));
    },
    [dispatch]
  );

  if (!isAdmin()) {
    return <Unauthorized />;
  }
  return (
    <BaseWrite
      name='Steelworks'
      items={items}
      allItems={steelworks?.data}
      save={save}
      saveResult={editSteelwork}
      refreshData={fetchData}
      setItems={updateItems}
      isAdmin={isAdmin}
    />
  );
}

import {
  FETCH_MECHANICALRANGES_PENDING,
  FETCH_MECHANICALRANGES_SUCCESS,
  FETCH_MECHANICALRANGES_ERROR
} from './actions';

const initialState = {
  pending: false,
  data: [],
  error: null
};

export default function mechanicalRangesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MECHANICALRANGES_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_MECHANICALRANGES_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.ranges
      };
    case FETCH_MECHANICALRANGES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
}

export const getMechanicalRanges = state => state.materials;
export const getMechanicalRangesPending = state => state.pending;
export const getMechanicalRangesError = state => state.error;

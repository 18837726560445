import { fetchManufacturersPending, fetchManufacturersSuccess, fetchManufacturersError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchManufacturersAsync = createAsyncThunk('manufacturer/list', async (manufacturer, thunkAPI) => {
  thunkAPI.dispatch(fetchManufacturersPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/manufacturer/list', {
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json; v=2.0',
        Accept: 'application/json; v=2.0'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchManufacturersError({ message: 'Unknown error' }));
      return;
    }
    const data = await res.json();
    thunkAPI.dispatch(fetchManufacturersSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchManufacturersError(error));
  }
});

export default fetchManufacturersAsync;

export const SEARCH_HEATS_PENDING = 'SEARCH_HEATS_PENDING';
export const SEARCH_HEATS_SUCCESS = 'SEARCH_HEATS_SUCCESS';
export const SEARCH_HEATS_ERROR = 'SEARCH_HEATS_ERROR';

export function searchHeatsPending() {
  return {
    type: SEARCH_HEATS_PENDING
  };
}

export function searchHeatsSuccess(data) {
  return {
    type: SEARCH_HEATS_SUCCESS,
    data: data
  };
}

export function searchHeatsError(error) {
  return {
    type: SEARCH_HEATS_ERROR,
    error: error
  };
}

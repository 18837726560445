export const FETCH_MTR_PENDING = 'FETCH_MTR_PENDING';
export const FETCH_MTR_SUCCESS = 'FETCH_MTR_SUCCESS';
export const FETCH_MTR_ERROR = 'FETCH_MTR_ERROR';
export const FETCH_MTR_CLEAR = 'FETCH_MTR_CLEAR';

export function fetchMtrPending() {
  return {
    type: FETCH_MTR_PENDING
  };
}

export function fetchMtrSuccess(data) {
  return {
    type: FETCH_MTR_SUCCESS,
    data: data
  };
}

export function fetchMtrError(error) {
  return {
    type: FETCH_MTR_ERROR,
    error: error
  };
}

export function fetchMtrClear() {
  return {
    type: FETCH_MTR_SUCCESS
  };
}

import { fetchDimensionRangesPending, fetchDimensionRangesSuccess, fetchDimensionRangesError } from './actions';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchDimensionRangesAsync = createAsyncThunk(
  'dimension/standard-dimensions',
  async (materialStandardId, thunkAPI) => {
    thunkAPI.dispatch(fetchDimensionRangesPending());

    try {
      const token = await thunkAPI.extra.acquireTokenSilent();
      const res = await fetch('api/dimension/standard-dimensions?standardId=' + materialStandardId, {
        headers: {
          Authorization: 'Bearer ' + token.accessToken,
          'Content-Type': 'application/json'
        }
      });
      if (!res.ok) {
        thunkAPI.dispatch(fetchDimensionRangesError({ message: 'Unknown error' }));
        return;
      }
      const data = await res.json();
      thunkAPI.dispatch(fetchDimensionRangesSuccess(data));
    } catch (error) {
      thunkAPI.dispatch(fetchDimensionRangesError(error));
    }
  }
);

export default fetchDimensionRangesAsync;

export const FETCH_DOCUMENTCERTIFICATION_PENDING = 'FETCH_DOCUMENTCERTIFICATION_PENDING';
export const FETCH_DOCUMENTCERTIFICATION_SUCCESS = 'FETCH_DOCUMENTCERTIFICATION_SUCCESS';
export const FETCH_DOCUMENTCERTIFICATION_ERROR = 'FETCH_DOCUMENTCERTIFICATION_ERROR';

export function fetchDocumentCertificationPending() {
  return {
    type: FETCH_DOCUMENTCERTIFICATION_PENDING
  };
}

export function fetchDocumentCertificationSuccess(data) {
  return {
    type: FETCH_DOCUMENTCERTIFICATION_SUCCESS,
    data: data
  };
}

export function fetchDocumentCertificationError(error) {
  return {
    type: FETCH_DOCUMENTCERTIFICATION_ERROR,
    error: error
  };
}

import { FETCH_ITEM_PENDING, FETCH_ITEM_SUCCESS, FETCH_ITEM_ERROR, FETCH_ITEM_CLEAR } from './actions';

const initialState = {
  pending: false,
  item: null,
  error: null
};

export default function itemReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEM_PENDING:
      return {
        ...state,
        error: undefined,
        item: undefined,
        pending: true
      };
    case FETCH_ITEM_SUCCESS:
      return {
        ...state,
        pending: false,
        error: undefined,
        item: action.item
      };
    case FETCH_ITEM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case FETCH_ITEM_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}

export const getItem = state => state.item;
export const getItemPending = state => state.pending;
export const getItemError = state => state.error;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAsmeCertificationPending, fetchAsmeCertificationSuccess, fetchAsmeCertificationError } from './actions';

const fetchAsmeCertificationAsync = createAsyncThunk('document/asme-certification/all', async (id, thunkAPI) => {
  thunkAPI.dispatch(fetchAsmeCertificationPending());

  try {
    const token = await thunkAPI.extra.acquireTokenSilent();
    const res = await fetch('api/document/asme-certification/all', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token.accessToken,
        'Content-Type': 'application/json; v=1.0',
        Accept: 'application/json; v=1.0'
      }
    });
    if (!res.ok) {
      thunkAPI.dispatch(fetchAsmeCertificationError({ message: 'Unknown error' }));
      return;
    }

    const data = await res.json();
    thunkAPI.dispatch(fetchAsmeCertificationSuccess(data));
  } catch (error) {
    thunkAPI.dispatch(fetchAsmeCertificationError(error));
  }
});

export default fetchAsmeCertificationAsync;

import React, { Component } from 'react';

import Modal from 'react-bootstrap/Modal';

class SaveModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saveClicked: false
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.save = this.save.bind(this);
  }

  createAnother() {
    this.props.createAnother();

    this.setState({
      saveClicked: false
    });
  }

  toggleModal() {
    this.setState({
      saveClicked: false
    });
    this.props.toggle();
  }

  async save() {
    this.setState({
      saveClicked: true
    });
    await this.props.save();
  }

  render() {
    const size = this.props.large ? 'lg' : undefined;

    return (
      <Modal size={size} role='document' show={this.props.show} onHide={this.toggleModal}>
        <Modal.Header closeButton>
          <h4 className='modal-title'>{this.props.header}</h4>
        </Modal.Header>
        <Modal.Body>
          <div>{this.props.body}</div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type='button'
            className='btn btn-secondary mr-auto'
            data-dismiss='modal'
            onClick={this.createAnother.bind(this)}
            title='Note that when pressing this everything will be cleared, make sure to save first...'
            style={
              this.state.saveClicked && !this.props.pending && this.props.data
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            Create another
          </button>
          <button
            className='btn btn-primary'
            type='button'
            onClick={this.save}
            disabled={this.state.saveClicked || this.props.pending}
          >
            Save&nbsp;
            {this.props.pending && (
              <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SaveModal;

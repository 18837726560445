import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchMtrAsync from '../../store/mtr/fetchMtr';
import Spinner from '../Layout/Spinner';
import Certificate from '../CertificateBase/Certificate.jsx';
import Unauthorized from '../Layout/Unauthoried.jsx';
import { useParams } from 'react-router-dom';
import { useAuth } from 'auth/useAuth';

export function MtrDetails() {
  const { id } = useParams();
  const { pending, data } = useSelector(state => state.mtr);

  const { hasElevatedRole } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasElevatedRole() && id) {
      dispatch(fetchMtrAsync(id));
    }
  }, [dispatch, id, hasElevatedRole]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (!pending && !data) {
    return null;
  }
  if (!data && pending) {
    return <Spinner />;
  }
  return <Certificate data={data} type='Alfa Laval Mtr' editRoute='edit-mtr' />;
}

import React, { Component, useMemo } from 'react';
import DimensionRangesDecimalInput from './DimensionRangesDecimalInput.jsx';
import DimensionRangesSelect from './DimensionRangesSelect.jsx';
import { FormRow } from 'components/FormRow';
import { Col, Form, Row } from 'react-bootstrap';

class DimensionRangesInputs extends Component {
  updateDisable() {
    this.props.disableComponent();
  }

  updateProperty(id, value, hasPredefinedValues) {
    let props = [];
    if (this.props.values.length) {
      props = this.props.values;
    }
    let prop = { validatorId: id, unit: this.props.unit };
    if (hasPredefinedValues) {
      prop.optionId = value;
    } else {
      prop.value = value;
    }
    const index = props.map(e => e.validatorId).indexOf(id);
    if (index > -1 && id) {
      props[index] = prop;
    } else {
      props.push(prop);
    }

    this.props.updatDimensions(props);
  }

  setSelectedDimensionUnit(event) {
    if (!event.target) {
      return;
    }

    this.props.updateUnit(event.target.value);
    this.props.updatDimensions([]);
  }

  render() {
    if (!this.props.ranges.data || !this.props.ranges.data.dimensions || !this.props.ranges.data.dimensions.length) {
      return null;
    }
    return (
      <>
        <FormRow className='align-items-center'>
          <Col xs='auto'>
            <h5 className='mb-0'>Dimension data</h5>
          </Col>
          <Col xs='auto'>
            <Form.Check checked={this.props.isDisabled} onChange={this.updateDisable.bind(this)} label='Skip' />
          </Col>
        </FormRow>
        {!this.props.isDisabled && (
          <UnitSelect
            selectedUnit={this.props.unit}
            units={this.props.ranges.data.units}
            onSelectedUnitChanged={this.setSelectedDimensionUnit.bind(this)}
          />
        )}
        {!this.props.isDisabled && (
          <Properties
            dimensions={this.props.ranges.data.dimensions}
            values={this.props.values}
            onUpdateProperty={this.updateProperty.bind(this)}
            unit={this.props.unit}
          />
        )}
      </>
    );
  }
}

function UnitSelect({ selectedUnit, units, onSelectedUnitChanged }) {
  return (
    <Row>
      <Form.Group as={Col} sm={4}>
        <Form.Label htmlFor='unit'>Unit *</Form.Label>
        <Form.Select value={selectedUnit} id='unit' onChange={onSelectedUnitChanged}>
          {units.length > 1 && (
            <option disabled value=''>
              Unit
            </option>
          )}
          {units.map(unit => {
            return (
              <option key={unit} value={unit}>
                {unit}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    </Row>
  );
}

function Properties({ dimensions, values, unit, onUpdateProperty }) {
  return (
    <Row>
      {dimensions.map(dimension => {
        return (
          <Property
            key={dimension.id}
            dimension={dimension}
            values={values}
            unit={unit}
            onUpdateProperty={onUpdateProperty}
          />
        );
      })}
    </Row>
  );
}

function Property({ dimension, values, unit, onUpdateProperty }) {
  const [value, optionId] = useMemo(() => {
    let value = null;
    let optionId = '';
    if (values.length) {
      const result = values.filter(x => x.validatorId === dimension.id);
      if (result.length) {
        value = result[0].value;
        optionId = result[0].optionId;
      }
    }
    return [value, optionId];
  }, [values, dimension]);

  if (dimension.selectValues.length || optionId) {
    return (
      <DimensionRangesSelect
        property={dimension}
        onChange={onUpdateProperty}
        optionId={optionId}
        unit={unit}
        predefinedValues={dimension.selectValues.length}
      />
    );
  } else {
    return (
      <DimensionRangesDecimalInput
        property={dimension}
        unit={unit}
        onChange={onUpdateProperty}
        value={value}
        predefinedValues={dimension.selectValues.length}
      />
    );
  }
}

export default DimensionRangesInputs;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fetchAlfaLavalCertificateAsync from '../../store/alfalavalCertificate/fetchAlfaLavalCertificate';
import CertificateSource from './CertificateSource.jsx';
import Unauthorized from '../Layout/Unauthoried.jsx';
import { useParams } from 'react-router';
import Spinner from 'components/Layout/Spinner';
import { useAuth } from 'auth/useAuth';

export function CertificateSourceDetails() {
  const dispatch = useDispatch();
  const alfalavalCertificate = useSelector(state => state.alfalavalCertificate);

  const { hasElevatedRole } = useAuth();

  const { id } = useParams();

  useEffect(() => {
    if (id && hasElevatedRole()) {
      dispatch(fetchAlfaLavalCertificateAsync(id));
    }
  }, [dispatch, id, hasElevatedRole]);

  if (!hasElevatedRole()) {
    return <Unauthorized />;
  }
  if (!alfalavalCertificate) {
    return null;
  }
  if (!alfalavalCertificate.data || alfalavalCertificate.pending) {
    return <Spinner />;
  }
  return <CertificateSource data={alfalavalCertificate.data} updateComplete={() => {}} />;
}

import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

export function EditQDocHeader({ id, revision, invalid, updateInvalid }) {
  const onInvalid = useCallback(() => updateInvalid(), [updateInvalid]);

  return (
    <>
      <Row>
        <h3>Q-doc</h3>
      </Row>
      <Row>
        <Col sm={6}>
          {id && (
            <h5>
              Id: {id}, Revision: {revision}
            </h5>
          )}
        </Col>
        <Col sm={2} className='d-flex justify-content-end'>
          <Form.Check type='checkbox' checked={invalid} label={<h5>Invalid</h5>} id='invalid' onChange={onInvalid} />
        </Col>
        <Col sm={4} />
      </Row>
    </>
  );
}

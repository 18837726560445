import { FETCH_QDOC_PENDING, FETCH_QDOC_SUCCESS, FETCH_QDOC_ERROR, FETCH_QDOC_CLEAR } from './actions';

const initialState = {
  pending: false,
  data: undefined,
  error: null
};

export default function qdocReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_QDOC_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_QDOC_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.data
      };
    case FETCH_QDOC_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    case FETCH_QDOC_CLEAR: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

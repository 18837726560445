import React, { Component } from 'react';
import { Accordion, Button, Card, Container, Row, Col, Collapse, Form } from 'react-bootstrap';

import './Admin.css';
import { FormRow } from 'components/FormRow';

export class WriteSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveClicked: false,
      name: '',
      invalid: false,
      showError: false,
      filter: '',
      newItem: null,
      selected: '',
      useQDoc: false,
      useCertificate: false
    };
  }

  async save(id) {
    if (!this.props.isAdmin()) {
      return;
    }

    let item = {};
    if (id) {
      item = this.props.items.find(x => x.id === id);
    } else {
      item.name = this.state.name;
      item.invalid = this.state.invalid;
      item.useQDoc = this.state.useQDoc;
      item.useCertificate = this.state.useCertificate;
    }

    await this.props.save(item);

    this.setState({
      newItem: null,
      name: undefined,
      invalid: false,
      filter: ''
    });
  }

  updateNameOnItem(e, id) {
    if (!e.target) {
      return;
    }
    const index = this.props.items.findIndex(x => x.id === id);
    const items = this.props.items;
    if (index > -1) {
      items[index].name = e.target.value;
      items[index].dirty = true;
    }

    this.props.setItems(items);
  }

  updateInvalidOnItem(e, id) {
    if (!e.target) {
      return;
    }
    const index = this.props.items.findIndex(x => x.id === id);
    const items = this.props.items;
    if (index > -1) {
      items[index].invalid = e.target.checked;
      items[index].dirty = true;
    }
    this.props.setItems(items);
  }

  updateQDocOnItem(e, id) {
    if (!e.target) {
      return;
    }
    const index = this.props.items.findIndex(x => x.id === id);
    const items = this.props.items;
    if (index > -1) {
      items[index].useQDoc = e.target.checked;
      items[index].dirty = true;
    }
    this.props.setItems(items);
  }

  updateCertificateOnItem(e, id) {
    if (!e.target) {
      return;
    }
    const index = this.props.items.findIndex(x => x.id === id);
    const items = this.props.items;
    if (index > -1) {
      items[index].useCertificate = e.target.checked;
      items[index].dirty = true;
    }
    this.props.setItems(items);
  }

  updateFilter(e) {
    if (!e.target) {
      return;
    }
    const filter = e.target.value;

    const items = this.shallowCopy(
      this.props.allItems.filter(x => x.name.toUpperCase().includes(filter.toUpperCase()))
    );
    this.props.setItems(items);
    this.setState({
      filter: e.target.value
    });
  }

  resetItem(id) {
    const originalItem = this.props.allItems.find(x => x.id === id);
    const items = this.props.items;
    const index = items.findIndex(x => x.id === id);
    items[index] = this.shallowCopy(originalItem);
    items[index].dirty = false;
    this.props.setItems(items);
  }

  shallowCopy(data) {
    return JSON.parse(JSON.stringify(data));
  }

  renderSaveButton(id) {
    return (
      <button
        id={id}
        type='button'
        className='btn btn-primary'
        disabled={this.props.saveResult.pending}
        onClick={() => this.save(id)}
      >
        Save <i className={this.props.saveResult.pending ? 'fas fa-circle-notch fa-spin' : ''}></i>
      </button>
    );
  }

  renderItem(item) {
    if (!item) {
      return null;
    }
    return (
      <div className='col-sm-12'>
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-group'>
              <input
                id={item.id}
                className='form-control'
                placeholder='Enter name'
                type='text'
                onChange={e => this.updateNameOnItem(e, item.id)}
                value={item.name}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form-group'>{this.renderSaveButton(item.id)}</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='form-check form-check-inline'>
              <input
                id={`${item.id}-certificate`}
                type='checkbox'
                className='form-check-input'
                checked={item.useCertificate}
                onChange={e => this.updateCertificateOnItem(e, item.id)}
              />
              <label className='form-check-label' htmlFor={`${item.id}-certificate`}>
                Certificate
              </label>
            </div>
            <div className='form-check form-check-inline'>
              <input
                id={`${item.id}-qdoc`}
                type='checkbox'
                className='form-check-input'
                checked={item.useQDoc}
                onChange={e => this.updateQDocOnItem(e, item.id)}
              />
              <label className='form-check-label' htmlFor={`${item.id}-qdoc`}>
                QDoc
              </label>
            </div>
          </div>
        </div>
        <div className='row'>
          <br />
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='form-check'>
              <input
                type='checkbox'
                className='form-check-input'
                id={`${item.id}-invalid`}
                checked={item.invalid}
                onChange={e => this.updateInvalidOnItem(e, item.id)}
              />
              <label className='form-check-label' htmlFor={`${item.id}-invalid`}>
                Invalid
              </label>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Last changed:</b> {item.created ? new Date(item.created).toLocaleString('sv-SE') : ''}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Last changed by:</b> {item.createdBy}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <b>Id:</b> {item.id}
          </div>
        </div>
      </div>
    );
  }

  addNewItem() {
    const item = { id: '', name: '' };
    this.setState({ newItem: item, name: '', selected: '' });
  }

  renderErrorSaveResult() {
    if (this.props.saveResult?.error) {
      return this.renderErrorAlert(
        'Unable to save item. If this error reoccurs please contact support.',
        'showError',
        'alert-danger'
      );
    }
    return null;
  }

  renderErrorAlert(message, stateProperty, className) {
    return (
      <div className='row justify-content-center'>
        <div className='col-lg-12 col-sm-12'>
          {this.renderAlert(
            message,
            () =>
              this.setState({
                [stateProperty]: false
              }),
            className
          )}
        </div>
      </div>
    );
  }

  renderAlert(message, onActionFunc, alertClass) {
    return (
      <div className={`alert ${alertClass} alert-not-found alert-dismissible fade show`} role='alert'>
        {message}
        <button type='button' className='close' data-dismiss='alert' aria-label='Close' onClick={() => onActionFunc()}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
    );
  }

  toggle(e, id) {
    if (this.state.newItem != null) {
      e.stopPropagation();
      this.setState({ selected: undefined });
      return;
    }
    const shouldCollapse = this.state.selected === id;

    this.setState({ selected: shouldCollapse ? '' : id });
  }

  render() {
    if (!this.props.items || !this.props.isAdmin()) {
      return null;
    }
    return (
      <Container>
        <Row className='py-4'>
          <Col sm={5} className='align-content-center'>
            <h3>{this.props.name}</h3>
          </Col>
          <Col>
            <Button variant='secondary' onClick={() => this.addNewItem()}>
              Add
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={0} md={3} />
          <Col md={6}>{this.renderErrorSaveResult()}</Col>
          <Col xs={0} md={3} />
        </Row>
        <Collapse in={this.state.newItem != null}>
          <FormRow>
            <Col md={6}>
              <Card>
                <Card.Header as={Card.Header}>
                  <Row className='align-items-center'>
                    <Col className='flex-grow-1'>
                      <h5 className='my-1'>New</h5>
                    </Col>
                    <Col xs='auto'>
                      <Button variant='white' onClick={() => this.setState({ newItem: null })}>
                        <span aria-hidden='true' className='fa fa-times' />
                      </Button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <FormRow>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          className='form-control'
                          placeholder='Enter name'
                          type='text'
                          onChange={e => this.setState({ name: e.target.value })}
                          value={this.state.name}
                        />
                      </Form.Group>
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col xs='auto'>
                      <Form.Check
                        id='certificate'
                        type='checkbox'
                        checked={this.state.useCertificate}
                        onChange={e =>
                          this.setState({
                            useCertificate: e.target.checked
                          })
                        }
                        label='Certificate'
                      />
                    </Col>
                    <Col xs='auto'>
                      <Form.Check
                        id='qdoc'
                        checked={this.state.useQDoc}
                        onChange={e => this.setState({ useQDoc: e.target.checked })}
                        label='QDoc'
                      />
                    </Col>
                  </FormRow>
                  <Row>
                    <Col className='d-flex justify-content-end'>{this.renderSaveButton()}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </FormRow>
        </Collapse>
        <FormRow>
          <Col>
            <Form.Group>
              <Form.Control
                type='text'
                minLength='1'
                placeholder='Filter by name'
                onChange={e => this.updateFilter(e)}
                value={this.state.filter}
              />
            </Form.Group>
          </Col>
        </FormRow>
        <Accordion>
          {this.props.items.map(item => {
            return (
              <Accordion.Item key={item.id} eventKey={item.id}>
                <Accordion.Header>
                  <Col>
                    <Row className='align-items-center'>
                      <Col xs={10} className={item.invalid ? 'invalid-item' : undefined}>
                        <h6 className='py-2'>{item.name}</h6>
                      </Col>
                      {item.dirty && (
                        <Col xs='auto' className='justify-self-end'>
                          <Button variant='danger' size='sm' onClick={e => this.resetItem(e, item.id)}>
                            Clear
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='container'>{this.renderItem(item)}</div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Container>
    );
  }
}

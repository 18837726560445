export const FETCH_DIMENSIONRANGES_PENDING = 'FETCH_DIMENSIONRANGES_PENDING';
export const FETCH_DIMENSIONRANGES_SUCCESS = 'FETCH_DIMENSIONRANGES_SUCCESS';
export const FETCH_DIMENSIONRANGES_ERROR = 'FETCH_DIMENSIONRANGES_ERROR';

export function fetchDimensionRangesPending() {
  return {
    type: FETCH_DIMENSIONRANGES_PENDING
  };
}

export function fetchDimensionRangesSuccess(dimensionRanges) {
  return {
    type: FETCH_DIMENSIONRANGES_SUCCESS,
    dimensionRanges: dimensionRanges
  };
}

export function fetchDimensionRangesError(error) {
  return {
    type: FETCH_DIMENSIONRANGES_ERROR,
    error: error
  };
}

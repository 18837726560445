import React, { Component } from 'react';

export default class Download extends Component {
  render() {
    if (!this.props.fileName) {
      return <span>N/A</span>;
    }

    const link = `/api/download/${this.props.documentType}/${this.props.identifier}`;
    return (
      <a
        href={link}
        alt={this.props.alt}
        title='Click to download file'
        onClick={e => this.props.onClick && this.props.onClick(e)}
        download
      >
        <i className='fas fa-download m-1' />
      </a>
    );
  }
}

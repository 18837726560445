import { combineReducers } from 'redux';
import heat from './heat/reducer';
import heats from './heats/reducer';
import basicHeat from './basicHeat/reducer';
import basicHeats from './basicHeats/reducer';
import searchHeats from './searchHeats/reducer';
import editCertificate from './editAlfaLavalCertificate/reducer';
import editSupplierCertificate from './editSupplierCertificate/reducer';
import basicSupplierCertificates from './basicSupplierCertificates/reducer';
import supplierCertificate from './supplierCertificate/reducer';
import searchSupplierCertificates from './searchSupplierCertificates/reducer';
import certificateTypes from './certificateTypes/reducer';
import suppliers from './suppliers/reducer';
import editSupplier from './editSupplier/reducer';
import steelworks from './steelworks/reducer';
import editSteelwork from './editSteelwork/reducer';
import materials from './materials/reducer';
import chemicalRanges from './chemicalRanges/reducer';
import mechanicalRanges from './mechanicalRanges/reducer';
import dimensionRanges from './dimensionRanges/reducer';
import manufacturers from './manufacturers/reducer';
import editManufacturer from './editManufacturer/reducer';
import editHeat from './editHeat/reducer';
import fetchItem from './items/reducer';
import surfaces from './surfaces/reducer';
import searchCertificates from './searchCertificates/reducer';
import alfalavalCertificate from './alfalavalCertificate/reducer';
import mtr from './mtr/reducer';
import editMtr from './editMtr/reducer';
import adHocMtr from './createAdHocMtr/reducer';
import documentIds from './documentId/reducer';
import documentCertification from './documentCertification/reducer';
import asmeCertification from './asmeCertification/reducer';
import editAsmeCertification from './editAsmeCertification/reducer';
import qdoc from './qdoc/reducer';
import qdocs from './qdocs/reducer';
import editQDoc from './editQDoc/reducer';
import searchQDocs from './searchQDocs/reducer';
import products from './products/reducer';
import uploadFile from './uploads/reducer';

export default combineReducers({
  heat,
  heats,
  basicHeat,
  basicHeats,
  searchHeats,
  editCertificate,
  basicSupplierCertificates,
  supplierCertificate,
  editSupplierCertificate,
  searchSupplierCertificates,
  suppliers,
  editSupplier,
  editSteelwork,
  steelworks,
  certificateTypes,
  materials,
  chemicalRanges,
  mechanicalRanges,
  dimensionRanges,
  manufacturers,
  editManufacturer,
  editHeat,
  fetchItem,
  surfaces,
  searchCertificates,
  alfalavalCertificate,
  mtr,
  editMtr,
  adHocMtr,
  documentIds,
  documentCertification,
  asmeCertification,
  editAsmeCertification,
  qdocs,
  editQDoc,
  qdoc,
  searchQDocs,
  products,
  uploadFile
});
